export const SET_ACTIVE_FILTERS_FROM_SEARCH_QUERY = "insights/set-active-filters-from-search-query";
export const SET_ACTIVE_FILTERS = "insights/set-active-filters";
export const TOGGLE_FILTER = "insights/toggle-filter";
export const PRESELECT_FILTER = "insights/preselect-filter";
export const POPULATE_FILTERS = "insights/populate-filters";
export const POPULATE_PRODUCTS = "insights/populate-products";
export const POPULATE_NEXT_PRODUCT_PAGE = "insights/populate-next-product-page";
export const ACTIVE_FILTER_NUM = "insights/active-filters-num";
export const ACTIVATE_PRESELECTED_FILTER = "insights/activate-preselected-filter";
export const ACTIVATE_ALL_PRESELECTED_FILTERS = "insights/activate-all-preselected-filters";
export const CLEAR_ALL_FILTERS = "insights/clear-all-filters";
export const CLEAR_ALL_PRESELECTED_FILTERS = "insights/clear-all-preselected-filters";
export const DESELECT_FILTERS = "insights/deselect-filters";
export const RESET = "insights/reset";
export const CLEAR_NON_TOPIC_FILTERS = "insights/clear-non-topic-filters";
export const HAS_RECOMMENDED = "insights/has-recommended";
