import {
  ACTIVATE_ALL_PRESELECTED_FILTERS,
  ACTIVATE_PRESELECTED_FILTER,
  ACTIVE_FILTER_NUM,
  CLEAR_ALL_FILTERS,
  SET_ACTIVE_FILTERS,
  TOGGLE_FILTER,
  POPULATE_NEXT_PRODUCT_PAGE,
  CLEAR_NON_TOPIC_FILTERS,
} from "./ConsultingTypes";
import { fetchAllPosts } from "./ConsultingActions";
import { triggerSearchApiCall, resetPageCount } from "../search/SearchActions";

function filterOutNonActive(list, isTag = false) {
  return list
    .filter((item) => {
      return item.active;
    })
    .map((item) => {
      return isTag ? item.label : item.value;
    })
    .join(",");
}

function getActiveFilters(state) {
  return {
    languages: filterOutNonActive(state.languageFilters),
    industries: filterOutNonActive(state.industryFilters),
    topics: filterOutNonActive(state.topicFilters),
    countries: filterOutNonActive(state.countryFilters),
    type: filterOutNonActive(state.tagFilters, true),
  };
}

function isFilterAction(action) {
  return (
    action.type === TOGGLE_FILTER ||
    action.type === ACTIVATE_PRESELECTED_FILTER ||
    action.type === ACTIVATE_ALL_PRESELECTED_FILTERS ||
    action.type === CLEAR_ALL_FILTERS ||
    action.type === POPULATE_NEXT_PRODUCT_PAGE ||
    action.type === CLEAR_NON_TOPIC_FILTERS
  );
}

function isPageResetAction(action) {
  return (
    action.type === ACTIVATE_PRESELECTED_FILTER ||
    action.type === ACTIVATE_ALL_PRESELECTED_FILTERS ||
    action.type === CLEAR_ALL_FILTERS ||
    action.type === CLEAR_NON_TOPIC_FILTERS
  );
}

function getFilterAmount(value, isTag) {
  return filterOutNonActive(value, isTag) ? filterOutNonActive(value, isTag).split(",").length : 0;
}

function getFilterLength(state) {
  const countLanguage = getFilterAmount(state.languageFilters);
  const countIndustries = getFilterAmount(state.industryFilters);
  const countTopic = getFilterAmount(state.topicFilters);
  const countCountry = getFilterAmount(state.countryFilters);
  const countType = getFilterAmount(state.tagFilters, true);
  return {
    languages: countLanguage,
    industries: countIndustries,
    topics: countTopic,
    countries: countCountry,
    type: countType,
    total: countLanguage + countIndustries + countType + countTopic + countCountry,
  };
}

const filterListener = (store) => (next) => (action) => {
  const result = next(action);
  const isSearch = action.value && action.value.isSearch;

  if (isPageResetAction(action) && isSearch) {
    store.dispatch(resetPageCount());
  }

  if (isFilterAction(action)) {
    const activeFilters = getActiveFilters(store.getState().consulting);
    const activeFilterNum = getFilterLength(store.getState().consulting);
    store.dispatch({ type: SET_ACTIVE_FILTERS, value: activeFilters });
    store.dispatch({ type: ACTIVE_FILTER_NUM, value: activeFilterNum });

    const isFirstLoad = action.value && action.value.firstLoad;

    if (!isFirstLoad) {
      if (isSearch) {
        const searchQuery = store.getState().search.query;
        const { page } = store.getState().search;
        triggerSearchApiCall(
          store.dispatch,
          searchQuery,
          false,
          true,
          page,
          12,
          "CONSULTING SERVICES",
        );
      } else {
        const { page } = store.getState().consulting;
        fetchAllPosts(store.dispatch, true, page);
      }
    }
  }

  return result;
};

export default filterListener;
