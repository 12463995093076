import React from "react";
import BarLoader from "react-spinners/BarLoader";
import PropTypes from "prop-types";
import Logo from "./Logo";

function LoadingStrip({ showLogo }) {
  return (
    <div className="loading-strip">
      {showLogo && <Logo className="logo" />}
      <div className="my-2">
        <BarLoader width="165px" height="5px" />
      </div>
    </div>
  );
}

LoadingStrip.propTypes = {
  showLogo: PropTypes.bool,
};

LoadingStrip.defaultProps = {
  showLogo: true,
};

export default LoadingStrip;
