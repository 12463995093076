import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import { ReactComponent as Close } from "../../../assets/images/close-concierge.svg";
import { ReactComponent as ChatConciergeIcon } from "../../../assets/images/gradient/rose-gradient.svg";
import { ReactComponent as SupportIcon } from "../../../assets/images/snow-chat/support-icon.svg";
import LandingChat from "./LandingChat";
import { hideConciergeChat } from "../../../reducers/global/GlobalActions";
import RouteConstants from "../../../utils/RouteConstants";

function ConciergePopup({ show, hidePopup, isPopup, isContactSupport, askRoseProductSlug }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const chat = document.getElementById("concierge-popup-chat");
    if (chat) {
      setTimeout(() => {
        chat.style.visibility = "visible";
      }, 100);
    }
    window.onpopstate = () => {
      hidePopup();
    };
    if (window.location.pathname === RouteConstants.PRODUCT_PAGE_URL + askRoseProductSlug) {
      hidePopup();
    }
    const handleClosing = (e) => {
      const chatElement = document.getElementById("concierge-popup-chat");
      if (chatElement && !chatElement.contains(e.target) && show) {
        hidePopup();
      }
    };

    setTimeout(() => {
      document.addEventListener("click", handleClosing);
    }, 200);
    return () => {
      document.removeEventListener("click", handleClosing);
    };
  }, [show, hidePopup, askRoseProductSlug]);

  useEffect(() => {
    if (!isPopup && window.innerWidth > 991) {
      if (!isContactSupport) {
        navigate("/");
        return;
      }
    }

    if (show && isPopup && window.innerWidth <= 991) {
      navigate("/concierge-popup");
      setIsLoading(true);
    }
  }, [isPopup, show, isContactSupport, navigate]);

  function closePopup(bookMeetingCLick) {
    if (!isPopup && !bookMeetingCLick) navigate(-1);
    hidePopup();
  }

  return (
    show &&
    !isLoading && (
      <>
        {isPopup && <RemoveScrollBar />}
        <div className="concierge-wrapper">
          <div className="concierge-popup" id="concierge-popup-chat">
            <div className="conciergeChatContent-close">
              <Close
                height="14"
                width="14"
                className="close cursor-pointer"
                onClick={() => closePopup(false)}
              />
              <div className="icon-text-holder">
                {isContactSupport ? (
                  <SupportIcon className="supportIcon mx-auto" />
                ) : (
                  <ChatConciergeIcon className="chatConciergeIcon mx-auto" />
                )}
                <p className="label mb-0">{isContactSupport ? "Support" : "Ask Rose"}</p>
              </div>
            </div>
            {/* eslint-disable-next-line react/jsx-no-bind */}
            <LandingChat setShowPopup={closePopup} />
          </div>
        </div>
      </>
    )
  );
}

ConciergePopup.defaultProps = {
  isPopup: true,
  show: false,
  isContactSupport: false,
  askRoseProductSlug: "",
};

ConciergePopup.propTypes = {
  isPopup: PropTypes.bool,
  show: PropTypes.bool,
  hidePopup: PropTypes.func.isRequired,
  isContactSupport: PropTypes.bool,
  askRoseProductSlug: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
  show: ownProps.isPage || state.global.showConciergePopup,
  isContactSupport: state.global.isSupportChat,
  goBackLocation: state.global.lastLocation,
  askRoseProductSlug: state.global.askRoseProductSlug,
});

const mapActionsToProps = (dispatch) => ({
  hidePopup: () => dispatch(hideConciergeChat()),
});

export default connect(mapStateToProps, mapActionsToProps)(ConciergePopup);
