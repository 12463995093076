import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import RootReducer from "./RootReducer";
import filterListener from "./consulting/FilterMiddleware";
import insightsFilterListener from "./insights/InsightsFilterMiddleware";
import videoLibraryFilterListener from "./videolibrary/VideoLibraryMiddleware";
import dashboardFilterListener from "./dashboard/DashboardFilterMiddleware";
import hireTeamFilterListener from "./hireateam/HireATeamFilterMiddleware";
import eventsFilterListener from "./events/EventsFilterMiddleware";
import sessionSetupListener from "./session/setup/SetupMiddleware";
import labsFilterListener from "./labs/LabsFilterMiddleware";

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

const store = createStore(
  RootReducer,
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
      filterListener,
      insightsFilterListener,
      videoLibraryFilterListener,
      dashboardFilterListener,
      sessionSetupListener,
      hireTeamFilterListener,
      eventsFilterListener,
      labsFilterListener,
    ),
  ),
);

export default store;
