import { isEmpty } from "lodash";
import {
  POPULATE_FILTERS,
  POPULATE_WEBINARS,
  TOGGLE_FILTER,
  PRESELECT_FILTER,
  ACTIVATE_PRESELECTED_FILTER,
  ACTIVATE_ALL_PRESELECTED_FILTERS,
  CLEAR_ALL_FILTERS,
  RESET,
  CLEAR_ALL_PRESELECTED_FILTERS,
  DESELECT_FILTERS,
  HAS_RECOMMENDED,
  POPULATE_NEXT_PRODUCT_PAGE,
} from "./VideoLibraryTypes";
import CommerceAPI from "../../utils/CommerceAPI";
import Helper from "../../utils/Helper";
import store from "../Store";
import VideoLibraryHelper from "../../utils/helpers/VideoLibraryHelper";

export const populateWebinars = (webinars, totalCount = 0) => {
  return {
    type: POPULATE_WEBINARS,
    webinars,
    totalCount,
  };
};

export const loadNextPage = () => {
  return {
    type: POPULATE_NEXT_PRODUCT_PAGE,
  };
};

export const hasRecommended = (value) => {
  return {
    type: HAS_RECOMMENDED,
    value,
  };
};

export const populateFilters = (value) => {
  return {
    type: POPULATE_FILTERS,
    value,
  };
};

export const toggleTag = (value) => {
  return {
    type: TOGGLE_FILTER,
    value,
  };
};

export const preselectTag = (value) => {
  return {
    type: PRESELECT_FILTER,
    value,
  };
};

export const activatePreselectedTag = (value) => {
  return {
    type: ACTIVATE_PRESELECTED_FILTER,
    value,
  };
};

export const activateAllPreselectedTags = (value) => {
  return {
    type: ACTIVATE_ALL_PRESELECTED_FILTERS,
    value,
  };
};

export const reset = () => {
  return {
    type: RESET,
  };
};

export const clearAllFilters = (value) => {
  return {
    type: CLEAR_ALL_FILTERS,
    value,
  };
};

export function clearAllPreselectedTags(value) {
  return {
    type: CLEAR_ALL_PRESELECTED_FILTERS,
    value,
  };
}

export function deselectFilters(value) {
  return {
    type: DESELECT_FILTERS,
    value,
  };
}

export function fetchAllPosts(dispatch, forcePushState = true, page = 1) {
  const filters = VideoLibraryHelper.filterOutEmptyFilters(
    store.getState().videoLibrary.activeFilters,
    forcePushState,
  );
  filters.page = page;
  filters.limit = 12;
  CommerceAPI.getAllWebinarsBO({ filters })
    .then((res) => {
      if (res.status === 200) {
        dispatch(
          populateWebinars(
            Helper.transformApiProductListToCardProps(
              res.data.data,
              "reply_col_portrait_card",
              undefined,
              undefined,
              false,
              true,
            ),
            res.data.aggregations.count.count,
          ),
        );
        return;
      }
      dispatch(populateWebinars([]));
    })
    .catch(CommerceAPI.defaultErrorHandler);
}

export function fetchAllFilters(dispatch, isProductFetch = false) {
  const { topicFilters, languageFilters, countryFilters } = store.getState().videoLibrary;
  const hasFilters =
    !isEmpty(topicFilters) && !isEmpty(languageFilters) && !isEmpty(countryFilters);
  if (!hasFilters) {
    CommerceAPI.getAllFilters()
      .then((res) => {
        if (res.status === 200) {
          dispatch(populateFilters(VideoLibraryHelper.transformFilters(res.data)));
          if (isProductFetch) {
            VideoLibraryHelper.checkIfAnyActiveFiltersInSearch(dispatch);
            fetchAllPosts(dispatch, false);
          }
          return;
        }
        dispatch({ type: POPULATE_WEBINARS, value: [] });
      })
      .catch(CommerceAPI.defaultErrorHandler);
  } else if (isProductFetch) {
    VideoLibraryHelper.checkIfAnyActiveFiltersInSearch(dispatch);
    fetchAllPosts(dispatch, false);
  }
}
