import { getSearchQueryFromURLParameters } from "./SearchHelper";

class InsightsHelper {
  static filterOutEmptyFilters(activeFilters, forcePushState = true) {
    const res = {};
    Object.keys(activeFilters).forEach((property) => {
      if (activeFilters[property].length > 0) {
        res[property] = activeFilters[property];
      }
    });

    if (forcePushState) {
      InsightsHelper.addToQuerySearch(res);
    }
    return res;
  }

  static addToQuerySearch(activeFilters) {
    let res = [];
    Object.keys(activeFilters).forEach((property) => {
      res.push(`${property}=${activeFilters[property]}`);
    });
    res = res.join("&");

    const searchQuery = getSearchQueryFromURLParameters();

    if (res.length > 0) {
      res = (searchQuery ? "&" : "?") + res;
    }
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }${searchQuery ? `?search=${searchQuery}` : ""}${res}`;

    const isTheSame = window.location.href === newurl;
    if (!isTheSame) window.history.pushState({ oldPath: window.location.href }, "", newurl);
  }
}

export default InsightsHelper;
