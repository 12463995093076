/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CommerceAPI from "../utils/CommerceAPI";
import Helper from "../utils/Helper";

const initialState = {
  isEventsLoading: false,
  pastEvents: [],
  upcomingEvents: [],
};

export const fetchAllEvents = createAsyncThunk("events/fetchAllEvents", async () => {
  const response = await CommerceAPI.getEvents();
  return response.data;
});

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllEvents.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchAllEvents.fulfilled, (state, action) => {
        state.isLoading = false;
        const events = Helper.transformApiProductListToCardProps(
          action.payload.data,
          "reply_col_portrait_card",
          undefined,
          undefined,
        );

        state.pastEvents = events
          .filter((event) => event.isPastEvent)
          .sort((a, b) => {
            if (a.createdAt > b.createdAt) return -1; // workaround to ensure VOD ordering is correct
            if (a.createdAt < b.createdAt) return 1;
            return 1;
          });

        state.upcomingEvents = events
          .filter((event) => !event.isPastEvent)
          .sort((a, b) => {
            if (a.createdAt > b.createdAt) return -1; // workaround to ensure VOD ordering is correct
            if (a.createdAt < b.createdAt) return 1;
            return 1;
          });
      });
  },
});

export const getEvents = (state) => state.events;
export const getFilters = (state) => state.filters;

// export const {  } = eventsSlice.actions;

export default eventsSlice.reducer;
