import CommerceAPI from "../../utils/CommerceAPI";
import SupportHelper from "../../utils/helpers/SupportHelper";
import { SUPPORT_INIT_CATEGORIES, SUPPORT_INIT_LANDING_FAQS } from "./SupportTypes";
import store from "../Store";

export const populateCategories = (value) => {
  return {
    type: SUPPORT_INIT_CATEGORIES,
    value,
  };
};

export const populateLandingFaqs = (value) => {
  return {
    type: SUPPORT_INIT_LANDING_FAQS,
    value,
  };
};

export async function fetchSupportCategories(dispatch) {
  CommerceAPI.getSupportCategories()
    .then((res) => {
      if (res.status === 200) {
        dispatch(populateCategories(SupportHelper.transformCategories(res.data)));
      }
    })
    .catch((e) => CommerceAPI.defaultErrorHandler(e, true));
}

export async function fetchSupportLandingFAQs(dispatch) {
  CommerceAPI.getSupportLandingFaqs()
    .then((res) => {
      const set = SupportHelper.transformSupportLandingFaq(res.data);
      dispatch(populateLandingFaqs(set));
    })
    .catch((e) => CommerceAPI.defaultErrorHandler(e, true));
}

export function initSupport(dispatch) {
  if (store.getState().support.categories.length === 0) {
    fetchSupportLandingFAQs(dispatch);
  }

  if (store.getState().support.faqs.length === 0) {
    fetchSupportCategories(dispatch);
  }
}
