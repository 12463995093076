import { SET_TRENDING_SESSIONS, RESET } from "./LandingTypes";

const initialState = {
  trendingSessions: [],
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRENDING_SESSIONS: {
      return {
        ...state,
        trendingSessions: action.trendingSessions,
      };
    }

    case RESET: {
      return {
        ...initialState,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default reducer;
