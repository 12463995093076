import { CHECKOUT_SETUP_MOVE_STEP, CLOSE_TOPUP } from "./CheckoutTypes";

export const initState = {
  step: 1,
  isLoading: false,
  isPaymentLoading: false,
  legalEntity: "",
  country: "",
  countryAdditional: {
    countryCode: "",
    taxTypeFiscalCode: "",
    taxTypeVatNumber: "",
    customerGroup: "",
    requiredFields: [],
    visibleFields: [],
    customRules: [],
    VAT: 0,
    VATCode: "",
  },
  fiscalCodeNumber: "",
  vatNumber: "",
  sdiCode: "",
  address: "",
  number: "",
  province: "",
  city: "",
  postalCode: "",
  pecEmail: "",
  name: "",
  surname: "",
  isIndividual: false,
  VATSplitPayment: "false",
  businessEmail: "",
  errorMessage: "",
  invalidMessage: "",
  APIError: "",
  addressID: "",
  initialAddress: true,
  roseID: "",
  reqBody: {},
};

// eslint-disable-next-line default-param-last
export default function CheckoutReducer(state = initState, action) {
  switch (action.type) {
    case CHECKOUT_SETUP_MOVE_STEP: {
      return {
        ...state,
        step: state.step + action.value,
      };
    }

    case CLOSE_TOPUP:
      return {
        ...state,
        step: 1,
      };

    case "update-req-body":
      return {
        ...state,
        reqBody: [action.inputValue],
      };

    case "resetSplitVAT":
      return {
        ...state,
        VATSplitPayment: "false",
      };

    case "clear-all":
      return {
        ...initState,
        step: 2,
        businessEmail: action.inputValue,
      };

    case "SDI-required":
      return {
        ...state,
        countryAdditional: {
          ...state.countryAdditional,
          requiredFields: [...state.countryAdditional.requiredFields, "sdiCode"],
        },
      };

    case "SDI-remove":
      if (state.countryAdditional.requiredFields.includes("sdiCode")) {
        state.countryAdditional.requiredFields.pop();
        return {
          ...state,
          countryAdditional: {
            ...state.countryAdditional,
            requiredFields: [...state.countryAdditional.requiredFields],
          },
        };
      }
      return {
        ...state,
      };

    case "updated-address":
      return {
        ...state,
        initialAddress: false,
      };

    case "input-change":
      return {
        ...state,
        [action.inputName]: action.inputValue ? action.inputValue : initState[action.inputName],
      };

    case "start-loading":
      return {
        ...state,
        errorMessage: "",
        isLoading: true,
      };

    case "start-loading-payment":
      return {
        ...state,
        errorMessage: "",
        isPaymentLoading: true,
      };

    case "failed-required-fields":
      return {
        ...state,
        isLoading: false,
        errorMessage: "* This field is required.",
      };

    case "failed":
      return {
        ...state,
        isLoading: false,
        errorMessage: "We failed to send your message. Please try again.",
      };

    case "errors-reset":
      return {
        ...state,
        isLoading: false,
        errorMessage: "",
        invalidMessage: "",
      };

    case "failed-valid-fields":
      return {
        ...state,
        isLoading: false,
        invalidMessage: "This field is invalid.",
      };

    case "api-error":
      return {
        ...state,
        isLoading: false,
        APIError: "Something went wrong.",
      };
    case "reset-api-error":
      return {
        ...state,
        isLoading: false,
        APIError: "",
      };

    case "stop-loading-payments":
      return {
        ...state,
        isPaymentLoading: false,
      };

    default:
      return state;
  }
}
