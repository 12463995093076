import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LoadingScreen from "../components/common/LoadingScreen";
import { fetchAuth } from "../reducers/dashboard/DashboardActions";
import AuthHelper from "../utils/AuthHelper";
import commerceAPI from "../utils/CommerceAPI";
import Constants from "../utils/Constants";
import { sendLogin as sendLoginTag } from "../utils/TagManagerHelper";
import RouteConstants from "../utils/RouteConstants";
import AWSAPI from "../utils/AWSAPI";

export const MsalContext = React.createContext();
export const useMsalContext = () => useContext(MsalContext);

export function AzureWrapper({ children, provider }) {
  const { REACT_APP_MAINTENANCE_MODE } = process.env;
  const urlParams = new URLSearchParams(window.location.search);
  const isMaintenance =
    REACT_APP_MAINTENANCE_MODE === "true" &&
    !urlParams.has(process.env.REACT_APP_MAINTENANCE_MODE_BACKDOOR);

  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const isPathnamePublic = (pathname) => {
    const enabledEndpoints = [
      "/forgot-password",
      "/change-password",
      "/signup-confirmation",
      "/country-not-available",
      "/invitation-error",
      "/service-maintenance",
      "/membership",
      "/payment-successful",
      `(${RouteConstants.PRODUCT_PAGE_URL})([A-Za-z0-9])*`,
      `(${RouteConstants.INSIGHTS_PAGE_URL})([A-Za-z0-9])*`,
      `(${RouteConstants.HIRE_A_TEAM_PAGE_URL})([A-Za-z0-9])*`,
      `(${RouteConstants.VIDEO_LIBRARY_URL})([A-Za-z0-9])*`,
      `(${RouteConstants.AREA42LABS_PAGE_URL})([A-Za-z0-9])*`,
      "/accessibility-statement",
    ];

    return enabledEndpoints.some((enabledPath) => {
      const regex = new RegExp(enabledPath);
      return regex.test(pathname) || pathname === "/";
    });
  };

  const setLoadingStateFinished = (forceRedirect = false) => {
    if (forceRedirect) {
      AuthHelper.redirectToSavedLocation(setIsLoading);
    } else {
      setIsLoading(false);
    }
  };

  const executeNewLogin = () => {
    sendLoginTag();

    if (!sessionStorage.getItem(Constants.SW_CONTEXT_TOKEN)) {
      provider
        .getAccessToken(true)
        .then((res) => {
          if (!sessionStorage.getItem(Constants.IS_LOGGED_IN_USER_DASHBOARD_ENABLED)) {
            dispatch(fetchAuth());
          }

          commerceAPI
            .login(res.accessToken)
            .then((response) => {
              sessionStorage.setItem(
                Constants.SW_CONTEXT_TOKEN,
                response.data[Constants.SW_CONTEXT_TOKEN],
              );
              sessionStorage.setItem(Constants.IS_LOGGED_IN_USER_REPLYER, response.data.isReplyer);
              const reqBody = { email: AuthHelper.getEmail() };
              AWSAPI.isQualifiedCredentials(reqBody).then((resAPI) => {
                sessionStorage.setItem(
                  Constants.IS_LOGGED_IN_USER_QUALIFIED,
                  resAPI.data.isQualified,
                );
              });
              setLoadingStateFinished(true);
            })
            .catch((e) => {
              commerceAPI.defaultErrorHandler(e);
              const status = e && e.response && e.response.status;
              if (status === 412) {
                setLoadingStateFinished(false);
              }
            });
        })
        .catch((e) => {
          commerceAPI.defaultErrorHandler(e);
        });
    } else {
      setLoadingStateFinished(true);
    }
  };

  const isPathnameBreakingEarly = (pathname) => {
    const pathnames = [
      "/signup-confirmation",
      "/country-not-available",
      "/invitation-error",
      "/service-maintenance",
      "/forgot-password",
      "/change-password",
      "/membership",
      "/payment-successful",
    ];

    return pathnames.includes(pathname);
  };

  useEffect(() => {
    if (!isMaintenance) {
      if (urlParams.has("redirect") && urlParams.get("redirect") === "home") {
        window.history.pushState({}, document.title, "/");
        AuthHelper.clearSavedRedirectLocation();
      }

      // special case for pathnames that respond to loggin in errors
      // and the change password flow
      const { pathname } = window.location;
      if (isPathnamePublic(pathname) && isPathnameBreakingEarly(pathname)) {
        setLoadingStateFinished(false);
        if (pathname === "/change-password") {
          // setBlockNotifications(false);
        }
        return;
      }

      if (provider) {
        if (provider.getAccount()) {
          if (!AuthHelper.isRegisteredFromReply(provider.getAccount()?.idToken.email || "")) {
            AuthHelper.isCountryAllowed(provider.getAccount().idToken.countryCode);
          }

          if (
            provider.getAccount().idToken.tfp === Constants.TFP_FORGOT_PASSWORD_FLOW ||
            provider.getAccount().idToken.tfp === Constants.TFP_RESET_PASSWORD_FLOW
          ) {
            setLoadingStateFinished(true);
            return;
          }

          if (provider.getAccount().idToken && provider.getAccount().idToken.exp) {
            if (moment.unix(provider.getAccount().idToken.exp).isBefore(moment())) {
              provider.refreshIdToken().then(() => {
                executeNewLogin();
              });
            } else {
              executeNewLogin();
            }
          }
        } else if (isPathnamePublic(window.location.pathname)) {
          setLoadingStateFinished(false);
        } else {
          provider.loginRedirect({ scopes: ["openid", "profile"] });
        }
      }
    } else {
      setLoadingStateFinished();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, isMaintenance]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <MsalContext.Provider value={{ isLoading }}>
      <RenderChildren isLoading={isLoading}>{children}</RenderChildren>
    </MsalContext.Provider>
  );
}

AzureWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  provider: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

AzureWrapper.defaultProps = {
  children: () => null,
  provider: () => null,
};

function RenderChildren({ children, isLoading }) {
  if (isLoading) {
    return <LoadingScreen />;
  }
  return <> {children} </>;
}

RenderChildren.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
