import DiscoveryTrendsCatalogue from "../../components/discovery/DiscoveryTrendsCatalogue";
import DiscoveryTrendCard from "../../components/discovery/DiscoveryTrendCard";
import DiscoveryButton from "../../components/discovery/DiscoveryButton";

const DiscoveryConfig = {
  apiRoot: process.env.REACT_APP_CMS_API_URL,
  apiToken: process.env.REACT_APP_CMS_TOKEN,
  propertyTitle: process.env.REACT_APP_CMS_PROPERTY_TITLE,
  components: {
    // ComponentTypeName: ComponentRenderer,
    Carousel: DiscoveryTrendsCatalogue,
    Card: DiscoveryTrendCard,
    Card_button: DiscoveryButton,
  },
  enableConnectorScript: true,
  disableCache: true,
};

export default DiscoveryConfig;
