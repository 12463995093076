import axios from "axios";
import { authProvider } from "../config/AuthProvider";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_AWS_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (authProvider.getAccount()) {
      const res = await authProvider.getAccessToken();
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${res.accessToken}`;
      return Promise.resolve(config);
    }
    return Promise.resolve(config);
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
