import { filter, find, sortBy, isEmpty } from "lodash";
import numWords from "num-words";
import moment from "moment";
import Constants from "./Constants";
import "moment-timezone";
import { createSlug } from "./slug/session-detail";
import { sendResearchEvent } from "./TagManagerHelper";

export const TOPIC_CARD = "reply_col_portrait_card";
export const TRIPLE_CARD = "reply_col_triple_card";
export const DOUBLE_CARD = "reply_col_double_card";
export const FULL_WIDTH_CARD = "reply_col_full_width_card";

class Helper {
  static toggleBodyScroll(lock) {
    document.body.style.overflow = lock ? "hidden" : "visible";
  }

  static getStripedPathName(pathname) {
    if (pathname !== "/") return pathname.replace(/\/$/, "");
    return pathname;
  }

  static propertyValueExists(array, value) {
    return array?.includes(value);
  }

  static getAskRoseCard(cards) {
    let card = {};
    // eslint-disable-next-line array-callback-return
    cards.map((item, index) => {
      if (item?.isAskRose) {
        card = {
          index,
          card: item,
        };
      }
    });
    return card;
  }

  static getDaysLeftOnBespoke(date) {
    if (moment(date).diff(moment()) > 0) {
      return this.calculateAvailableTime(moment(date).diff(moment(), "seconds"));
    }
    // quick fix for filter issue
    // TODO: refactor this, and the bespoke filters in transformApiProductListToCardProps
    return "not bespoke";
  }

  static calculateAvailableTime(differenceInSeconds) {
    if (Number.isNaN(differenceInSeconds)) {
      return null;
    }

    const amountDays = Math.floor(differenceInSeconds / (60 * 60 * 24));
    const amountHours = Math.floor(differenceInSeconds / (60 * 60));
    const amountMinutes = Math.floor(differenceInSeconds / 60);

    if (amountDays === 1) {
      return `${amountDays} day`;
    }
    if (amountDays > 1) {
      return `${amountDays} days`;
    }
    if (amountDays === 0 && amountHours === 1) {
      return `${amountHours} hour`;
    }
    if (amountDays === 0 && amountHours > 0) {
      return `${amountHours} hours`;
    }
    if (amountDays === 0 && amountMinutes === 1) {
      return `${amountMinutes} minute`;
    }
    return `${amountMinutes} minutes`;
  }

  /**
   * Transforms apiData to fit in the react component Topic Card
   * @param apiData
   * @param cardType - string
   */
  static transformApiProductListToCardProps(
    apiData,
    cardType,
    askRoseText,
    askRosePosition,
    isConsulting = false,
  ) {
    const rawCards = apiData
      .filter((entry) => entry.active === true)
      .map((entry) => {
        const nonCalculatedPrice = entry.price || entry.productPrice;

        const price =
          (nonCalculatedPrice &&
            nonCalculatedPrice[0] &&
            this.formatCreditsPrice(nonCalculatedPrice[0].gross)) ||
          (entry.calculatedPrice && this.formatCreditsPrice(entry.calculatedPrice.totalPrice)) ||
          undefined;
        const orderingIndex =
          entry?.customFields?.reply_col_session_order_index === undefined
            ? Number.POSITIVE_INFINITY
            : entry?.customFields?.reply_col_session_order_index;
        return {
          id: entry?.id,
          productNumber: entry?.productNumber,
          slug: createSlug(entry?.productNumber, entry?.name),
          bgColor: this.constructBackgroundColor(entry?.customFields),
          bgImage: entry?.cover?.media && this.findThumbnail(entry?.cover?.media, cardType),
          bgImageMobile: entry?.cover?.media && this.findThumbnail(entry?.cover?.media, "mobile"),
          title:
            entry?.customFields?.reply_col_session_categories_product_type ===
            "reply_col_session_categories_is_ask_rose"
              ? askRoseText
              : entry?.name,
          description: entry?.customFields?.reply_col_session_details_subtitle,
          isDark: entry?.customFields?.reply_col_session_font,
          price,
          expertName: entry?.manufacturer?.name,
          expertImage: entry?.manufacturer?.media?.url,
          active: entry?.active,
          isSoldOut:
            // eslint-disable-next-line no-nested-ternary
            entry?.customFields.reply_col_session_categories_DAM_content?.[0] ===
              "reply_col_session_categories_is_video" ||
            entry?.customFields.reply_col_session_categories_product_type ===
              "reply_col_session_categories_is_insights"
              ? false
              : typeof entry?.isSoldOut !== "undefined"
              ? entry.isSoldOut
              : !!(typeof entry?.isSoldOut === "undefined" && entry?.availableStock === 0),
          hideExpert: entry?.customFields?.reply_col_session_hide_expert,
          isBespoke: entry?.customFields?.reply_col_session_bespoke_customer,
          bespokeExpire:
            this.getDaysLeftOnBespoke(
              entry?.customFields?.reply_col_session_bespoke_expiration_date,
            ) || undefined,
          expireDate: entry?.customFields?.reply_col_session_bespoke_expiration_seconds,
          language: this.transformShortLanguage(entry?.customFields?.reply_col_session_language),
          fullLanguage: this.transformFullLanguage(entry?.customFields?.reply_col_session_language),
          length: parseFloat(entry?.customFields?.reply_col_session_duration),
          numberOfSessions: entry?.customFields?.reply_col_session_meetings,
          tag: entry?.tags?.[0]?.name,
          isHireATeam:
            entry?.customFields?.reply_col_session_categories_product_type === "hire_a_team",
          isInsight:
            entry?.customFields?.reply_col_session_categories_product_type ===
            "reply_col_session_categories_is_insights",
          orderingIndex,
          isAskRose:
            entry?.customFields?.reply_col_session_categories_product_type ===
            "reply_col_session_categories_is_ask_rose",
          isLiveEvent:
            entry?.customFields?.reply_col_session_categories_product_type ===
            "reply_col_session_categories_is_live_event",
          isArea42Labs:
            entry?.customFields?.reply_col_session_categories_product_type ===
            "reply_col_session_categories_is_lab",
          isWebinar:
            entry?.customFields?.reply_col_session_categories_product_type ===
              "reply_col_session_categories_is_dam_content" &&
            entry?.customFields?.reply_col_session_categories_DAM_content[0] ===
              "reply_col_session_categories_is_video",
          createdAt: entry?.createdAt,
          isEvent: entry?.customFields?.reply_col_session_categories_product_type === "is_event",
          isPastEvent: entry?.customFields?.reply_col_catalogue_event_flag_past,
          eventDate: entry?.customFields?.reply_col_catalogue_event_date,
          eventLink: entry?.customFields?.reply_col_session_webinar_event_link,
          eventCta: entry?.customFields?.reply_col_catalogue_event_cta_name,
        };
      })
      .filter((entry) => entry.isBespoke || !entry.bespokeExpire.includes("-"));

    let cards = null;
    // refactor in future
    // eslint-disable-next-line no-restricted-globals
    if (location.pathname.includes("/services/SW") || location.pathname.includes("/insights/SW")) {
      cards = rawCards;
    } else if (isConsulting) {
      cards = rawCards.sort((a, b) => a.createdAt - b.createdAt);
    } else {
      cards = rawCards;
    }

    const askRoseCard = this.getAskRoseCard(cards);

    if (!isEmpty(askRoseCard) && cards.length > 1) {
      cards.splice(askRoseCard.index, 1);
      if (askRosePosition <= 0) {
        cards.splice(0, askRoseCard.card);
      } else {
        cards.splice(askRosePosition - 1, 0, askRoseCard.card);
      }
    }

    return cards;
  }

  static findThumbnail(media, cardType) {
    if (media.thumbnails.length < 3) {
      return media.url;
    }

    const sortedThumbnails = sortBy(media.thumbnails, "width");

    switch (cardType) {
      case TOPIC_CARD:
        return sortedThumbnails[1].url;
      case DOUBLE_CARD:
        return sortedThumbnails[1].url;
      case TRIPLE_CARD:
        return sortedThumbnails[1].url;
      case FULL_WIDTH_CARD:
        return sortedThumbnails[1].url;
      default:
        return sortedThumbnails[0].url;
    }
  }

  static constructBackgroundColor(customFields) {
    if (customFields) {
      const {
        reply_col_session_top_bckgr_card: top,
        reply_col_session_middle_bckgr_card: middle,
        reply_col_session_bottom_bckgr_card: bottom,
        reply_col_session_bckgr_orientation: orientation,
      } = customFields;

      const orient = (orientation && orientation + 180) || 180;

      if (top && middle && bottom && orient) {
        return `linear-gradient(${orient}deg, ${top}, ${middle}, ${bottom})`;
      }
      if (top && middle && bottom) {
        return `linear-gradient(${top}, ${middle}, ${bottom})`;
      }
      if (top && bottom && orient) {
        return `linear-gradient(${orient}deg, ${top}, ${bottom})`;
      }
      return `linear-gradient(${top}, ${bottom})`;
    }
    return "#fff";
  }

  static getTopColor(customFields) {
    if (customFields.reply_col_session_top_bckgr_card) {
      return customFields.reply_col_session_top_bckgr_card;
    }
    return "#fff";
  }

  static formatCreditsPrice(price) {
    // if (parseInt(price, 10) === 0) {
    //   return "Free";
    // }
    return parseInt(price, 10) === 1 ? `${price} credit ` : `${price} credits`;
  }

  /**
   * Transforms apiData to fit in the react component DoubleCards
   * @param apiData
   * @param cardType
   */
  static transformApiProductListToDoubleCardProps(apiData, cardType) {
    return Helper.transformApiProductListToCardProps(apiData.slice(0, 2), cardType);
  }

  /**
   * Transforms apiData to fit in the react component TripleCards
   * @param apiData
   * @param cardType
   */
  static transformApiProductListToTripleCardProps(apiData, cardType) {
    return Helper.transformApiProductListToCardProps(apiData.slice(0, 3), cardType);
  }

  /**
   * Transforms apiData to fit in the react component FullWidthCards
   * @param {*} apiData
   * @param cardType
   */
  static transformApiProductListToFullWidthCardProps(apiData, cardType) {
    return Helper.transformApiProductListToCardProps(apiData.slice(0, 1), cardType)[0];
  }

  static transformApiProductListToFullWidthCardViewProps(apiData, cardType) {
    return Helper.transformApiProductListToCardProps(apiData.slice(0, 1), cardType);
  }

  /**
   * Updates the state in the home reducer for a given slider
   * @param {*} dispatch
   * @param {*} titleFieldName
   * @param {*} cardsFieldName
   * @param {*} titleFieldValue
   * @param {*} cardsFieldValue
   */
  static updateStateForACarousel(
    dispatch,
    titleFieldName,
    cardsFieldName,
    titleFieldValue,
    cardsFieldValue,
  ) {
    dispatch({
      type: "data",
      data: {
        [titleFieldName]: titleFieldValue,
        [cardsFieldName]: cardsFieldValue,
      },
    });
  }

  /**
   * Finds a category with the specified id
   * @param {*} categoryId
   * @param {*} listOfCategories
   */
  static findNeededCategory(categoryId, listOfCategories) {
    return find(listOfCategories, (o) => o.id === categoryId);
  }

  static transformShortLanguage(languages) {
    if (!languages) {
      return "";
    }
    return languages
      .map((language) => {
        return language.split("_")[2].toUpperCase();
      })
      .sort()
      .join(", ");
  }

  static transformFullLanguage(languages) {
    if (!languages) {
      return "";
    }
    return languages
      .map((language) => {
        return this.transformCapitalise(language.split("_")[3]);
      })
      .sort()
      .join(", ");
  }

  static getLanguageFromShopwareCode(languageCode) {
    const splitArray = languageCode.split("_");
    if (splitArray[splitArray.length - 1].length > 3) {
      const languageSplice = splitArray[splitArray.length - 1];
      return this.transformCapitalise(languageSplice);
    }
    return "NA";
  }

  static transformLanguage(languages) {
    return languages
      .map((e) => {
        return this.getLanguageFromShopwareCode(e);
      })
      .filter(Boolean)
      .join(", ");
  }

  /**
   * Splits a string of one or more comma-separated language abbreviations,
   * converts them to the full language name and joins them
   * in a comma-separated string
   * @param language language string
   */
  static parseLanguages(language) {
    return language && language.length > 1
      ? language
          .split(", ")
          .map((lang) => {
            return this.getFullLanguageString(lang);
          })
          .join(", ")
      : "";
  }

  static getFullLanguageString(language) {
    switch (language.toLowerCase()) {
      case "it":
        return "Italian";
      case "en":
        return "English";
      case "de":
        return "German";
      case "pt":
        return "Portugese";
      case "fr":
        return "French";
      case "pl":
        return "Polish";
      case "es":
        return "Spanish";
      case "tr":
        return "Turkish";
      default:
        return null;
    }
  }

  static transformCapitalise(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static calculateEndHour(startTime, duration) {
    const slot = moment(startTime, "HH:mm");
    slot.add(60 * duration, "minutes");
    return slot.format("HH:mm");
  }

  static transformApiDataToExpertPage(apiData) {
    const manufacturer = apiData;
    return {
      expertEmail: manufacturer && manufacturer?.customFields?.reply_col_expert_details_mail,
      title: manufacturer.name,
      displayName: manufacturer && manufacturer.customFields.reply_col_expert_details_display_name,
      firstName: manufacturer && manufacturer.customFields.reply_col_expert_details_first_name,
      expertRole: manufacturer && manufacturer.customFields.reply_col_expert_details_job,
      company: manufacturer && manufacturer.customFields.reply_col_expert_details_company,
      link: manufacturer && manufacturer.link,
      heroDescription: manufacturer && manufacturer.description,
      alias: manufacturer && manufacturer.media && manufacturer.media.apiAlias,
      img: manufacturer && manufacturer.media && manufacturer.media.url,
      whyShouldWeTalkSection:
        manufacturer && manufacturer.customFields.reply_col_expert_details_talk,
      carouselTitle:
        manufacturer &&
        `${manufacturer.customFields.reply_col_expert_details_display_name}'s consulting services`,
      cards: this.transformApiProductListToCardProps(manufacturer.products, TOPIC_CARD, false),
    };
  }

  /**
   * Gets the time in format HH:MM
   * @param {*} payload
   */
  static getTimeFromPayload(payload) {
    return moment(payload.customFields.reply_col_session_meeting_time).format("HH:mm");
  }

  /**
   * Gets the date in DD MMM YYYY format
   * @param {*} payload
   */
  static getDateFromPayload(payload) {
    return moment(payload.customFields.reply_col_session_meeting_time).format(
      Constants.SELECTED_DATE_FORMAT,
    );
  }

  /**
   * Gets the date in DD MMM YYYY format
   * @param {*} payload
   */
  static getMonthYearFromPayload(payload) {
    return moment(payload.customFields.reply_col_session_meeting_time).format(
      Constants.SELECTED_DATE_FORMAT,
    );
  }

  static getBookedDateFromPayload(payload) {
    return moment(payload.customFields.reply_col_session_meeting_time);
  }

  /**
   * Gets the date in DD/MM/YYYY format from 'weekday day month year'
   * @param {*} payload
   * @returns
   */
  static stringToShortDate(stringDate) {
    return moment(stringDate, Constants.SELECTED_DATE_FORMAT).format("DD/MM/YYYY");
  }

  /**
   * Gets the date in utc format from 'DD/MM/YYYY'
   * @param {*} payload
   * @returns
   */
  static formatFromUTC(stringDate) {
    return moment(stringDate).format("DD/MM/YYYY");
  }

  /**
   * Gets the day in dddd format
   * @param {*} payload
   */
  static getDayFromPayload(payload) {
    return moment(payload.customFields.reply_col_session_meeting_time).format(
      Constants.SELECTED_DAY_FORMAT,
    );
  }

  /**
   * Sorts the array by the options date in order to display the next available date
   * @param lineItemsArray
   */
  static sortLineItems(lineItemsArray) {
    return sortBy(
      lineItemsArray.map(
        (lineItem) => {
          const date = lineItem.product.customFields.reply_col_session_meeting_time;
          return {
            ...lineItem,
            date: moment(date),
          };
        },
        (lineItem) => {
          return lineItem.date;
        },
      ),
    );
  }

  static filterLineItemsAfterToday(sortedLineItems) {
    return filter(sortedLineItems, (lineItem) => {
      const date = lineItem.date.add(
        lineItem.product.customFields.reply_col_session_duration,
        "hours",
      );
      return date.isAfter(moment());
    });
  }

  static getOrderState(payload) {
    switch (payload.stateMachineState) {
      case "Open":
      case "In progress":
        return true;
      case "Done":
        return false;
      default:
        return true;
    }
  }

  static formatConfirmationDay(stringDate) {
    return moment(stringDate).format(Constants.SELECTED_DAY_FORMAT);
  }

  static formatSelectedDate(stringDate, twoCommas = false) {
    if (!twoCommas) {
      return moment(stringDate).format(Constants.SELECTED_DATE_FORMAT);
    }
    return moment(stringDate).format(Constants.SELECTED_DATE_FORMAT_TWO_COMMAS);
  }

  static formatEndDateTime(stringDate) {
    return moment(stringDate).format(Constants.DEFAULT_VOUCHER_DATETIME_FORMAT);
  }

  static formatEndDateFromDateTime(stringEndDateTime) {
    return moment(stringEndDateTime, Constants.DEFAULT_VOUCHER_DATETIME_FORMAT).format(
      Constants.DEFAULT_VOUCHER_TIME_FORMAT,
    );
  }

  /**

     * Give an array of line items, this function returns a new array containing only products.
     * E.g. it will filter out any promotions from each array.
     * @param {*} lineItems
     */
  static filterOutLineItems(lineItems) {
    return filter(lineItems, (lineItem) => {
      return lineItem && lineItem.productId !== undefined;
    });
  }

  /**
   * Returns true if the arrays are equal
   * @param {*} arr1
   * @param {*} arr2
   */
  static compareNotificationArrays(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i += 1) {
      if (arr1[i].id !== arr2[i].id) {
        return false;
      }
    }
    return true;
  }

  /**
   * This function converts numbers to string
   * E.g. 1 -> first, 2 -> second, 23 - twenty-third
   *
   * This function is taken from here -> https://stackoverflow.com/questions/20425771/how-to-replace-1-with-first-2-with-second-3-with-third-etc
   * Works ONLY up to 99.
   *
   * @param {*} n - the number to be converted
   * @returns - string
   */
  static stringifyNumber(n) {
    const underTwenty = [
      "zeroth",
      "first",
      "second",
      "third",
      "fourth",
      "fifth",
      "sixth",
      "seventh",
      "eighth",
      "ninth",
      "tenth",
      "eleventh",
      "twelfth",
      "thirteenth",
      "fourteenth",
      "fifteenth",
      "sixteenth",
      "seventeenth",
      "eighteenth",
      "nineteenth",
    ];
    const decimals = ["twent", "thirt", "fort", "fift", "sixt", "sevent", "eight", "ninet"];

    if (n < 20) return underTwenty[n];
    if (n % 10 === 0) return `${decimals[Math.floor(n / 10) - 2]}ieth`;

    return `${decimals[Math.floor(n / 10) - 2]}y-${underTwenty[n % 10]}`;
  }

  static isSafari() {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    const isSafariUA = /^((?!chrome|android).)*safari/i.test(ua);

    return iOSSafari || isSafariUA;
  }

  static capitalizeFirstLetterOfString(string) {
    return string.toLowerCase()[0].toUpperCase() + string.toLowerCase().slice(1, string.length);
  }

  static getTags(apiData) {
    if (apiData) {
      const tags = apiData.map((tag) => {
        return tag.name.toUpperCase();
      });
      return tags.join(", ");
    }
    return undefined;
  }

  /**
   * Transforms the result from GET /customer
   * @param data
   */
  static transformVoucherApiResult(data) {
    const vouchers =
      data.data &&
      data.data.map((promotion) => {
        return {
          id: promotion?.id,
          tokenName: promotion?.name,
          code: promotion.code && promotion.code.toUpperCase(),
          endDateTime: this.formatEndDateTime(promotion.validUntil),
          endDateTimeRaw: promotion.validUntil,
          individualRedemptionsText: this.getLimitationPerUser(promotion.maxRedemptionsPerCustomer),
          remainingVoucherReclaims: promotion.remainingVoucherReclaims,
          maxRedemptionsPerCustomer: promotion?.maxRedemptionsPerCustomer,
          maxRedemptionsGlobal: promotion?.maxRedemptionsGlobal,
          isEnterprise: promotion?.tokenExtension?.isEnterpriseToken,
          isTeams: promotion?.tokenExtension?.isTeamsToken,
          isTrial: promotion?.tokenExtension?.isTrialPromotion,
          tokenText: promotion?.tokenExtension?.tokenText,
        };
      });

    const firstRedeemableVoucher = vouchers.filter((v) => v.remainingVoucherReclaims > 0)[0];

    return [vouchers, firstRedeemableVoucher];
  }

  static isVoucherUnlimited(voucherValue) {
    return voucherValue === -1;
  }

  static getVoucherValueText(remainingVoucherReclaims) {
    return remainingVoucherReclaims === -1 ? "unlimited" : remainingVoucherReclaims;
  }

  /**
   * Get the first promotion with redemption > 0
   * @returns {null|string}
   * @param data
   */
  static getFirstVoucherRedemption(data) {
    // eslint-disable-next-line no-restricted-syntax
    for (const promotion of data) {
      if (promotion.remainingVoucherReclaims > 0) {
        return [promotion.remainingVoucherReclaims, promotion.maxRedemptionsPerCustomer];
      }
    }

    return [0, data[0] && data[0].maxRedemptionsPerCustomer];
  }

  static getLimitationPerUser(hasIndividualRedemptionRestriction) {
    return hasIndividualRedemptionRestriction <= 0
      ? null
      : `Limit of ${numWords(hasIndividualRedemptionRestriction)} ${
          hasIndividualRedemptionRestriction === 1 ? "use" : "uses"
        } per person.`;
  }

  static getDisplayDurationCalendar(duration) {
    if (duration === 0.5) {
      return "30 minutes";
    }
    if (duration === 1) {
      return "1 hour";
    }
    return `${duration} hours`;
  }

  static formatEuro(price) {
    let EUformatting = Intl.NumberFormat("en-DE").format(price).toString();
    if (EUformatting[EUformatting.length - 2] === ",") EUformatting += "0";

    return EUformatting;
  }

  static formatTimeRange(start, end) {
    const startTime = moment(start);
    const endTime = moment(end);
    return `${startTime.format(Constants.DEFAULT_HOUR_MINUTE_FORMAT)} - ${endTime.format(
      Constants.DEFAULT_HOUR_MINUTE_FORMAT,
    )}`;
  }

  static getSessionAmountTag(numberOfSessions) {
    return numberOfSessions > 1 ? " / meeting" : "";
  }

  /**
   * Get the label for remaining credits.
   *
   * @param {number} credits - The number of credits.
   * @param {boolean} isAsterisk - Whether to include an asterisk in the label.
   * @returns {string} The label for remaining credits.
   */
  static getRemainingCreditsLabel(credits, isAsterisk = false) {
    return `${credits === 1 ? "credit" : "credits"}${isAsterisk ? "*" : ""}`;
  }

  static directlyDownloadFile(url, buttonName, productName) {
    sendResearchEvent(buttonName, productName);
    const link = document.createElement("a");
    link.href = url;
    link.target = "_self";
    link.download = url?.substr(url.lastIndexOf("/") + 1);
    link.click();
  }

  static isVowel(word) {
    const firstLetter = word.charAt(0).toLowerCase();
    return ["a", "e", "i", "o", "u"].includes(firstLetter) ? "an " : "a ";
  }
}

export default Helper;

export function getDisplayDuration(duration) {
  return duration === 0.5 ? "30 min" : `${duration}h`;
}

export function getDisplayHourPerSession(duration, numberOfSessions) {
  if (numberOfSessions === 1) {
    return getDisplayDuration(duration);
  }
  return `${getDisplayDuration(duration)} / meeting`;
}

export function getLanguagesStringFromDamProduct(data) {
  let string = "";
  if (!data.entities) {
    return null;
  }

  const { length } = data.entities;

  data.entities.forEach((entity, index) => {
    string += entity.language;
    if (index !== length - 1) {
      string += ", ";
    }
  });

  return string ?? null;
}

/**
 * Determines whether the state should be reset based on the current and previous pathnames.
 *
 * @param {object} state - The current state of the reducer.
 * @param {string} currentPage - The path of the current page.
 * @param {string} searchPage - The path of the search results page.
 * @param {string} productPagePrefix - The prefix of the path for the product page.
 * @returns {object} - An object containing resetStateFlag (boolean) and newPreviousPathname (string).
 */
export const shouldResetState = (state, currentPage, searchPage, productPagePrefix) => {
  const { pathname, search } = window.location;

  const isCurrentPage = pathname === currentPage;
  const isSearchResultsPage = pathname === searchPage;
  const isProductPage = pathname.startsWith(productPagePrefix) && !search;

  const wasOnCurrentPage = state.previousPathname === currentPage;
  const wasOnSearchResultsPage = state.previousPathname === searchPage;

  const switchingBetweenCurrentAndSearchResults =
    (isCurrentPage && wasOnSearchResultsPage) || (isSearchResultsPage && wasOnCurrentPage);

  const resetStateFlag =
    !((isCurrentPage && search) || isSearchResultsPage || isProductPage) ||
    switchingBetweenCurrentAndSearchResults;

  const newPreviousPathname = isProductPage ? state.previousPathname : window.location.pathname;

  return {
    resetStateFlag,
    newPreviousPathname,
  };
};

/**
 * Get a formatted future event date string.
 * @param {string} dateString - A string representing the date and time of the event.
 * @param {number} duration - The duration of the event in hours.
 * @param {string} timezone - (Optional) The timezone in which the event is specified (default is "Europe/London").
 * @returns {string | null} - A formatted event date string or null if input is invalid.
 */
export const getFutureEventDate = (
  dateString,
  duration,
  timezone = Constants.TIMEZONE_FULL_NAME,
) => {
  const date = moment(`${dateString.toString().split("+")[0]}+01:00`);
  if (!date.isValid() || duration < 0) return null;
  const formattedDate = date.format("MMMM Do, YYYY");
  const [startHour, endHour] = [date.format("h:mm A"), date.add(duration, "hour").format("h:mm A")];
  const tz = date.tz(timezone).format("z");
  return `${formattedDate} | ${startHour} - ${endHour} ${tz}`;
};

export const removeItemFromArray = (arr, value) => {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};
