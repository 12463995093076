import {
  POPULATE_FILTERS,
  POPULATE_PRODUCTS,
  SET_ACTIVE_FILTERS,
  TOGGLE_FILTER,
  ACTIVE_FILTER_NUM,
  PRESELECT_FILTER,
  ACTIVATE_PRESELECTED_FILTER,
  ACTIVATE_ALL_PRESELECTED_FILTERS,
  RESET,
  CLEAR_ALL_FILTERS,
  CLEAR_ALL_PRESELECTED_FILTERS,
  CLEAR_NON_TOPIC_FILTERS,
  POPULATE_NEXT_PRODUCT_PAGE,
  DESELECT_FILTERS,
} from "./ConsultingTypes";
import ConsultingHelper from "../../utils/helpers/ConsultingHelper";
import { filterSelectedGTM } from "../../utils/TagManagerHelper";
import Helper, { shouldResetState } from "../../utils/Helper";

const initialState = {
  isProductsLoading: true,
  isFiltersLoading: true,
  products: [],
  totalProductCount: 0,

  isAdditionalProductsLoading: false,
  page: 1,
  hasNextPage: true,

  languageFilters: [],
  tagFilters: [],
  industryFilters: [],
  topicFilters: [],
  countryFilters: [],

  activeFilters: {
    languages: "",
    industries: "",
    topics: "",
    type: "",
    countries: "",
  },

  activeFilterNum: {
    languages: "",
    industries: "",
    topics: "",
    type: "",
    total: "",
    countries: "",
  },
};

function toggleFilter(filters, id, force = false, checkLabel = false) {
  return filters.map((filter) => {
    if (filter.value === id || (checkLabel && filter.label === id)) {
      return {
        ...filter,
        active: !filter.active,
        preselected: !filter.active,
      };
    }
    if (force) {
      return {
        ...filter,
        active: false,
        preselected: false,
      };
    }
    return filter;
  });
}

function preselectFilter(filters, id) {
  return filters.map((filter) => {
    if (filter.value === id) {
      return {
        ...filter,
        preselected: !filter.preselected,
      };
    }
    return filter;
  });
}

function activatePreselected(filters) {
  return filters.map((filter) => {
    return {
      ...filter,
      active: filter.preselected,
    };
  });
}

function removePreselectedFilters(filters) {
  return filters.map((filter) => {
    if (!filter.active && filter.preselected) {
      return {
        ...filter,
        preselected: false,
      };
    }
    if (filter.active && !filter.preselected) {
      return {
        ...filter,
        preselected: true,
      };
    }

    return filter;
  });
}

function deselectFilters(filters) {
  return filters.map((filter) => {
    if (filter.active || filter.preselected) {
      return {
        ...filter,
        preselected: false,
      };
    }
    return filter;
  });
}

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POPULATE_PRODUCTS: {
      let products = [];
      if (state.page > 1) {
        products = [...state.products, ...action.value];
      } else {
        products = action.value;
      }

      return {
        ...state,
        products,
        hasNextPage: products.length < action.total,
        isProductsLoading: false,
        isAdditionalProductsLoading: false,
        totalProductCount: action.total,
      };
    }

    case POPULATE_NEXT_PRODUCT_PAGE: {
      return {
        ...state,
        isAdditionalProductsLoading: true,
        page: state.page + 1,
      };
    }

    case POPULATE_FILTERS: {
      const formatList = action.value.tagFilters;
      const tagList = [];
      formatList.forEach((tag) => {
        if (
          tag.label === "Ask the expert" ||
          tag.label === "Co-design" ||
          tag.label === "Show me how"
        ) {
          tagList.push(tag);
        }
      });
      return {
        ...state,
        isFiltersLoading: false,
        industryFilters: action.value.industryFilters,
        tagFilters: tagList,
        topicFilters: action.value.topicFilters,
        languageFilters: action.value.languageFilters,
        countryFilters: action.value.countryFilters,
      };
    }

    case TOGGLE_FILTER: {
      return {
        ...state,
        isProductsLoading: true,
        products: [],
        page: 1,
        [action.value.selector]: toggleFilter(
          state[action.value.selector],
          action.value.id,
          action.value.force,
          action.value.checkLabel,
        ),
      };
    }

    case PRESELECT_FILTER: {
      return {
        ...state,
        [action.value.selector]: preselectFilter(state[action.value.selector], action.value.id),
      };
    }

    case ACTIVATE_PRESELECTED_FILTER: {
      const { selector } = action.value;
      const activeFilters = state[selector].filter((item) => item.preselected);
      activeFilters.forEach((item) => {
        filterSelectedGTM(item.label, ConsultingHelper.getFilterGALabel(selector));
      });

      return {
        ...state,
        products: [],
        page: 1,
        isProductsLoading: true,
        [action.value.selector]: activatePreselected(state[action.value.selector]),
      };
    }

    case ACTIVATE_ALL_PRESELECTED_FILTERS: {
      const filters = [
        "languageFilters",
        "tagFilters",
        "industryFilters",
        "topicFilters",
        "countryFilters",
      ];

      filters.forEach((filterItem) => {
        const activeFilters = state[filterItem].filter((item) => item.active || item.preselecteds);
        activeFilters.forEach((item) => {
          filterSelectedGTM(
            filterItem === "languageFilters"
              ? Helper.getLanguageFromShopwareCode(item.value)
              : item.label,
            ConsultingHelper.getFilterGALabel(filterItem),
          );
        });
      });

      return {
        ...state,
        products: [],
        page: 1,
        isProductsLoading: true,
        topicFilters: activatePreselected(state.topicFilters),
        industryFilters: activatePreselected(state.industryFilters),
        tagFilters: activatePreselected(state.tagFilters),
        languageFilters: activatePreselected(state.languageFilters),
        countryFilters: activatePreselected(state.countryFilters),
      };
    }

    case CLEAR_ALL_FILTERS: {
      return {
        ...state,
        isProductsLoading: true,
        products: [],
        page: 1,
        activeFilters: initialState.activeFilters,
        activeFilterNum: initialState.activeFilterNum,
        topicFilters: ConsultingHelper.addActivePropToFilter(state.topicFilters),
        industryFilters: ConsultingHelper.addActivePropToFilter(state.industryFilters),
        tagFilters: ConsultingHelper.addActivePropToFilter(state.tagFilters),
        languageFilters: ConsultingHelper.addActivePropToFilter(state.languageFilters),
        countryFilters: ConsultingHelper.addActivePropToFilter(state.countryFilters),
      };
    }

    case CLEAR_ALL_PRESELECTED_FILTERS: {
      return {
        ...state,
        topicFilters: removePreselectedFilters(state.topicFilters),
        industryFilters: removePreselectedFilters(state.industryFilters),
        tagFilters: removePreselectedFilters(state.tagFilters),
        languageFilters: removePreselectedFilters(state.languageFilters),
        countryFilters: removePreselectedFilters(state.countryFilters),
      };
    }

    case CLEAR_NON_TOPIC_FILTERS: {
      return {
        ...state,
        isProductsLoading: true,
        products: [],
        page: 1,
        activeFilters: ConsultingHelper.clearNonTopicActiveFilters(state.activeFilters),
        activeFilterNum: ConsultingHelper.clearNonTopicActiveFilterNum(state.activeFilterNum),
        industryFilters: ConsultingHelper.addActivePropToFilter(state.industryFilters),
        tagFilters: ConsultingHelper.addActivePropToFilter(state.tagFilters),
        languageFilters: ConsultingHelper.addActivePropToFilter(state.languageFilters),
        countryFilters: ConsultingHelper.addActivePropToFilter(state.countryFilters),
      };
    }

    case DESELECT_FILTERS: {
      return {
        ...state,
        topicFilters: deselectFilters(state.topicFilters),
        industryFilters: deselectFilters(state.industryFilters),
        tagFilters: deselectFilters(state.tagFilters),
        languageFilters: deselectFilters(state.languageFilters),
        countryFilters: deselectFilters(state.countryFilters),
      };
    }

    case SET_ACTIVE_FILTERS: {
      return {
        ...state,
        activeFilters: action.value,
      };
    }
    case ACTIVE_FILTER_NUM: {
      return {
        ...state,
        activeFilterNum: action.value,
      };
    }

    case RESET: {
      const { resetStateFlag, newPreviousPathname } = shouldResetState(
        state,
        "/consulting",
        "/search-results",
        "/services/",
      );

      let updatedState = {};
      if (window.location.pathname === "/consulting") {
        updatedState = { ...initialState };
      } else {
        Object.keys(initialState).forEach((key) => {
          if (key !== "previousPathname") {
            updatedState[key] = resetStateFlag ? initialState[key] : state[key];
          }
        });
      }

      return {
        ...state,
        ...updatedState,
        // Update the previousPathname
        previousPathname: newPreviousPathname,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
