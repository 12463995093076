import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ReactComponent as SuccessIcon } from "../../../assets/images/snow-chat/chat-success.svg";
import { sendChatForm } from "../../../utils/TagManagerHelper";

function ChatSuccess({ isContactSupport }) {
  useEffect(() => {
    sendChatForm();
  }, []);

  return (
    <div className="chat-success">
      <SuccessIcon />
      <h6 className="title-text medium-contrast">
        Your {isContactSupport ? "support request " : "message "}
        <br />
        has been sent
      </h6>
      {isContactSupport ? (
        <p className="mb-3 medium-contrast">
          We will take charge of it and get in touch
          <br />
          with you as soon as possible
        </p>
      ) : (
        <p className="mb-3">
          We will review your message and answer you
          <br />
          as soon as possible.
        </p>
      )}

      <p className="sub">
        {isContactSupport ? "Track your ticket in" : "Monitor the thread in"}
        <br />
        {isContactSupport ? (
          <a className="text-underline p1" href="/support/requests">
            <b>My support requests</b>
          </a>
        ) : (
          <a className="text-underline p1" href="/my-rose-messages">
            <b>My messages</b>
          </a>
        )}
        &nbsp;area.
      </p>
    </div>
  );
}

ChatSuccess.propTypes = {
  isContactSupport: PropTypes.bool,
};

ChatSuccess.defaultProps = {
  isContactSupport: false,
};

export default ChatSuccess;
