export const SET_ACTIVE_FILTERS = "videoLibrary/set-active-filters";
export const TOGGLE_FILTER = "videoLibrary/toggle-filter";
export const PRESELECT_FILTER = "videoLibrary/preselect-filter";
export const POPULATE_WEBINARS = "videoLibrary/populate-webinars";
export const POPULATE_FILTERS = "videoLibrary/populate-filters";
export const CLEAR_ALL_FILTERS = "videoLibrary/clear-all-filters";
export const ACTIVE_FILTER_NUM = "videoLibrary/active-filters-num";
export const ACTIVATE_PRESELECTED_FILTER = "videoLibrary/activate-preselected-filter";
export const ACTIVATE_ALL_PRESELECTED_FILTERS = "videoLibrary/activate-all-preselected-filters";
export const CLEAR_ALL_PRESELECTED_FILTERS = "videoLibrary/clear-all-preselected-filters";
export const DESELECT_FILTERS = "videoLibrary/deselect-filters";
export const RESET = "videoLibrary/reset";
export const HAS_RECOMMENDED = "videoLibrary/has-recommended";
export const POPULATE_NEXT_PRODUCT_PAGE = "videoLibrary/populate-next-product-page";
