// Hold route constants to be used where links are generated

const RouteConstants = {
  PRODUCT_PAGE_URL: "/services/",
  MY_PROFILE_PAGE_URL: "/my-profile",
  INSIGHTS_PAGE_URL: "/insights/",
  HIRE_A_TEAM_PAGE_URL: "/hire-a-team/",
  VIDEO_LIBRARY_URL: "/video-library/",
  EVENTS_PAGE_URL: "/video-library/",
  AREA42LABS_PAGE_URL: "/area-42-labs/",
};

export default RouteConstants;
