import React, { useEffect, useReducer, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import classnames from "classnames";
import { ReactComponent as AttachmentIcon } from "../../../assets/images/snow-chat/attachment.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/close.svg";
import { initState, reducer } from "../../../reducers/ChatFormReducer";
import CommerceAPI from "../../../utils/CommerceAPI";
import LoadingStrip from "../../common/LoadingStrip";
import { initSupport } from "../../../reducers/support/SupportActions";

function ChatForm({ setPageContent, isContactSupport, isCategoriesLoading }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const [fileSize, setFileSize] = useState(0);
  const inputRef = useRef();
  const reduxDispatch = useDispatch();
  const requiredMessage = "* This information is required";

  useEffect(() => {
    initSupport(reduxDispatch);
  }, [reduxDispatch]);

  const valueChange = (e) => {
    dispatch({
      type: "input-change",
      inputName: e.target.id,
      inputValue: e.target.value,
    });
  };

  const hasError = (id) => {
    const element = document.getElementById(id);
    if (element?.type === "text" || element?.type === "textarea") {
      return state.errorMessage && element.value.length <= 1;
    }
    return state.errorMessage && (element.value === "" || element.value === "Select a category");
  };

  const fileChange = (e) => {
    const file = e.target.files[0];
    if (file) setFileSize(file.size);
    dispatch({
      type: "input-change",
      inputName: e.target.id,
      inputValue: file,
    });
  };

  const clearAttachment = () => {
    inputRef.current.value = "";
    document.getElementById("attachment").value = "";
    if (inputRef.current && inputRef.current.files)
      setTimeout(() => {
        dispatch({
          type: "input-change",
          inputName: "attachment",
          inputValue: "",
        });
      }, 10);
  };

  const apiCall = () => {
    if (isContactSupport) {
      return CommerceAPI.createSnowCase(state.subject, state.description, state.category);
    }
    return CommerceAPI.createConciergeCase(state.subject, state.description);
  };

  const requiredFieldsValid = () => {
    if (isContactSupport) {
      return (
        state.subject !== "" &&
        state.subject.length > 1 &&
        state.description !== "" &&
        state.description.length > 1
      );
    }
    return (
      state.subject !== "" &&
      state.subject.length > 1 &&
      state.description !== "" &&
      state.description.length > 1
    );
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (requiredFieldsValid()) {
      dispatch({ type: "start-loading" });

      apiCall()
        .then((res) => {
          const caseSysId = res.data.result.sys_id;
          if (state.attachment) {
            CommerceAPI.addAttachment(caseSysId, state.attachment);
          }
          setPageContent("success");
        })
        .then(() => {
          setPageContent("success");
        })
        .catch(() => {
          CommerceAPI.defaultErrorHandler(e, true);
          setPageContent("error");
        });
    } else {
      dispatch({ type: "failed-required-fields", value: isContactSupport });
    }
  };

  return (
    <div className="chat-form">
      {isContactSupport ? (
        <h6 className="contact-title medium-contrast">Contact support</h6>
      ) : (
        <h6 className="message-title medium-contrast"> Send a message</h6>
      )}
      {!isCategoriesLoading ? (
        <form onSubmit={submitForm}>
          {" "}
          <div className="form-group p2">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="subject" className="subtitle body-1 p2 light-contrast">
              Title *
            </label>
            <input
              type="text"
              className={classnames("form-control p2", {
                "input-error": hasError("subject"),
              })}
              id="subject"
              onChange={valueChange}
              placeholder="Title"
            />
            {hasError("subject") && (
              <p className="text-danger">
                <small>{requiredMessage}</small>
              </p>
            )}
          </div>
          {isContactSupport ? (
            <div className="form-group-last p2">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="description" className="subtitle body-1 p2 light-contrast">
                Your message *
              </label>
              <textarea
                className={classnames("form-control p2", {
                  "input-error": hasError("description"),
                })}
                id="description"
                rows="5"
                onChange={valueChange}
                placeholder="Your message"
              />
              {hasError("description") && (
                <p className="text-danger">
                  <small>{requiredMessage}</small>
                </p>
              )}
            </div>
          ) : (
            <div className="form-group-last">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="description" className="subtitle body-1 p2 light-contrast">
                Your message *
              </label>
              <textarea
                className={classnames("form-control p2", {
                  "input-error": hasError("description"),
                })}
                id="description"
                rows="5"
                onChange={valueChange}
                placeholder="Your message"
              />
              {hasError("description") && (
                <p className="text-danger">
                  <small>{requiredMessage}</small>
                </p>
              )}
            </div>
          )}
          <div className="form-group">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="label small attachment-label " htmlFor="attachment">
              <AttachmentIcon width={25} height={25} className="me-2" />
              Attach file
            </label>
            <input
              type="file"
              className="attachment"
              id="attachment"
              ref={inputRef}
              onChange={fileChange}
            />
          </div>
          {state.attachment && (
            <div className="d-flex justify-content-between">
              <p className="attachment-text p2 light-contrast text-underline mb-0">
                {state.attachment.name}
                {` (${fileSize}) KB`}
              </p>
              <CloseIcon className="cursor-pointer" onClick={clearAttachment} />
            </div>
          )}
          <div className="text-center submit-wrapper margin-bottom-120px">
            <button
              type="submit"
              className="btn btn-primary button-form h8 mx-auto"
              disabled={state.isLoading}
            >
              Send
            </button>
          </div>
        </form>
      ) : (
        <LoadingStrip showLogo={false} />
      )}
    </div>
  );
}

ChatForm.propTypes = {
  setPageContent: PropTypes.func.isRequired,
  isContactSupport: PropTypes.bool,
  isCategoriesLoading: PropTypes.bool.isRequired,
};

ChatForm.defaultProps = {
  isContactSupport: false,
};

const mapStateToProps = (state) => ({
  categories: state.support.categories,
  isCategoriesLoading: state.support.isCategoriesLoading,
});

export default connect(mapStateToProps)(ChatForm);
