import {
  SESSION_SETUP_EDITING,
  SESSION_SETUP_CONFIRM,
  SESSION_SETUP_MOVE_STEP,
  SESSION_SETUP_SET_PRODUCT_NUMBER,
  SESSION_SETUP_SET_QUESTION,
  SESSION_SETUP_SET_VALUE,
  SESSION_SETUP_TOGGLE_SHOW,
  SESSION_SETUP_RESET,
  SESSION_SETUP_ERROR,
} from "./SetupTypes";

const initialState = {
  editing: false,
  show: false,
  error: null,
  step: 1,
  productNumber: "",
  selectedSession: null,
  editingSession: null,
  questions: [],
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_SETUP_CONFIRM: {
      return {
        ...state,
        show: false,
      };
    }
    case SESSION_SETUP_MOVE_STEP: {
      return {
        ...state,
        step: state.step + action.value,
      };
    }

    case SESSION_SETUP_TOGGLE_SHOW: {
      return {
        ...state,
        show: !state.show,
      };
    }
    case SESSION_SETUP_SET_PRODUCT_NUMBER:
    case SESSION_SETUP_SET_VALUE: {
      return {
        ...state,
        [action.target]: action.value,
      };
    }

    case SESSION_SETUP_SET_QUESTION: {
      return {
        ...state,
        questions: {
          ...action.value,
        },
      };
    }
    case SESSION_SETUP_EDITING: {
      return {
        ...state,
        editing: true,
        show: true,
        step: 1,
        questions: action.questions,
        editingSession: action.editingSession,
        selectedSession: action.editingSession,
      };
    }

    case SESSION_SETUP_ERROR: {
      return {
        ...state,
        step: 1,
        selectedSession: null,
        error: "The date & time you selected is not available anymore.",
      };
    }

    case SESSION_SETUP_RESET: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
