export const GLOBAL_SHOW_CHAT = "global/show-chat";
export const GLOBAL_HIDE_CHAT = "global/hide-chat";
export const GLOBAL_SHOW_SUPPORT_CHAT = "global/show-support-chat";
export const GLOBAL_SET_AVAILABILITY = "global/set-availability";
export const GLOBAL_SET_CHAT_DISABLED = "global/set-chat-disabled";
export const GLOBAL_UPDATE_BLOCK_NOTIFICATIONS = "global/update-block-notifications";
export const GLOBAL_UPDATE_NOTIFICATION_LIST = "global/update-notification-list";
export const GLOBAL_UPDATE_NOTIFICATION_STATE = "global/update-notification-state";
export const GLOBAL_UPDATE_CART_ITEMS_COUNT = "global/update-cart-items-count";
export const GLOBAL_UPDATE_EXPIRATION = "global/update-expiration";
export const GLOBAL_STOP_TIMER = "global/stop-timer";
export const GLOBAL_UPDATE_REMAINING_TIME = "global/update-remaining-time";
