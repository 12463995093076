import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { ReactComponent as TeamsLogo } from "../../../assets/images/teams-logo.svg";
import { ReactComponent as MailLogo } from "../../../assets/images/mail-logo.svg";
import CommerceAPI from "../../../utils/CommerceAPI";
import Constants from "../../../utils/Constants";
import { sendOnLeaveMessage, sendStartMeeting } from "../../../utils/TagManagerHelper";
import {
  getChatAvailability as getChatAvailabilityAction,
  setChatDisabled as setChatDisabledAction,
} from "../../../reducers/global/GlobalActions";
import RouteConstants from "../../../utils/RouteConstants";

function ConciergeChatContent({
  setPageContent,
  closeChat,
  availabilityRange,
  isChatDisabled,
  getChatAvailability,
  setChatDisabled,
  askRoseProductSlug,
  isPublicHoliday,
}) {
  const location = useLocation();
  const [joinUrl, setJoinUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    getChatAvailability(setPageContent);
    //    eslint-disable-next-line
  }, [setPageContent]);

  const triggerTimeout = () => {
    setIsLoading(false);
    setTimeout(() => {
      setPageContent("");
      setPageContent("landing");
    }, 10000);
  };

  const handleChatButtonClick = () => {
    setIsLoading(true);
    setError(null);
    setJoinUrl(null);
    CommerceAPI.getTeamsMeetingLink()
      .then((res) => {
        const link = res.data && res.data.joinUrl;
        setIsLoading(false);
        if (link) {
          sendStartMeeting();
          window.open(link, "_blank");
          setJoinUrl(link);
          triggerTimeout();
          return;
        }
        const messageCode = res.data && res.data.messageCode;

        if (messageCode === 204) {
          setError(true);
          setChatDisabled();
        } else {
          CommerceAPI.defaultErrorHandler(res.data, true);
          setPageContent("error");
        }
      })
      .catch((e) => {
        CommerceAPI.defaultErrorHandler(e, true);
        setPageContent("error");
      });
  };

  const isAugustBreak = () => {
    return moment().format("M") === "8";
  };

  return (
    <div className="conciergeChatContent">
      <div>
        {!error ? (
          <div className="wrapper">
            <p className="header-text">
              We&apos;re here to answer your questions <br className="d-none d-sm-inline" />
              and help you find solutions from across <br className="d-none d-sm-inline" />
              the Reply network.
            </p>
            <div className="wrap-box">
              <div className="title-box">
                <TeamsLogo width={28} height={22} />
                <h6 className="medium-contrast">Talk with us</h6>
              </div>
              <div className="">
                <Link
                  to={
                    askRoseProductSlug ? RouteConstants.PRODUCT_PAGE_URL + askRoseProductSlug : "#"
                  }
                  state={{ previous: location.pathname, query: location.search }}
                >
                  <button
                    type="button"
                    onClick={closeChat}
                    className={classnames(
                      "btn btn-primary start-meeting-button",
                      { link: isLoading },
                      { disabled: !askRoseProductSlug },
                    )}
                  >
                    <span className="teams-text">Book a meeting</span>
                  </button>
                </Link>
              </div>
              <hr className="gradient-divider" />
              <div className="">
                <button
                  type="button"
                  className={classnames("btn btn-primary start-meeting-button", {
                    link: isLoading,
                    disabled: isAugustBreak() || isPublicHoliday,
                  })}
                  disabled={
                    isLoading || isChatDisabled || joinUrl || isAugustBreak() || isPublicHoliday
                  }
                  onClick={handleChatButtonClick}
                >
                  <span className="teams-text">
                    {isLoading ? "Generating a link" : "Start a meeting now"}
                  </span>
                </button>
              </div>
              {joinUrl && (
                <div className="mt-11px">
                  <div className="body-2 popup-disclaimer extra-meeting-link">
                    If you are not redirected to the Microsoft <br />
                    Teams meeting, please click&nbsp;
                    <a
                      href={joinUrl}
                      target="_blank"
                      className="text-underline semi-bold"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                  </div>
                </div>
              )}
              <p className="footer-voices light-contrast">
                {isPublicHoliday ? (
                  "We will be back on August 28th"
                ) : (
                  // "In August we are taking a break, we'll be back in September"
                  <>
                    We are online from Monday - Friday,
                    <br />
                    {availabilityRange} {Constants.TIMEZONE_ABBREVIATION}
                  </>
                )}
              </p>
            </div>
          </div>
        ) : (
          <div>
            <p className="title-text mb-70px">
              Sorry, it seems the Concierge is not online at the moment
            </p>
            <div className="d-flex justify-content-center align-items-center flex-column mb-4">
              <button type="button" className="btn btn-secondary" onClick={() => closeChat()}>
                Close and try back later
              </button>
            </div>
          </div>
        )}
        <div className="wrap-box">
          <div className="title-box medium-contrast">
            <MailLogo width={32} height={22} className="mailIcon" />
            <h6>Write to us</h6>
          </div>
          <button
            type="button"
            className={`btn  ${error ? "btn-primary" : "btn-secondary leave-message"} cta-big`}
            onClick={() => {
              sendOnLeaveMessage();
              setPageContent("form");
            }}
          >
            Send a message
          </button>
          <div className="cta" />
        </div>
      </div>
    </div>
  );
}

ConciergeChatContent.propTypes = {
  setPageContent: PropTypes.func.isRequired,
  askRoseProductSlug: PropTypes.string.isRequired,
  closeChat: PropTypes.func.isRequired,
  availabilityRange: PropTypes.node,
  isChatDisabled: PropTypes.bool,
  getChatAvailability: PropTypes.func.isRequired,
  setChatDisabled: PropTypes.func.isRequired,
  isPublicHoliday: PropTypes.bool,
};

ConciergeChatContent.defaultProps = {
  availabilityRange: null,
  isChatDisabled: false,
  isPublicHoliday: false,
};

const mapDispatchToProps = (dispatch) => ({
  getChatAvailability: (setPageContent) => getChatAvailabilityAction(dispatch, setPageContent),
  setChatDisabled: () => dispatch(setChatDisabledAction()),
});

const mapStateToProps = (state) => ({
  availabilityRange: state.global.availabilityRange,
  isChatDisabled: state.global.isChatDisabled,
  askRoseProductSlug: state.global.askRoseProductSlug,
  isPublicHoliday: state.global.isPublicHoliday,
});

export default connect(mapStateToProps, mapDispatchToProps)(ConciergeChatContent);
