/* eslint-disable camelcase */
import axiosDashboardInstance from "./AxiosDashboardInstance";
import axiosInstance from "./AxiosRoseInstance";

class DashboardAPI {
  /**
   * login a user and retrieve the context token
   * @param jwt the json token with the customer information
   * @returns {Promise<>}
   */
  static getActivities(
    job_activity,
    health_status,
    cust_contact,
    reply_contact,
    min_act,
    max_act,
    order_by,
  ) {
    const body = {
      job_activity,
      health_status,
      cust_contact,
      reply_contact,
      min_act,
      max_act,
      order_by,
    };

    return axiosDashboardInstance.post("/dashboard/get-activities", body);
  }

  static getActivitiesDetails(job_activity, reply_resp, cust_resp) {
    const body = {
      job_activity,
      reply_resp,
      cust_resp,
    };
    return axiosDashboardInstance.post("/dashboard/get-activity-details", body);
  }

  static getProjects(job_activity, reply_resp, cust_resp, type) {
    const body = {
      job_activity,
      reply_resp,
      cust_resp,
      type,
    };

    return axiosDashboardInstance.post("/dashboard/get-projects", body);
  }

  static getProjectDetails(job_activity, type, prj_services_desc) {
    const body = {
      job_activity,

      type,
      prj_services_desc,
    };
    return axiosDashboardInstance.post("/dashboard/get-project-details", body);
  }

  static getAuth() {
    const body = {};
    return axiosDashboardInstance.post("/dashboard/get-auth", body);
  }

  static setStatus(reply_resp, cust_resp, activity, health_status_revised, note) {
    const body = {
      reply_resp,
      cust_resp,
      activity,
      health_status_revised,
      note,
    };

    return axiosDashboardInstance.post("/dashboard/set-status", body);
  }

  static getNote(job_activity, reply_resp, cust_resp) {
    const body = {
      job_activity,
      reply_resp,
      cust_resp,
    };
    return axiosDashboardInstance.post("/dashboard/get-note", body);
  }

  static getServices(job_activity, reply_resp, cust_resp, type) {
    const body = {
      job_activity,
      reply_resp,
      cust_resp,
      type,
    };
    return axiosDashboardInstance.post("/dashboard/get-services", body);
  }

  static getServiceDetails(job_activity, type, prj_services_desc) {
    const body = {
      job_activity,
      type,
      prj_services_desc,
    };
    return axiosDashboardInstance.post("/dashboard/get-service-details", body);
  }

  static getStatusHistory(activity, reply_resp, cust_resp, time_period_filter, view_all = "0") {
    const body = {
      activity,
      reply_resp,
      cust_resp,
      time_period_filter,
      view_all,
    };
    return axiosDashboardInstance.post("/dashboard/get-status-history", body);
  }

  static addContributors({
    activity,
    reply_resp,
    cust_resp,
    viewer_name,
    viewer_surname,
    viewer_email,
  }) {
    const body = {
      activity: Array.isArray(activity) ? [...activity] : [activity],
      reply_resp: Array.isArray(reply_resp) ? [...new Set(reply_resp)] : [reply_resp],
      cust_resp: Array.isArray(cust_resp) ? [...cust_resp] : [cust_resp],
      viewer_name,
      viewer_surname,
      viewer_email,
    };
    return axiosDashboardInstance.post("/dashboard/add-contributors", body);
  }

  static shareJob(colleague_email, colleague_full_name, job_title, count = 1) {
    const body = {
      colleague_email,
      colleague_full_name,
      job_title,
      count,
    };
    return axiosInstance.post("/share-job", body);
  }

  static getAllRequests(job_activity, reply_resp, cust_resp) {
    const body = {
      job_activity,
      reply_resp,
      cust_resp,
    };
    return axiosDashboardInstance.post("/dashboard/get-all-requests", body);
  }

  static getServiceNow(
    short_description,
    description,
    u_type,
    opened_by,
    replyContact,
    replyCompany,
    rating,
    health_status,
    date,
  ) {
    const body = {
      short_description,
      description,
      u_type,
      opened_by,
      replyContact,
      replyCompany,
      rating,
      health_status,
      date,
    };
    return axiosDashboardInstance.post("/dashboard/service-now", body);
  }

  static getSignedUrlInvoice(fileName) {
    return axiosDashboardInstance.get(`/payments/get-signed-url/${fileName}`);
  }
}

export default DashboardAPI;
