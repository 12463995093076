export const SET_ACTIVE_FILTERS_FROM_SEARCH_QUERY =
  "eventsSearch/set-active-filters-from-search-query";
export const SET_ACTIVE_FILTERS = "eventsSearch/set-active-filters";
export const TOGGLE_FILTER = "eventsSearch/toggle-filter";
export const PRESELECT_FILTER = "events/preselect-filter";
export const POPULATE_FILTERS = "eventsSearch/populate-filters";
export const POPULATE_PRODUCTS = "eventsSearch/populate-products";
export const POPULATE_NEXT_PRODUCT_PAGE = "eventsSearch/populate-next-product-page";
export const ACTIVE_FILTER_NUM = "eventsSearch/active-filters-num";
export const ACTIVATE_PRESELECTED_FILTER = "eventsSearch/activate-preselected-filter";
export const ACTIVATE_ALL_PRESELECTED_FILTERS = "eventsSearch/activate-all-preselected-filters";
export const CLEAR_ALL_FILTERS = "eventsSearch/clear-all-filters";
export const CLEAR_ALL_PRESELECTED_FILTERS = "eventsSearch/clear-all-preselected-filters";
export const DESELECT_FILTERS = "eventsSearch/deselect-filters";
export const RESET = "eventsSearch/reset";
export const CLEAR_NON_TOPIC_FILTERS = "eventsSearch/clear-non-topic-filters";
export const HAS_RECOMMENDED = "eventsSearch/has-recommended";
