import {
  ACTIVATE_ALL_PRESELECTED_FILTERS,
  ACTIVATE_PRESELECTED_FILTER,
  ACTIVE_FILTER_NUM,
  CLEAR_ALL_FILTERS,
  SET_ACTIVE_FILTERS,
  TOGGLE_FILTER,
  POPULATE_NEXT_PRODUCT_PAGE,
  CLEAR_NON_TOPIC_FILTERS,
} from "./HireATeamTypes";
import { fetchAllPosts } from "./HireATeamActions";
import { triggerSearchApiCall, resetPageCount } from "../search/SearchActions";

function isFilterAction(action) {
  return (
    action.type === TOGGLE_FILTER ||
    action.type === ACTIVATE_PRESELECTED_FILTER ||
    action.type === ACTIVATE_ALL_PRESELECTED_FILTERS ||
    action.type === CLEAR_ALL_FILTERS ||
    action.type === POPULATE_NEXT_PRODUCT_PAGE ||
    action.type === CLEAR_NON_TOPIC_FILTERS
  );
}

function isPageResetAction(action) {
  return (
    action.type === ACTIVATE_PRESELECTED_FILTER ||
    action.type === ACTIVATE_ALL_PRESELECTED_FILTERS ||
    action.type === CLEAR_ALL_FILTERS ||
    action.type === CLEAR_NON_TOPIC_FILTERS
  );
}

function filterOutNonActive(list, isTag = false) {
  return list
    .filter((item) => {
      return item.active;
    })
    .map((item) => {
      return isTag ? item.label : item.value;
    })
    .join(",");
}

function getActiveFilters(state) {
  return {
    languages: filterOutNonActive(state.languageFilters),
  };
}

function getFilterAmount(value, isTag) {
  return filterOutNonActive(value, isTag) ? filterOutNonActive(value, isTag).split(",").length : 0;
}

function getFilterLength(state) {
  const countLanguage = getFilterAmount(state.languageFilters);
  return {
    languages: countLanguage,
    total: countLanguage,
  };
}

const hireTeamFilterListener = (store) => (next) => (action) => {
  const result = next(action);
  const isSearch = action.value && action.value.isSearch;

  if (isPageResetAction(action) && isSearch) {
    store.dispatch(resetPageCount());
  }

  if (isFilterAction(action)) {
    const activeFilters = getActiveFilters(store.getState().hireATeam);
    const activeFilterNum = getFilterLength(store.getState().hireATeam);
    store.dispatch({ type: SET_ACTIVE_FILTERS, value: activeFilters });
    store.dispatch({ type: ACTIVE_FILTER_NUM, value: activeFilterNum });

    const isFirstLoad = action.value && action.value.firstLoad;

    if (!isFirstLoad) {
      if (isSearch) {
        const searchQuery = store.getState().search.query;
        const { page } = store.getState().search;
        triggerSearchApiCall(store.dispatch, searchQuery, false, true, page, 12, "HIRE A TEAM");
      } else {
        const { page } = store.getState().hireATeam;
        fetchAllPosts(store.dispatch, true, page);
      }
    }
  }

  return result;
};

export default hireTeamFilterListener;
