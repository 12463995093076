import {
  ACTIVATE_ALL_PRESELECTED_FILTERS,
  ACTIVATE_PRESELECTED_FILTER,
  ACTIVE_FILTER_NUM,
  CLEAR_ALL_FILTERS,
  POPULATE_NEXT_JOBS_PAGE,
  SET_ACTIVE_FILTERS,
  TOGGLE_FILTER,
  UPDATE_STATUS,
} from "./DashboardTypes";
import { fetchAllJobs } from "./DashboardActions";

function filterOutNonActive(list, isTag = false) {
  return list
    .filter((item) => {
      return item.active;
    })
    .map((item) => {
      return isTag ? item.label : item.value;
    })
    .join(",");
}

function getActiveFilters(state) {
  return {
    titles: filterOutNonActive(state.titleFilters),
    statuses: filterOutNonActive(state.statusFilters),
    replyContacts: filterOutNonActive(state.replyContactFilters),
    customerContacts: filterOutNonActive(state.customerContactFilters),
  };
}

function getFilterAmount(value, isTag) {
  return filterOutNonActive(value, isTag) ? filterOutNonActive(value, isTag).split(",").length : 0;
}

function getFilterLength(state) {
  const countTitles = getFilterAmount(state.titleFilters);
  const countStatuses = getFilterAmount(state.statusFilters);
  const countReplyContacts = getFilterAmount(state.replyContactFilters);
  const countCustomerContacts = getFilterAmount(state.customerContactFilters);
  return {
    titles: countTitles,
    statuses: countStatuses,
    replyContacts: countReplyContacts,
    customerContacts: countCustomerContacts,
    total: countTitles + countStatuses + countReplyContacts + countCustomerContacts,
  };
}

function isFilterAction(action) {
  return (
    action.type === TOGGLE_FILTER ||
    action.type === ACTIVATE_PRESELECTED_FILTER ||
    action.type === ACTIVATE_ALL_PRESELECTED_FILTERS ||
    action.type === CLEAR_ALL_FILTERS ||
    action.type === POPULATE_NEXT_JOBS_PAGE ||
    action.type === UPDATE_STATUS
  );
}

const dashboardFilterListener = (store) => (next) => (action) => {
  const result = next(action);

  if (isFilterAction(action)) {
    const activeFilters = getActiveFilters(store.getState().dashboard);
    const activeFilterNum = getFilterLength(store.getState().dashboard);
    store.dispatch({ type: SET_ACTIVE_FILTERS, value: activeFilters });
    store.dispatch({ type: ACTIVE_FILTER_NUM, value: activeFilterNum });

    const isFirstLoad = action.value && action.value.firstLoad;
    if (!isFirstLoad) {
      const page = action.type === UPDATE_STATUS ? store.getState().dashboard.tablePage : 1;
      const isLocalOrRFilter =
        store.getState().dashboard?.filteredJobs?.length > 0 || action.type === TOGGLE_FILTER;
      fetchAllJobs(
        store.dispatch,
        isLocalOrRFilter && action.type !== UPDATE_STATUS,
        true,
        page,
        10000,
        "JobTitle-ASC",
      );
    }
  }

  return result;
};

export default dashboardFilterListener;
