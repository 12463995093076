import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  showConciergeChat as showConciergeChatAction,
  showSupportChat as showSupportChatAction,
} from "../../reducers/global/GlobalActions";
import { sendContactConcierge } from "../../utils/TagManagerHelper";

function AskConciergeChatTrigger({
  className,
  children,
  showConciergeChat,
  showSupportChat,
  isSupport,
}) {
  const handleClick = () => {
    if (isSupport) {
      sendContactConcierge();
      showSupportChat();
    } else {
      showConciergeChat();
    }
  };

  return (
    <button onClick={handleClick} className={className} type="button">
      {children}
    </button>
  );
}

AskConciergeChatTrigger.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  showConciergeChat: PropTypes.func.isRequired,
  showSupportChat: PropTypes.func.isRequired,
  isSupport: PropTypes.bool,
};

AskConciergeChatTrigger.defaultProps = {
  isSupport: false,
};

const mapDispatchToProps = (dispatch) => ({
  showConciergeChat: () => dispatch(showConciergeChatAction()),
  showSupportChat: () => dispatch(showSupportChatAction()),
});

export default connect(null, mapDispatchToProps)(AskConciergeChatTrigger);
