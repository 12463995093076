import axios from "axios";
import Constants from "./Constants";
import Helper from "./Helper";
import awsGTWAxiosInstance from "./AWSGTWAxiosInstance";

const AWSAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_AWS_API,
});

const AWSAxiosInstanceSendBilling = axios.create({
  baseURL: process.env.REACT_APP_AWS_API,
});

class AWSAPI {
  static updateAddress(billingAddressJson, roseId, addressId) {
    const billingAddressJsonUpdated = structuredClone(billingAddressJson);
    billingAddressJsonUpdated.RoseID = roseId;
    billingAddressJsonUpdated.RoseAddressID = addressId;
    return AWSAxiosInstance.post(
      "/payments/sw-post-api/reply-api/update-address",
      billingAddressJsonUpdated,
    );
  }

  static getInsightsURL(productID) {
    return AWSAxiosInstance.post("/dam/document", productID);
  }

  static getVideoURL(productID) {
    return awsGTWAxiosInstance.post("/dam/video", productID);
  }

  static getAllowedCountries() {
    return awsGTWAxiosInstance.get("/get-allowed-countries");
  }

  static isQualifiedCredentials(email) {
    return awsGTWAxiosInstance.post("/check-qualification", email);
  }

  static orderPlaced(billingAddressJson) {
    const billingAddressJsonUpdated = structuredClone(billingAddressJson);
    billingAddressJsonUpdated.order_placed = true;
    return AWSAxiosInstanceSendBilling.post(
      "/payments/send-billing-address",
      billingAddressJsonUpdated,
    );
  }

  static createSAPUser(billingAddressJson, roseId, addressId) {
    const billingAddressJsonUpdated = structuredClone(billingAddressJson);
    billingAddressJsonUpdated.RoseID = roseId;
    billingAddressJsonUpdated.RoseAddressID = addressId;
    return AWSAxiosInstance.post("/payments/send-billing-address", billingAddressJsonUpdated);
  }

  static shopwareLogin = (RoseAddressID) => {
    return AWSAxiosInstance.get(`/payments/sw-get-api/reply-api/sw-login/${RoseAddressID}`);
  };

  static insertNewAddress = (email, billingAddressJson) => {
    return AWSAxiosInstance.post(
      `/payments/sw-post-api/reply-api/new-address/${email}`,
      billingAddressJson,
    );
  };

  static deleteAddress = (RoseAddressID) => {
    const body = {
      RoseAddressID,
    };
    return AWSAxiosInstance.post("/payments/sw-post-api/reply-api/delete-address", body);
  };

  static buyMembership = (type, duration, swContextToken) => {
    AWSAxiosInstance.defaults.headers.common[Constants.SW_CONTEXT_TOKEN] = swContextToken;
    const body = {
      type,
      duration,
    };
    return AWSAxiosInstance.post("/payments/sw-post-api/reply-api/buy-membership", body);
  };

  static deleteMembership = (orderId, subscriptionId) => {
    const body = {
      orderId,
      subscriptionId,
    };
    return AWSAxiosInstance.post("/payments/sw-post-api/reply-api/delete-membership-data", body);
  };

  static sendSuccessfulPaymentEmail = (
    businessEmail,
    customerFirstName,
    orderNumber,
    subscriptionTotal,
    subscriptionTotalVATExcluded,
    subscriptionVAT,
  ) => {
    const body = {
      businessEmail,
      customerFirstName,
      orderNumber,
      subscriptionTotal: Helper.formatEuro(subscriptionTotal),
      subscriptionTotalVATExcluded: Helper.formatEuro(subscriptionTotalVATExcluded),
      subscriptionVAT: subscriptionVAT.toString(),
    };
    return AWSAxiosInstance.post("/payments/sw-post-api/reply-api/successful-payment-email", body);
  };

  static updateOrder = (orderId, paymentId, addressId, amountTotal, amountNet) => {
    const body = {
      orderId,
      paymentId,
      addressId,
      amountTotal,
      amountNet,
    };
    return AWSAxiosInstance.post("/payments/sw-post-api/reply-api/update-order", body);
  };
}

export default AWSAPI;
