import React from "react";
import {
  SESSION_DETAIL_FAILED_LOADING,
  SESSION_DETAIL_RESET,
  SESSION_DETAIL_START_LOADING,
  SESSION_DETAIL_SUCCESSFUL_LOADING,
  SESSION_DETAIL_VALUE,
} from "./SessionDetailTypes";

const initialState = {
  isLoading: true,
  slug: null,
  title: "...",
  productNumber: "",
  isBespokeSession: "",
  isDark: false,
  heroDescription: "...",
  price: 0,
  availableDates: [],
  timeOfSession: 0,
  numberOfSessions: 0,
  currencySign: <span>&euro;</span>,
  blockNDays: 0,
  summary: "",
  heroBackgroundColor: "#fff",
  imgSrc: "",
  imgAlt: "",
  language: "",
  listOfOutcomes: [],
  sessionStructure: "",
  forWhoIsTheSession: "",
  expertSectionImg: "",
  expertSectionName: "",
  expertSectionCompany: "",
  expertSectionLanguages: [],
  requiredToolsContent: "",
  supportingMaterialsContent: "",
  tag: "",
  questions: [],
  isInsight: false,
  expertSectionDisplayName: "",
  expertSectionFirstName: "",
  calendarLowerBound: "",
  calendarUpperBound: "",
  askRose_wasPrevProductSetup: false,
  variantFetch: false,
};

// eslint-disable-next-line default-param-last
const SessionDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_DETAIL_SUCCESSFUL_LOADING: {
      return {
        ...state,
        isLoading: false,
        ...action.value,
      };
    }
    case SESSION_DETAIL_FAILED_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SESSION_DETAIL_START_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SESSION_DETAIL_VALUE: {
      return {
        ...state,
        [action.name]: action.value,
      };
    }
    case SESSION_DETAIL_RESET: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default SessionDetailReducer;
