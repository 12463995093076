import _ from "lodash";
import DashboardHelper, {
  createJobId,
  updateArray,
  updateContactsArray,
} from "../../utils/helpers/DashboardHelper";
import { removeItemFromArray } from "../../utils/Helper";
import { filterSelectedGTM } from "../../utils/TagManagerHelper";
import {
  ACTIVATE_ALL_PRESELECTED_FILTERS,
  ACTIVATE_PRESELECTED_FILTER,
  ACTIVE_FILTER_NUM,
  ADD_ALL_SELECTED_JOBS,
  ADD_DETAIL_JOB_CONTACTS,
  ADD_FORM_ERROR,
  ADD_FORM_ERROR_MESSAGE,
  ADD_FORM_VALUES,
  ADD_JOB_DETAIL,
  ADD_JOBS_IN_TABLE,
  ADD_SELECTED_CONTACT,
  ADD_SELECTED_JOB,
  ADD_SELECTED_JOB_CONTACTS,
  CLEAR_ALL_FILTERS,
  CLEAR_ALL_PRESELECTED_FILTERS,
  CLEAR_SELECTED_JOBS,
  GET_AUTH,
  LIST_OF_SELECTED_FILTERS,
  POPULATE_ACTIVITY,
  POPULATE_FILTERS,
  POPULATE_JOBS,
  PRESELECT_FILTER,
  REMOVE_SELECTED_JOB,
  REMOVE_SELECTED_JOB_CONTACTS,
  RESET,
  RESET_FORM_DATA,
  RESET_SORT,
  SET_ACTIVE_FILTERS,
  SET_ACTIVE_SORT,
  SET_EMAIL,
  SET_TABLE_PAGE,
  TOGGLE_FILTER,
  UPDATE_SHARED_JOB_CONTACTS,
  UPDATE_STATUS,
} from "./DashboardTypes";

function selectOwnerJobs(jobs) {
  const filteredJobs = jobs.filter((job) => job.func_role.trim() === "Owner");
  const extractField = (field) => filteredJobs.map((job) => job[field]);
  const selectedJobs = {
    activity: extractField("activity"),
    custResp: extractField("cust_resp"),
    replyResp: extractField("reply_resp"),
    total: filteredJobs.length,
  };
  return selectedJobs;
}

export const initialState = {
  isActivityLoading: true,
  isFiltersLoading: true,
  isTableLoading: true,
  jobs: [],
  jobsInTable: [],
  totalProductCount: 0,
  isDashAuth: false,
  customerEmail: "",

  isAdditionalProductsLoading: false,
  tablePage: 1,
  hasNextPage: true,

  titleFilters: [],
  statusFilters: [],
  replyContactFilters: [],
  customerContactFilters: [],
  sortOptions: [
    {
      label: "Job title: A-Z",
      value: "activity-ascending",
      active: true,
      preselected: false,
    },
    {
      label: "Job title: Z-A",
      value: "activity-descending",
      active: false,
      preselected: false,
    },
    {
      label: "Status: On track - Critical",
      value: "health_status-ascending",
      active: false,
      preselected: false,
    },
    {
      label: "Status: Critical - On track",
      value: "health_status-descending",
      active: false,
      preselected: false,
    },
  ],

  activeFilters: {
    titles: "",
    statuses: "",
    replyContacts: "",
    customerContacts: "",
  },

  listOfSelectedFilters: {
    titleFilters: "",
    statusFilters: "",
    replyContactFilters: "",
    customerContactFilters: "",
  },

  activeFilterNum: {
    titles: "",
    statuses: "",
    replyContacts: "",
    customerContacts: "",
    total: "",
  },
  // modal
  firstName: "",
  surname: "",
  email: "",
  errorMessage: "",
  dataCheck1: "",
  dataCheck2: "",
  selectedJobs: {
    activity: [],
    replyResp: [],
    custResp: [],
    total: 0,
  },
  selectedContacts: [],
  jobShareErrors: {
    sendTo: "",
    values: {
      firstName: "",
      lastName: "",
      email: "",
      conditionOne: false,
      conditionTwo: false,
      role: "",
    },
    errors: {
      firstName: false,
      lastName: false,
      email: false,
      conditionOne: false,
      conditionTwo: false,
    },
    messages: {
      firstName: "This field is required",
      lastName: "This field is required",
      email: "This field is required",
      conditionOne: "Please accept in order to proceed",
      conditionTwo: "Please accept in order to proceed",
    },
  },

  dataTable: {
    jobs: [],
    allContacts: [],
    sharingJobs: {
      selectedJobs: [],
    },
    selectedJobs: [],
  },
};

function removePreselectedFilters(filters) {
  return filters.map((filter) => {
    if (!filter.active && filter.preselected) {
      return {
        ...filter,
        preselected: false,
      };
    }
    if (filter.active && !filter.preselected) {
      return {
        ...filter,
        preselected: true,
      };
    }

    return filter;
  });
}

function activatePreselected(filters) {
  return filters.map((filter) => {
    return {
      ...filter,
      active: filter.preselected,
    };
  });
}

function preselectFilter(filters, id) {
  return filters.map((filter) => {
    if (filter.label === id) {
      return {
        ...filter,
        preselected: !filter.preselected,
      };
    }
    return filter;
  });
}

function toggleFilter(filters, id) {
  return filters.map((filter) => {
    if (filter.label === id) {
      return {
        ...filter,
        active: !filter.active,
        preselected: !filter.preselected,
      };
    }
    return filter;
  });
}

function clearFilters(filters) {
  return filters.map((filter) => {
    return {
      ...filter,
      active: false,
      preselected: false,
    };
  });
}

function parseJobs(jobs) {
  return jobs.map((job) => {
    return {
      ...job,
      health_status_revised: job.health_status_revised === "" ? -1 : job.health_status_revised,
    };
  });
}

function setSort(optionsList, sort) {
  return optionsList.map((options) => {
    if (options.label === sort.label) {
      return {
        ...options,
        active: true,
      };
    }
    return {
      ...options,
      active: false,
    };
  });
}

function jobFormat(jobs) {
  function generateUniqueId(job, index) {
    return job.id || createJobId(job) || index + 1;
  }
  return jobs
    ?.sort((a, b) => a.activity.localeCompare(b.activity))
    .map((job, index) => ({
      id: generateUniqueId(job, index), // Use generateUniqueId to get ID
      activity: job.activity,
      replyResp: job.reply_resp,
      companyName: job.company_name,
      custResp: job.cust_resp,
      custRole: job.cust_role,
      healthStatus: job.health_status,
      replyNoteIsTrue: job.reply_note_is_true,
      healthStatusRevised: job.health_status_revised,
      lastDateRevised: job.last_date_revised,
      funcRole: job.func_role,
      replyNote: job.reply_note,
      custNoteIsTrue: job.cust_note_is_true,
      custNote: job.cust_note,
    }));
}

function transformResponse(response) {
  // Function to generate a unique ID for each job

  const transformedData = {
    typeInfoTotal: {
      typeInfo: "Total Jobs",
      count: response.typeInfoTotal.count,
      percentage: response.typeInfoTotal.perc,
    },
    typeInfoCritical: {
      typeInfo: "Red Status",
      count: response.typeInfoCritical.count,
      percentage: response.typeInfoCritical.perc,
    },
    typeInfoWarning: {
      typeInfo: "Yellow Status",
      count: response.typeInfoWarning.count,
      percentage: response.typeInfoWarning.perc,
    },
    typeInfoOnTrack: {
      typeInfo: "Green Status",
      count: response.typeInfoOnTrack.count,
      percentage: response.typeInfoOnTrack.perc,
    },
    filters: {
      activity: response.filters.activity,
      replyResp: response.filters.reply_resp,
      custResp: response.filters.cust_resp,
    },
    jobs: jobFormat(response?.jobs),
    lastUpdate: response.last_update,
  };

  return transformedData;
}

function mergeContacts(updatedJobs) {
  const uniqueContactsArray = _.uniqBy(
    _.flatMap(updatedJobs, (job) => job.contacts || []),
    (contact) => contact.viewer_mail.toLowerCase(),
  );
  return uniqueContactsArray;
}
// eslint-disable-next-line default-param-last, consistent-return
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POPULATE_ACTIVITY: {
      const activity = action.value;
      const dataTable = transformResponse(activity);

      return {
        ...state,
        jobs: state.jobs.length > 0 ? state.jobs : parseJobs(activity.jobs),
        filteredJobs: state.jobs.length > 0 ? state.jobs : parseJobs(activity.jobs),
        last_update: DashboardHelper.parseAPIDate(activity.last_update),
        typeInfoCritical: activity.typeInfoCritical,
        typeInfoOnTrack: activity.typeInfoOnTrack,
        typeInfoTotal: activity.typeInfoTotal,
        typeInfoWarning: activity.typeInfoWarning,
        isActivityLoading: false,
        isTableLoading: false,
        dataTable,
      };
    }
    case POPULATE_FILTERS: {
      const filters = action.value;
      return {
        ...state,
        titleFilters: filters.titleFilters,
        replyContactFilters: filters.replyContactFilters,
        customerContactFilters: filters.customerContactFilters,
        statusFilters: filters.statusFilters,
        isFiltersLoading: false,
      };
    }

    case POPULATE_JOBS: {
      return {
        ...state,
        jobs: action.value,

        dataTable: {
          ...state.dataTable,
          // jobs: jobFormat(action.value),
          jobs: jobFormat(action.value),
        },

        isTableLoading: false,
        isFiltersLoading: false,
      };
    }
    case PRESELECT_FILTER: {
      return {
        ...state,
        [action.value.selector]: preselectFilter(state[action.value.selector], action.value.id),
      };
    }

    case SET_ACTIVE_FILTERS: {
      return {
        ...state,
        activeFilters: action.value,
      };
    }

    case LIST_OF_SELECTED_FILTERS: {
      return {
        ...state,
        listOfSelectedFilters: {
          ...state.listOfSelectedFilters,
          [action.value.type]: action.value.value,
        },
      };
    }

    case ACTIVE_FILTER_NUM: {
      return {
        ...state,
        activeFilterNum: action.value,
      };
    }

    case ACTIVATE_PRESELECTED_FILTER: {
      const { selector, filterType } = action.value;
      const activeFilters = state[selector].filter((item) => item.preselected);
      activeFilters.forEach((item) => {
        filterSelectedGTM(item.label, filterType);
      });

      return {
        ...state,
        jobs: [],
        page: 1,
        isTableLoading: true,
        dataTable: {
          ...state.dataTable,
          jobs: [],
        },
        [action.value.selector]: activatePreselected(state[action.value.selector]),
        selectedJobs: {
          activity: [],
          custResp: [],
          replyResp: [],
          total: 0,
        },
      };
    }

    case CLEAR_ALL_PRESELECTED_FILTERS: {
      return {
        ...state,
        titleFilters: removePreselectedFilters(state.titleFilters),
        statusFilters: removePreselectedFilters(state.statusFilters),
        replyContactFilters: removePreselectedFilters(state.replyContactFilters),
        customerContactFilters: removePreselectedFilters(state.customerContactFilters),
        sortOptions: removePreselectedFilters(state.sortOptions),
        selectedJobs: {
          activity: [],
          custResp: [],
          replyResp: [],
          total: 0,
        },
      };
    }
    case ACTIVATE_ALL_PRESELECTED_FILTERS: {
      const filters = [
        "titleFilters",
        "statusFilters",
        "replyContactFilters",
        "customerContactFilters",
      ];
      Object.values(filters).forEach((filter) => {
        const activeFilters = state[filter].filter((item) => item.preselected);
        activeFilters.forEach((item) => {
          filterSelectedGTM(item.label);
        });
      });

      return {
        ...state,
        jobs: [],
        dataTable: {
          ...state.dataTable,
          jobs: [],
        },
        page: 1,
        isTableLoading: true,
        titleFilters: activatePreselected(state.titleFilters),
        statusFilters: activatePreselected(state.statusFilters),
        replyContactFilters: activatePreselected(state.replyContactFilters),
        customerContactFilters: activatePreselected(state.customerContactFilters),
        selectedJobs: {
          activity: [],
          custResp: [],
          replyResp: [],
          total: 0,
        },
      };
    }
    case TOGGLE_FILTER: {
      return {
        ...state,
        isTableLoading: true,
        jobs: [],
        dataTable: {
          ...state.dataTable,
          jobs: [],
        },
        page: 1,
        [action.value.selector]: toggleFilter(state[action.value.selector], action.value.id),
      };
    }

    case CLEAR_ALL_FILTERS: {
      return {
        ...state,
        isProductsLoading: true,
        isTableLoading: true,
        products: [],
        page: 1,
        titleFilters: clearFilters(state.titleFilters),
        statusFilters: clearFilters(state.statusFilters),
        replyContactFilters: clearFilters(state.replyContactFilters),
        customerContactFilters: clearFilters(state.customerContactFilters),
        activeFilters: initialState.activeFilters,
        activeFilterNum: initialState.activeFilterNum,
        sortOptions: clearFilters(state.sortOptions),
        selectedJobs: {
          activity: [],
          custResp: [],
          replyResp: [],
          total: 0,
        },
      };
    }

    case SET_ACTIVE_SORT: {
      return {
        ...state,
        sortOptions: setSort(initialState.sortOptions, action.value),
      };
    }

    case RESET_SORT: {
      break;
    }

    case RESET: {
      return {
        ...initialState,
      };
    }

    case GET_AUTH: {
      return {
        ...state,
        isDashAuth: action.value,
      };
    }

    case SET_EMAIL: {
      return {
        ...state,
        customerEmail: action.value,
      };
    }

    case SET_TABLE_PAGE: {
      return {
        ...state,
        tablePage: action.value,
      };
    }

    case UPDATE_STATUS: {
      return {
        ...state,
        isFiltersLoading: true,
      };
    }

    // modal logic
    case "input-change": {
      return {
        ...state,
        [action.inputName]: action.inputValue,
      };
    }

    case "start-loading": {
      return {
        ...state,
        errorMessage: "",
      };
    }

    case "failed-required-fields": {
      return {
        ...state,
        errorMessage: "* In order to proceed, you have to accept.",
      };
    }

    case "modal-reset": {
      return {
        ...state,
        firstName: "",
        surname: "",
        email: "",
        errorMessage: "",
        dataCheck1: "",
        dataCheck2: "",
      };
    }

    // Job selection logic
    case ADD_SELECTED_JOB: {
      const job = action.value;
      const contact = {
        activity: job.activity,
        contacts: job.contacts,
      };
      const isUpdated = state.selectedJobs.activity.length === state.selectedJobs.replyResp.length;
      return {
        ...state,
        selectedJobs: {
          activity: updateArray(state.selectedJobs.activity, job.activity),
          custResp: updateArray(state.selectedJobs.custResp, job.custResp),
          replyResp: isUpdated
            ? state.selectedJobs.replyResp
            : updateArray(state.selectedJobs.replyResp, job.replyResp, false),
          total: state.selectedJobs.activity.length,
        },
        selectedContacts: updateContactsArray(state.selectedContacts, contact),
      };
    }

    case REMOVE_SELECTED_JOB: {
      const job = action.value;
      return {
        ...state,
        selectedJobs: {
          activity: removeItemFromArray(state.selectedJobs.activity, job.activity),
          custResp: removeItemFromArray(state.selectedJobs.custResp, job.custResp),
          replyResp: removeItemFromArray(state.selectedJobs.replyResp, job.replyResp),
          total: state.selectedJobs.total - 1,
        },
        selectedContacts: state.selectedContacts.filter(
          (contact) => contact.activity !== job.activity,
        ),
      };
    }

    case CLEAR_SELECTED_JOBS: {
      return {
        ...state,
        selectedJobs: {
          activity: [],
          custResp: [],
          replyResp: [],
          total: 0,
        },
        selectedContacts: [],
      };
    }

    case ADD_ALL_SELECTED_JOBS: {
      const { jobsInTable, selectedJobs } = state;

      const selectedJobsArray = selectOwnerJobs(jobsInTable);

      const updatedSelectedJobs = {
        activity: [...selectedJobs.activity, ...selectedJobsArray.activity],
        custResp: [...selectedJobs.custResp, ...selectedJobsArray.custResp],
        replyResp: [...selectedJobs.replyResp, ...selectedJobsArray.replyResp],
        total: selectedJobs.total + selectedJobsArray.total,
      };
      const selectedContacts = state.selectedContacts.concat(
        jobsInTable.map((job) => ({
          activity: job.activity,
          contacts: job.contact,
        })),
      );
      return {
        ...state,
        selectedJobs: updatedSelectedJobs,
        selectedContacts,
      };
    }

    case ADD_JOBS_IN_TABLE: {
      return {
        ...state,
        jobsInTable: action.value,
      };
    }

    case ADD_SELECTED_CONTACT: {
      const { activity, contact } = action.value;
      const [match] = state.jobs.filter((job) => job.activity === activity);
      const updatedJob = { ...match, contact: contact || [] };
      const newJobs = state.jobsInTable.filter((job) => job.activity !== activity);
      return {
        ...state,
        jobsInTable: [...newJobs, updatedJob],
      };
    }
    case ADD_FORM_ERROR: {
      return {
        ...state,
        jobShareErrors: {
          ...state.jobShareErrors,
          errors: {
            ...state.jobShareErrors.errors,
            ...action.value,
          },
        },
      };
    }
    case ADD_FORM_VALUES: {
      const sendTo = state.jobShareErrors.values.email || action.value.email;
      return {
        ...state,
        jobShareErrors: {
          ...state.jobShareErrors,
          sendTo,
          values: {
            ...state.jobShareErrors.values,
            ...action.value,
          },
        },
      };
    }
    case ADD_FORM_ERROR_MESSAGE: {
      return {
        ...state,
        jobShareErrors: {
          ...state.jobShareErrors,
          messages: {
            ...state.jobShareErrors.messages,
            ...action.value,
          },
        },
      };
    }
    case RESET_FORM_DATA: {
      const sendTo = state.jobShareErrors.values.email || state.jobShareErrors.sendTo;
      return {
        ...state,
        jobShareErrors: {
          ...initialState.jobShareErrors,
          sendTo,
        },
      };
    }
    case ADD_SELECTED_JOB_CONTACTS: {
      const { id, contact } = action.value;
      const updatedJobs = state.dataTable.jobs.map((job) =>
        job.id === id ? { ...job, contacts: contact } : job,
      );

      const allContactsSet = mergeContacts(updatedJobs);

      const selectedJobs = state.dataTable.selectedJobs
        ? [...state.dataTable.selectedJobs, id]
        : [id];

      return {
        ...state,
        dataTable: {
          ...state.dataTable,
          jobs: updatedJobs,
          allContacts: allContactsSet,
          selectedJobs,
        },
      };
    }
    case REMOVE_SELECTED_JOB_CONTACTS: {
      const jobIds = action.value;

      const updatedJobs = state.dataTable.jobs.map((job) =>
        jobIds.includes(job.id) ? { ...job, contacts: [] } : job,
      );

      const allContactsSet = mergeContacts(updatedJobs);

      const selectedJobs = state.dataTable.selectedJobs?.filter(
        (selectedJobId) => !jobIds.includes(selectedJobId),
      );

      return {
        ...state,
        dataTable: {
          ...state.dataTable,
          jobs: updatedJobs,
          allContacts: allContactsSet,
          selectedJobs,
        },
      };
    }

    case ADD_DETAIL_JOB_CONTACTS: {
      const job = action.value;

      const updatedJobs = state.dataTable?.jobs.map((j) =>
        j.id === job.id ? { ...j, contacts: job.contact } : j,
      );

      const allContactsSet = mergeContacts(updatedJobs);

      const selectedJobs = updatedJobs.filter((j) => j.id === job.id);

      return {
        ...state,
        dataTable: {
          ...state.dataTable,
          jobs: updatedJobs,
          selectedJobs,
          allContacts: allContactsSet,
        },
      };
    }

    case UPDATE_SHARED_JOB_CONTACTS: {
      const { id, contact } = action.value;
      const jobIds = Array.isArray(id) ? id.map((item) => item.id) : [];

      const updatedJobs = state.dataTable?.selectedJobs.map((job) => {
        return jobIds.includes(job.id) ? { ...job, contacts: [...job.contacts, contact] } : job;
      });

      const allContactsSet = mergeContacts(updatedJobs);

      return {
        ...state,
        dataTable: {
          ...state.dataTable,
          selectedJobs: updatedJobs,
          allContacts: allContactsSet,
        },
      };
    }

    case ADD_JOB_DETAIL: {
      const job = action.value;
      return {
        ...state,
        dataTable: {
          ...state.dataTable,
          selectedJobs: [job],
          allContacts: job.contacts,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
