import moment from "moment";
import { useEffect } from "react";
import store from "../../reducers/Store";
import DashboardAPI from "../DashboardAPI";

export const statuses = ["Critical", "Warning", "On Track"];
const classes = ["critical", "warning", "ontrack"];
const monthToIndex = {
  JAN: "01",
  FEB: "02",
  MAR: "03",
  APR: "04",
  MAY: "05",
  JUN: "06",
  JUL: "07",
  AUG: "08",
  SEP: "09",
  OCT: "10",
  NOV: "11",
  DEC: "12",
};

class DashboardHelper {
  static getStatusLabel(statusIndex) {
    return statusIndex === -1 ? "" : statuses[statusIndex];
  }

  static getStatusClass(statusIndex) {
    return statusIndex === -1 ? "" : classes[statusIndex];
  }

  static processStatusHistoryDataIntoTable(data) {
    const historyObject = {
      headers: [],
      statusRow: [],
      ratingRow: [],
      lastUpdated: [],
    };

    const groupedData = this.groupHistoryByYear(Object.values(data.data));
    // eslint-disable-next-line no-restricted-syntax
    for (const year in groupedData) {
      if (Object.prototype.hasOwnProperty.call(groupedData, year)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const month in groupedData[year]) {
          if (Object.prototype.hasOwnProperty.call(groupedData[year], month)) {
            historyObject.headers.push(
              `${Intl.DateTimeFormat("en", { month: "long" }).format(
                new Date(`${data[year][month].month}`),
              )} ${year}`,
            );
            historyObject.statusRow.push(data[year][month].health_status);
            historyObject.ratingRow.push(data[year][month].health_status_revised);
            historyObject.lastUpdated.push(data[year][month].last_update);
          }
        }
      }
    }

    return historyObject;
  }

  static groupHistoryByYear = (history) => {
    return (
      history &&
      history[0].reduce((group, val) => {
        const { year } = val;
        // eslint-disable-next-line no-param-reassign
        group[year] = group[year] ?? [];
        group[year].push(val);
        return group;
      }, {})
    );
  };

  static transformDashboardFilters(data) {
    return {
      titleFilters: DashboardHelper.addActivePropToFilter(data.activity || [], "titleFilters"),
      replyContactFilters: DashboardHelper.addActivePropToFilter(
        data.reply_resp || [],
        "replyContactFilters",
      ),
      customerContactFilters: DashboardHelper.addActivePropToFilter(
        data.cust_resp || [],
        "customerContactFilters",
      ),
      statusFilters: DashboardHelper.addActivePropToFilter(statuses || [], "statusFilters"),
    };
  }

  static addActivePropToFilter(filters, type) {
    const filterState = store.getState().dashboard[type];
    return filters.map((filter) => {
      const newFilter = filterState.filter(
        (stateFilter) => stateFilter.label === filter && stateFilter.active,
      )[0];
      return (
        newFilter || {
          label: filter,
          active: false,
          preselected: false,
          value: filter,
        }
      );
    });
  }

  static filterOutEmptyFilters(activeFilters) {
    const res = {};
    Object.keys(activeFilters).forEach((property) => {
      if (activeFilters[property].length > 0) {
        res[property] = activeFilters[property];
      }
    });

    return res;
  }

  static addToQuerySearch(activeFilters) {
    let res = [];
    Object.keys(activeFilters).forEach((property) => {
      res.push(`${property}=${activeFilters[property]}`);
    });
    res = res.join("&");

    const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${res}`;

    const isTheSame = window.location.href === newurl;
    if (!isTheSame) window.history.pushState({ oldPath: window.location.href }, "", newurl);
  }

  static parseAPIDate(date, shorthand = false) {
    if (!date) {
      return "";
    }

    if (shorthand) {
      const formattedDate = date?.replaceAll(".", "/");
      const segments = formattedDate?.split("/");

      return `${segments[0]}/${segments[1]}/${segments[2]?.substring(2, 5)}`;
    }
    return date?.replaceAll(".", "/");
  }

  static formatDate = (d) => {
    return `${d.getDate().toString().padStart(2, "0")}.${(d.getMonth() + 1)
      .toString()
      .padStart(2, "0")}.${d.getFullYear()}`;
  };

  /**
   * The Dashboard API returns dates in a format that cannot be parsed by JS, so this helper function exists to correct that.
   */
  static parseMilestoneDate(date, longDate) {
    if (date.trim() === "" || !date) {
      return "";
    }

    const segments = date.split("-");
    if (longDate) {
      return `${segments[0]}/${monthToIndex[segments[1]]}/20${segments[2]}`; // temp workaround until Machine Learning data changes are done.
    }
    return `${segments[0]}/${monthToIndex[segments[1]]}/${segments[2]}`;
  }

  static convertToClassname(value) {
    return value.toLowerCase().replaceAll(" ", "-");
  }

  static preventBackgroundScroll(value) {
    if (value) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }

  static getHistoryItemRange(value, shorthand = false, adjustTimeFrame = false, shortDate = false) {
    const format = shorthand === true ? "MMM" : "MMMM";
    let startMonth = moment(value, "dd.MM.YYYY").format(`${format} YYYY`);
    let endMonth = moment(value, "dd.MM.YYYY").add(1, "M").format(` ${format} YYYY`);

    if (adjustTimeFrame === true && value.split(".")[0] < 25) {
      startMonth = moment(value, "dd.MM.YYYY").subtract(1, "M").format(`${format} YYYY`);
      endMonth = moment(value, "dd.MM.YYYY").format(` ${format} YYYY`);
    }

    const startMonthYear = moment(startMonth, `${format} YYYY`).format("YYYY");
    const endMonthYear = moment(endMonth, `${format} YYYY`).format("YYYY");
    const startMonthFormat = startMonthYear !== endMonthYear ? `${format} YYYY` : format;

    if (shortDate) {
      startMonth = moment(startMonth, `${format} YYYY`).format("/MM/YY");
      endMonth = moment(value, "dd.MM.YYYY").format("/MM/YY");
    } else {
      startMonth = moment(startMonth, `${format} YYYY`).format(` ${startMonthFormat}`);
    }

    return `25${startMonth} - 24${endMonth}`;
  }

  static groupHistoryByMonth(jobHistory, viewAll = false) {
    // Group history items by month
    const months = jobHistory.reduce((grouped, item) => {
      const { month } = item;
      // eslint-disable-next-line no-param-reassign
      if (grouped[month] == null) grouped[month] = [];
      grouped[month].push(item);
      return grouped;
    }, {});
    if (viewAll) {
      return Object.values(months)
        .map((item) => item)
        .reverse();
    }
    // return the most recent items for each month
    return Object.values(months).map((item) => item.slice(-1)[0]);
  }

  static parseHistory(jobHistory) {
    return jobHistory.reduce((group, val) => {
      const { year } = val;
      // eslint-disable-next-line no-param-reassign
      group[year] = group[year] ?? [];
      group[year].push(val);
      return group;
    }, {});
  }

  static scrollLock(dependency, disableTouch = false) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      function preventScroll(event) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }

      if (dependency) {
        document.addEventListener("wheel", preventScroll, { passive: false });
        if (disableTouch) {
          document.body.classList.add("scroll-lock-mobile");
        }
      }

      return () => {
        document.removeEventListener("wheel", preventScroll);
        if (disableTouch) {
          document.body.classList.remove("scroll-lock-mobile");
        }
      };
    }, [dependency, disableTouch]);
  }

  static parseSnowDate(date) {
    if (!date) {
      return "";
    }

    return date.replaceAll("/", "-");
  }

  static parseSlaDates(dateString) {
    const dateArray = dateString.split("|");
    return dateArray.map((item) => {
      const date = moment(item.trim(), "MMM-YY").format("MM/YYYY");
      return `25/${date}`;
    });
  }

  static convertVariables(obj, toSnakeCase) {
    const convertCase = (input) => {
      return toSnakeCase
        ? input.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)
        : input.replace(/_([a-z])/g, (match, group) => group.toUpperCase());
    };

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        convertCase(key),
        typeof value === "object" && value !== null
          ? this.convertVariables(value, toSnakeCase)
          : value,
      ]),
    );
  }
}

export async function getJobDetails(job) {
  const { activity, reply_resp: replyResp, cust_resp: custResp } = job;
  const data = await DashboardAPI.getActivitiesDetails(activity, replyResp, custResp);

  if (data.status === 200) {
    const { activity_details: activityDetails, hs, kpi, wip } = data.data;
    return { jobDetails: activityDetails, jobHealth: hs, kpi, wip };
  }
  return null;
}

export async function getStatusHistory(job, range) {
  const { activity, reply_resp: replyResp, cust_resp: custResp } = job;
  const data = await DashboardAPI.getStatusHistory(activity, replyResp, custResp, range);
  if (data.status === 200) {
    return data.data.history;
  }
  return null;
}

export function createJobId(job) {
  const { activity, reply_resp: replyPm, cust_resp: customerPm } = job;
  const keyString = activity + replyPm + customerPm;
  return window.btoa(keyString);
}

export function updateArray(array, value, isDuplicate = true) {
  if (array.includes(value) && isDuplicate) {
    return array;
  }
  return array.concat(value);
}

export function updateContactsArray(contacts, value) {
  if (value.contacts === undefined) {
    return contacts;
  }
  return contacts.concat(value);
}

export default DashboardHelper;
