import { toggleTag } from "../../reducers/consulting/ConsultingActions";
import { getSearchQueryFromURLParameters } from "./SearchHelper";

class ConsultingHelper {
  static transformConsultFilters(data) {
    return {
      industryFilters: ConsultingHelper.addActivePropToFilter(
        data.industries?.filters || [],
        "industries",
      ),
      topicFilters: ConsultingHelper.addActivePropToFilter(data.topics?.filters || [], "topics"),
      languageFilters: ConsultingHelper.addActivePropToFilter(
        data.languages?.filters || [],
        "languages",
      ),
      tags: ConsultingHelper.addActivePropToFilter(data.tags.filters || [], "tags"),
      tagFilters: ConsultingHelper.addActivePropToFilter(data.tags.filters || [], "type"),
      countryFilters: ConsultingHelper.addActivePropToFilter(
        data.countries.filters || [],
        "countries",
      ),
    };
  }

  static clearNonTopicActiveFilters(activeFilters) {
    return {
      languages: "",
      industries: "",
      countries: "",
      tags: "",
      topics: activeFilters.topics,
      type: "",
    };
  }

  static clearNonTopicActiveFilterNum(activeFilterNum) {
    return {
      languages: "",
      industries: "",
      topics: activeFilterNum.topics,
      type: "",
      total: activeFilterNum.topics,
      countries: "",
    };
  }

  static addActivePropToFilter(filters) {
    return filters.map((filter) => {
      return {
        ...filter,
        active: false,
        preselected: false,
      };
    });
  }

  static filterOutEmptyFilters(activeFilters, forcePushState = true) {
    const res = {};
    Object.keys(activeFilters).forEach((property) => {
      if (activeFilters[property].length > 0) {
        res[property] = activeFilters[property];
      }
    });

    if (forcePushState) {
      ConsultingHelper.addToQuerySearch(res);
    }
    return res;
  }

  static addToQuerySearch(activeFilters) {
    let res = [];
    Object.keys(activeFilters).forEach((property) => {
      res.push(`${property}=${activeFilters[property]}`);
    });
    res = res.join("&");

    const searchQuery = getSearchQueryFromURLParameters();

    if (res.length > 0) {
      res = (searchQuery ? "&" : "?") + res;
    }
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }${searchQuery ? `?search=${searchQuery}` : ""}${res}`;

    const isTheSame = window.location.href === newurl;
    if (!isTheSame) window.history.pushState({ oldPath: window.location.href }, "", newurl);
  }

  static checkIfAnyActiveFiltersInSearch(dispatch) {
    const urlParams = new URLSearchParams(document.location.search);
    ConsultingHelper.populateFilterFromSearchQuery("type", "tagFilters", dispatch, urlParams, true);
    ConsultingHelper.populateFilterFromSearchQuery(
      "languages",
      "languageFilters",
      dispatch,
      urlParams,
    );
    ConsultingHelper.populateFilterFromSearchQuery(
      "industries",
      "industryFilters",
      dispatch,
      urlParams,
    );
    ConsultingHelper.populateFilterFromSearchQuery(
      "topics",
      "topicFilters",
      dispatch,
      urlParams,
      true,
    );
    ConsultingHelper.populateFilterFromSearchQuery(
      "countries",
      "countryFilters",
      dispatch,
      urlParams,
    );
  }

  static populateFilterFromSearchQuery(
    querySelector,
    stateSelector,
    dispatch,
    urlParams,
    checkLabel = false,
  ) {
    if (urlParams.has(querySelector)) {
      const params = urlParams.get(querySelector).split(",");
      params.forEach((tag) => {
        dispatch(
          toggleTag({
            selector: stateSelector,
            id: tag,
            firstLoad: true,
            checkLabel,
          }),
        );
      });
    }
  }

  static getURLWithTopicSearchFilters(urlParams) {
    const topicFilter = urlParams.get("topics");
    return `?topics=${topicFilter}`;
  }

  static getFilterGALabel(name) {
    switch (name) {
      case "languageFilters":
        return "language";
      case "tagFilters":
        return "format";
      case "industryFilters":
        return "industry";
      case "topicFilters":
        return "topic";
      case "countryFilters":
        return "country";
      default:
        return null;
    }
  }
}

export default ConsultingHelper;
