import DashboardAPI from "../../utils/DashboardAPI";
import DashboardHelper, { createJobId } from "../../utils/helpers/DashboardHelper";
import store from "../Store";
import AuthHelper from "../../utils/AuthHelper";

import {
  ACTIVATE_ALL_PRESELECTED_FILTERS,
  ACTIVATE_PRESELECTED_FILTER,
  ADD_ALL_SELECTED_JOBS,
  ADD_FORM_ERROR,
  ADD_FORM_ERROR_MESSAGE,
  ADD_FORM_VALUES,
  ADD_JOBS_IN_TABLE,
  ADD_SELECTED_CONTACT,
  ADD_SELECTED_JOB,
  ADD_SELECTED_JOB_CONTACTS,
  CLEAR_ALL_FILTERS,
  CLEAR_ALL_PRESELECTED_FILTERS,
  CLEAR_SELECTED_JOBS,
  GET_AUTH,
  LIST_OF_SELECTED_FILTERS,
  POPULATE_ACTIVITY,
  POPULATE_FILTERS,
  POPULATE_JOBS,
  PRESELECT_FILTER,
  REMOVE_SELECTED_JOB,
  RESET,
  RESET_FORM_DATA,
  RESET_SORT,
  SET_ACTIVE_SORT,
  SET_EMAIL,
  SET_TABLE_PAGE,
  TOGGLE_FILTER,
  UPDATE_STATUS,
  REMOVE_SELECTED_JOB_CONTACTS,
  ADD_DETAIL_JOB_CONTACTS,
  UPDATE_SHARED_JOB_CONTACTS,
  ADD_JOB_DETAIL,
} from "./DashboardTypes";
import Constants from "../../utils/Constants";
import { DSB_STATUSES, ratingChangeJobTablePageGTM } from "../../utils/TagManagerHelper";

/**
 *
 * @param jobs  array of jobs
 * @param filters {activity: [], health_status: [], cust_resp: [], reply_resp: []}
 * @returns {Array} filtered jobs
 */
const multipleFilter = (jobs, filters) => {
  const activeFilters = Object.keys(filters).filter((key) => String(filters[key]) !== "-1");

  if (activeFilters.length === 0) return jobs;

  return jobs.filter((job) => {
    return activeFilters.every((filterKey) => {
      const jobValue = job[filterKey];
      const filterValue = filters[filterKey];

      if (Array.isArray(filterValue)) {
        return filterValue.includes(jobValue);
      }
      return jobValue === filterValue;
    });
  });
};

export const populateActivity = (value) => {
  return {
    type: POPULATE_ACTIVITY,
    value,
  };
};

export const populateFilters = (value) => {
  return {
    type: POPULATE_FILTERS,
    value,
  };
};

export const populateJobs = (value) => {
  return {
    type: POPULATE_JOBS,
    value,
  };
};

export const activatePreselectedFilter = (value) => {
  return {
    type: ACTIVATE_PRESELECTED_FILTER,
    value,
  };
};

export const preselectTag = (value) => {
  return {
    type: PRESELECT_FILTER,
    value,
  };
};

export function clearAllPreselectedFilters(value) {
  return {
    type: CLEAR_ALL_PRESELECTED_FILTERS,
    value,
  };
}

export const activateAllPreselectedFilters = (value) => {
  return {
    type: ACTIVATE_ALL_PRESELECTED_FILTERS,
    value,
  };
};

export const toggleTag = (value) => {
  return {
    type: TOGGLE_FILTER,
    value,
  };
};

export const reset = () => {
  return {
    type: RESET,
  };
};

export const clearAllFilters = (value) => {
  return {
    type: CLEAR_ALL_FILTERS,
    value,
  };
};

export const getAuth = (value) => {
  return {
    type: GET_AUTH,
    value,
  };
};

export const setEmail = (value) => {
  return {
    type: SET_EMAIL,
    value,
  };
};

export const setActiveSort = (value) => {
  return {
    type: SET_ACTIVE_SORT,
    value,
  };
};

export const resetSort = (value) => {
  return {
    type: RESET_SORT,
    value,
  };
};

export const setTablePage = (value) => {
  return {
    type: SET_TABLE_PAGE,
    value,
  };
};

export const updateStatus = (value) => {
  return {
    type: UPDATE_STATUS,
    value,
  };
};

export const listOfSelectedFilters = (value) => {
  return {
    type: LIST_OF_SELECTED_FILTERS,
    value,
  };
};

export const addSelectedJob = (value) => {
  return {
    type: ADD_SELECTED_JOB,
    value,
  };
};

export const removeSelectedJob = (value) => {
  return {
    type: REMOVE_SELECTED_JOB,
    value,
  };
};

export const clearSelectedJobs = () => {
  return {
    type: CLEAR_SELECTED_JOBS,
  };
};

export const addJobsInTable = (value) => {
  return {
    type: ADD_JOBS_IN_TABLE,
    value,
  };
};

export const addAllSelectedJobs = () => {
  return {
    type: ADD_ALL_SELECTED_JOBS,
  };
};
export const updateJobContacts = (value) => {
  return {
    type: UPDATE_SHARED_JOB_CONTACTS,
    value,
  };
};

// eslint-disable-next-line camelcase
export function fetchActivity(max_act = 10000, sort = "JobTitle-ASC") {
  return async (dispatch) => {
    const res = await DashboardAPI.getActivities(["-1"], [-1], ["-1"], ["-1"], 1, max_act, sort);
    if (res.status === 200) {
      const { jobs } = res.data;
      const indexedJobs = jobs.map((job) => {
        return {
          ...job,
          id: createJobId(job),
        };
      });
      const data = { ...res.data, jobs: indexedJobs };
      dispatch(populateActivity(data));
      return;
    }
    dispatch(populateActivity(null));
  };
}

export function fetchAllJobs(
  dispatch,
  isLocalOrRFilter,
  forcePushState = false,
  page = 1,
  // eslint-disable-next-line camelcase
  max_act = 10000,
  sort = "JobTitle-ASC",
) {
  const filters = DashboardHelper.filterOutEmptyFilters(
    store.getState().dashboard.activeFilters,
    forcePushState,
  );
  const statusList = ["Critical", "Warning", "On Track"];
  filters.page = page;
  filters.limit = 12;

  let { titles = "-1", statuses = "-1", replyContacts = "-1", customerContacts = "-1" } = filters;

  statuses = statuses.split(",").map((item) => statusList.indexOf(item));
  titles = titles.split(",");
  replyContacts = replyContacts.split(",");
  customerContacts = customerContacts.split(",");

  // check if store has jobs
  if (isLocalOrRFilter) {
    const jobs = multipleFilter(store.getState().dashboard.filteredJobs, {
      activity: titles,
      health_status: statuses,
      cust_resp: customerContacts,
      reply_resp: replyContacts,
    });
    dispatch(populateJobs(jobs));
  } else {
    // fetch jobs from api
    DashboardAPI.getActivities(
      titles,
      statuses,
      customerContacts,
      replyContacts,
      1,
      max_act,
      sort,
    ).then((res) => {
      if (res.status === 200) {
        dispatch(populateJobs(res.data.jobs));
        return;
      }
      dispatch(populateJobs([]));
    });
  }
}

// eslint-disable-next-line camelcase
export function fetchAllFilters(max_act = 10000, sort = "JobTitle-ASC") {
  return async (dispatch) => {
    const res = await DashboardAPI.getActivities(["-1"], [-1], ["-1"], ["-1"], 1, max_act, sort);
    if (res.status === 200) {
      dispatch(populateFilters(DashboardHelper.transformDashboardFilters(res.data.filters)));
      dispatch(updateStatus());
      return;
    }
    dispatch(populateFilters([]));
  };
}

export function fetchAuth() {
  return async (dispatch) => {
    const res = await DashboardAPI.getAuth();
    if (res.status === 200) {
      sessionStorage.setItem(Constants.IS_LOGGED_IN_USER_DASHBOARD_ENABLED, res.data.cust_exist);
      dispatch(getAuth(res.data.cust_exist));
      return;
    }
    dispatch(getAuth(false));
  };
}

export function fetchServiceNow(data) {
  // eslint-disable-next-line camelcase
  const { job, message, selected, oid, last_update } = data;

  return async () => {
    const res = await DashboardAPI.getServiceNow(
      job.activity,
      message,
      "2",
      oid,
      job.reply_resp || job.custResp,
      job.company_name || job.companyName,
      DashboardHelper.getStatusLabel(selected),
      DashboardHelper.getStatusLabel(job.health_status || job.healthStatus),
      DashboardHelper.parseSnowDate(last_update),
    );

    return res.status;
  };
}

export function changeJobStatus(data) {
  // eslint-disable-next-line camelcase
  const { job, message, selected, last_update } = data;

  return async (dispatch) => {
    const res = await DashboardAPI.setStatus(
      job.reply_resp,
      job.cust_resp,
      job.activity,
      selected,
      message,
    );
    if (res.status === 200) {
      const dataLayer = {
        step: "changed",
        jobTitle: job.activity,
        companyName: job?.company_name,
        replyCurrentStatus: DSB_STATUSES[job.health_status],
        userCurrentRating: DSB_STATUSES[selected],
        userPrevRating: DSB_STATUSES[job.health_status_revised],
        lastUpdate: job?.last_date_revised,
      };
      ratingChangeJobTablePageGTM(dataLayer);
      dispatch(fetchActivity());
      dispatch(updateStatus());
      if (DashboardHelper.getStatusLabel(selected) === "Critical") {
        const oid = AuthHelper.getUserOid();
        // eslint-disable-next-line camelcase
        dispatch(fetchServiceNow({ job, message, selected, oid, last_update }));
      }
      return 200;
    }
    return res.status;
  };
}

export function statusJobChanged(data) {
  // eslint-disable-next-line camelcase
  const { job, message, selected, last_update } = data;

  return async (dispatch) => {
    const res = await DashboardAPI.setStatus(
      job.replyResp,
      job.custResp,
      job.activity,
      selected,
      message,
    );
    if (res.status === 200) {
      const dataLayer = {
        step: "changed",
        jobTitle: job.activity,
        companyName: job?.companyName,
        replyCurrentStatus: DSB_STATUSES[job.healthStatus],
        userCurrentRating: DSB_STATUSES[selected],
        userPrevRating: DSB_STATUSES[job.healthStatusRevised],
        lastUpdate: job?.lastDateRevised,
      };
      ratingChangeJobTablePageGTM(dataLayer);
      dispatch(fetchActivity());
      dispatch(updateStatus());
      if (DashboardHelper.getStatusLabel(selected) === "Critical") {
        const oid = AuthHelper.getUserOid();
        // eslint-disable-next-line camelcase
        dispatch(fetchServiceNow({ job, message, selected, oid, last_update }));
      }
      return 200;
    }
    return res.status;
  };
}

export const addSelectedContact = (value) => {
  return {
    type: ADD_SELECTED_CONTACT,
    value,
  };
};

export const addJobContacts = (value) => {
  return {
    type: ADD_SELECTED_JOB_CONTACTS,
    value,
  };
};

export const addJobDetail = (value) => {
  return {
    type: ADD_JOB_DETAIL,
    value,
  };
};

export const addFormErrors = (value) => {
  return {
    type: ADD_FORM_ERROR,
    value,
  };
};
export const addFormValues = (value) => {
  return {
    type: ADD_FORM_VALUES,
    value,
  };
};
export const addFormErrorMessage = (value) => {
  return {
    type: ADD_FORM_ERROR_MESSAGE,
    value,
  };
};
export const resetFormData = () => {
  return {
    type: RESET_FORM_DATA,
  };
};

export const addJobDetailContacts = (value) => {
  return {
    type: ADD_DETAIL_JOB_CONTACTS,
    value,
  };
};

export function fetchContacts(jobs) {
  return async (dispatch) => {
    jobs.forEach(async (job) => {
      const { activity, reply_resp: replyResp, cust_resp: custResp } = job;
      const contact = await DashboardAPI.getAllRequests(activity, replyResp, custResp);
      dispatch(addSelectedContact({ activity, contact: contact.data.requests }));
    });
  };
}

export const fetchAllContacts = (jobs) => {
  return async (dispatch) => {
    try {
      await Promise.all(
        jobs.map(async (job) => {
          const { id, activity, replyResp, custResp } = job;
          const { data } = await DashboardAPI.getAllRequests(activity, replyResp, custResp);
          dispatch(addJobContacts({ id, activity, contact: data.requests }));
        }),
      );
    } catch (error) {
      // Handle errors gracefully, e.g., log the error or dispatch an error action
      // eslint-disable-next-line no-console
      console.error("Error fetching contacts:", error.message);
    }
  };
};
export const fetchOnlyContacts = (job) => {
  // eslint-disable-next-line consistent-return
  return async (dispatch) => {
    try {
      const { id, activity, replyResp, custResp } = job;
      const { data } = await DashboardAPI.getAllRequests(activity, replyResp, custResp);
      dispatch(addJobContacts({ id, activity, contact: data.requests }));
      return data;
    } catch (error) {
      // Handle errors gracefully, e.g., log the error or dispatch an error action
      // eslint-disable-next-line no-console
      console.error("Error fetching contacts:", error.message);
    }
  };
};

export const fetchDetailJobContacts = (job) => {
  // eslint-disable-next-line consistent-return
  return async (dispatch) => {
    try {
      const { id, activity, replyResp, custResp } = job;
      const { data } = await DashboardAPI.getAllRequests(activity, replyResp, custResp);
      dispatch(addJobDetailContacts({ id, activity, contact: data.requests }));
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error fetching contacts:", error.message);
    }
  };
};

export const removeSelectedJobContacts = (value) => {
  return {
    type: REMOVE_SELECTED_JOB_CONTACTS,
    value,
  };
};

export const fetchSingleActivity = (jobId) => async (dispatch) => {
  try {
    const response = await DashboardAPI.getActivities(
      ["-1"],
      [-1],
      ["-1"],
      ["-1"],
      1,
      10000,
      "JobTitle-ASC",
    );

    if (response.status !== 200) {
      throw new Error(`Failed to fetch activities. Status code: ${response.status}`);
    }

    const indexedJobs = response.data.jobs.map((job) => ({ ...job, id: createJobId(job) }));
    const singleActivity = indexedJobs.find((job) => job.id === jobId);

    if (!singleActivity) {
      throw new Error("Job not found");
    }

    const { activity, reply_resp: replyResp, cust_resp: custResp } = singleActivity;
    const requestsResponse = await DashboardAPI.getAllRequests(activity, replyResp, custResp);
    const responseDetails = {
      ...DashboardHelper.convertVariables(singleActivity, false),
      contacts: requestsResponse.data.requests,
    };

    dispatch(addJobDetail({ id: jobId, ...responseDetails }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error fetching single activity: ${error.message}`);
  }
};
