import POPULATE_VOUCHERS from "./VoucherTypes";

const initialState = {
  vouchers: [],
  redeemableVoucher: {},
  vouchersFetched: false,
  isVoucherLoading: true,
  freeSessionsLeft: 0,
  totalFreeSessions: 0,
};
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POPULATE_VOUCHERS: {
      return {
        ...state,
        vouchersFetched: true,
        vouchers: action.vouchers,
        firstRedeemableVoucher: action.firstRedeemableVoucher,
        isVoucherLoading: false,
        freeSessionsLeft: action.freeSessionsLeft,
        totalFreeSessions: action.totalFreeSessions,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
