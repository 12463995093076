/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import React from "react";
import classnames from "classnames";
import DiscoveryButton from "./DiscoveryButton";
import BackgroundConstructor from "../common/BackgroundConstructor";

function DiscoveryTrendCard({ cardData, isSingleCanvas }) {
  const formatText = cardData?.Format_text || "";
  const formatTextColor = cardData?.Format_text_color || "#000000";
  const titleText = cardData?.Title_text;
  const titleTextColor = cardData?.Title_text_color;
  const dateTimeText = cardData?.Date_Time_text;
  const dateTimeTextColor = cardData?.Date_Time_text_color;
  const subtitleText = cardData?.Subtitle_text;
  const subtitleTextColor = cardData?.Subtitle_text_color;
  const foregroundImage = cardData?.Foreground_image;
  const bgColorStart =
    cardData?.Background_color_start === "Background_color_start example"
      ? "#fff"
      : cardData?.Background_color_start;
  const bgColorEnd =
    cardData?.Background_color_end === "Background_color_end example"
      ? "#fff"
      : cardData?.Background_color_end;
  const bgFullWidthVideo = cardData?.Background_video;
  const bgFullWidthImage = cardData?.Background_full_width_image;
  const bgFullWidthImageMobile = cardData?.Background_full_width_image_lower_resolutions;
  const buttons = cardData?.Buttons;
  const transLayerColor = cardData?.Transparent_layer_color;
  const transLayerPercentage = cardData?.Transparent_layer_percentage;

  return (
    <BackgroundConstructor
      bgColorStart={bgColorStart}
      bgColorEnd={bgColorEnd}
      bgFullWidthVideo={bgFullWidthVideo}
      bgFullWidthImage={bgFullWidthImage}
      bgFullWidthImageMobile={bgFullWidthImageMobile}
      transLayerColor={transLayerColor}
      transLayerPercentage={transLayerPercentage}
      isSingleCanvas={isSingleCanvas}
    >
      <div
        className={`px-lg-0 ${isSingleCanvas ? "single-trend-wrapper" : "container trend-wrapper"}`}
      >
        <div className={`${!isSingleCanvas ? "trend-card" : "single-trend-card"}`}>
          <div className="left-wrap">
            {formatText && (
              <p
                className="label medium mb-0"
                style={{ color: formatTextColor }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: formatText }}
              />
            )}
            {titleText && (
              <h1
                className="medium-contrast"
                style={{ color: titleTextColor }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: titleText }}
              />
            )}
            {dateTimeText && (
              <p
                className={classnames("label medium dt-spacer mb-0", {
                  "st-spacer": !subtitleText,
                })}
                style={{ color: dateTimeTextColor }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: dateTimeText }}
              />
            )}
            {subtitleText && (
              <h6
                className={classnames("light-contrast", {
                  "title-spacer": !dateTimeText,
                })}
                style={{ color: subtitleTextColor }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: subtitleText }}
              />
            )}
            {buttons.length > 0 && (
              <div className="button-wrap">
                {buttons?.map((button) => (
                  // eslint-disable-next-line no-underscore-dangle
                  <DiscoveryButton id={button._id} key={button._id} />
                ))}
              </div>
            )}
          </div>
          <div className="right-wrap my-auto">
            {foregroundImage?.url && <img src={foregroundImage.url} alt="" />}
          </div>
        </div>
      </div>
    </BackgroundConstructor>
  );
}

export default DiscoveryTrendCard;
