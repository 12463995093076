export const initState = {
  isLoading: false,
  subject: "",
  description: "",
  attachment: "",
  errorMessage: "",
};

// eslint-disable-next-line default-param-last
export function reducer(state = this.initState, action) {
  switch (action.type) {
    case "input-change":
      return {
        ...state,
        [action.inputName]: action.inputValue,
      };
    case "start-loading":
      return {
        ...state,
        errorMessage: "",
        isLoading: true,
      };
    case "failed-required-fields":
      return {
        ...state,
        isLoading: false,
        errorMessage: action.value
          ? "* Category, subject and description are required."
          : "* Subject and description are both required.",
      };
    case "failed":
      return {
        ...state,
        isLoading: false,
        errorMessage: "We failed to send your message. Please try again.",
      };
    default:
      return state;
  }
}
