import { extractProductNumberFromSlug } from "../slug/session-detail";

export const SEARCH_RESULT_PAGE_URL = "/search-results";
export const MIN_SEARCH_LENGTH = 1;

const boldMatchingSubstringsInResultTitle = (title, searchTerm) => {
  const wordsInSearchTerm = searchTerm.split(" ");

  return title
    .split(" ")
    .map((word) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const ws of wordsInSearchTerm) {
        const startingIndexOfTerm = word.toLowerCase().indexOf(ws.toLowerCase());
        if (startingIndexOfTerm > -1) {
          const substringToBold = word.slice(startingIndexOfTerm, startingIndexOfTerm + ws.length);
          return word.replace(substringToBold, `<b>${substringToBold}</b>`);
        }
      }
      return word;
    })
    .join(" ");
};

const parsePrice = (price) => {
  const suffix = price === 1 ? " credit" : " credits";
  return price + suffix;
};

export const transformSearchResultsToPopupData = (data, searchTerm) => {
  if (!data || typeof searchTerm !== "string") return {};
  const transformResult = (result, additionalProps = {}) => ({
    ...result,
    titleBold: boldMatchingSubstringsInResultTitle?.(result.title, searchTerm),
    productNumber: extractProductNumberFromSlug?.(result.slug),
    price: parsePrice?.(result.price),
    ...additionalProps,
  });

  return {
    products: data.products?.map((p) => transformResult(p)),
    webinars: data.webinars?.map((p) => transformResult(p, { isSoldOut: false, isWebinars: true })),
    hireATeam: data.hireATeam?.map((p) => transformResult(p, { isHireATeam: true })),
    insights: data.insight?.map((p) => transformResult(p, { isSoldOut: false, isInsight: true })),
    events: data.events?.map((p) => transformResult(p, { isSoldOut: false, isInsight: true })),
    labs: data.labs?.map((p) => transformResult(p, { isLabs: true })),
  };
};

export const getSearchQueryFromURLParameters = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("search");
};

export const createSearchResultsPageURL = (query) => {
  return `${SEARCH_RESULT_PAGE_URL}?search=${encodeURI(query)}`;
};
