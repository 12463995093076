import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ConciergeChatContent from "./ConciergeChatContent";
import ChatForm from "./ChatForm";
import ChatSuccess from "./ChatSuccess";
import ChatError from "./ChatError";

function RenderChatContent({ pageString, setPageContent, setShowPopup, isContactSupport }) {
  switch (pageString) {
    case "landing":
      return (
        <ConciergeChatContent
          setPageContent={setPageContent}
          closeChat={() => setShowPopup(true)}
        />
      );
    case "form":
      return <ChatForm setPageContent={setPageContent} isContactSupport={isContactSupport} />;
    case "success":
      return <ChatSuccess isContactSupport={isContactSupport} />;
    case "error":
      return <ChatError setShowPopup={setShowPopup} />;
    default:
      return null;
  }
}

RenderChatContent.propTypes = {
  pageString: PropTypes.string.isRequired,
  setPageContent: PropTypes.func.isRequired,
  setShowPopup: PropTypes.func.isRequired,
  isContactSupport: PropTypes.bool.isRequired,
};

function LandingChat({ setShowPopup, isContactSupport }) {
  const defaultPage = isContactSupport ? "form" : "landing";
  const [page, setPageContent] = useState(defaultPage);

  const setPageHandler = (newValue) => {
    setTimeout(() => {
      setPageContent(newValue);
    }, 10);
  };

  return (
    <div className="conciergeChatWrapper">
      <RenderChatContent
        pageString={page}
        setPageContent={setPageHandler}
        setShowPopup={setShowPopup}
        isContactSupport={isContactSupport}
      />
    </div>
  );
}

LandingChat.propTypes = {
  setShowPopup: PropTypes.func.isRequired,
  isContactSupport: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isContactSupport: state.global.isSupportChat,
});

export default connect(mapStateToProps)(LandingChat);
