import TagManager from "react-gtm-module";
import moment from "moment";
import AuthHelper from "./AuthHelper";
import { getDisplayDuration } from "./Helper";

export const DSB_STATUSES = {
  0: "Critical",
  1: "Warning",
  2: "On track",
};

const sendEvent = (config) => {
  const configWithUserToken = {
    dataLayer: {
      userToken: AuthHelper.getUserOid() || "not_present",
      isReplyer: AuthHelper.isReplyUser() || "not_present",
      userRegisteredFrom: AuthHelper.getUserRegisteredFrom() || "not_present",
      userType: AuthHelper.getUserType() || "not_present",
      ...config.dataLayer,
    },
  };
  TagManager.dataLayer(configWithUserToken);
};

function getPage() {
  const path = window.location.pathname;
  if (path === "/") {
    return "homepage";
  }

  return path.slice(1, path.length);
}

export const sendDataLayer = (event, productId, productName, productPrice, language, extras) => {
  const config = {
    dataLayer: {
      event,
      sku: productId,
      name: productName,
      price: productPrice,
      language,
      ...extras,
    },
  };
  sendEvent(config);
};

export const sendInsightsData = (title, language, tag, location) => {
  const config = {
    dataLayer: {
      event: "insights_click",
      name: title,
      doc_language: language,
      doc_type: tag,
      page: location,
    },
  };
  sendEvent(config);
};
export const sendInsightsDownload = (docType, language, eventName, productName, page) => {
  const config = {
    dataLayer: {
      event: eventName,
      name: productName,
      doc_language: language,
      doc_type: docType,
      page,
    },
  };
  sendEvent(config);
};

export function sendEventAnalytics(obj) {
  const config = {
    dataLayer: {
      event: obj?.event,
      page: obj?.page,
      buttonName: obj?.buttonName,
      name: obj?.name,
      doc_language: obj?.doc_language,
      doc_type: obj?.doc_type,
    },
  };
  sendEvent(config);
}

export const sendTellUsMore = (product, isHireATeamSession) => {
  let productType = "Consulting";
  if (isHireATeamSession) productType = "Hire a team";
  if (product.isAskRose) productType = "Ask Rose";

  const config = {
    dataLayer: {
      event: "tellUsMore",
      name: product.title,
      sku: product.id,
      price: product.price,
      language: product.dataLayerLanguage,
      format: product.tag || "",
      productType,
    },
  };
  sendEvent(config);
};

export const sendLeaveBookingPage = (product, step, isHireATeamSession) => {
  let stepText = "";
  if (step === 1) stepText = "Calendar";
  else if (step === 2) stepText = "Tell Us More";

  let productType = "Consulting";
  if (isHireATeamSession) productType = "Hire a team";
  if (product?.isAskRose) productType = "Ask Rose";

  const config = {
    dataLayer: {
      event: "leaveThePage",
      name: product.title,
      sku: product.id,
      price: product.price,
      language: product.dataLayerLanguage,
      format: product.tag || "",
      step: stepText,
      productType,
    },
  };
  sendEvent(config);
};

export const sendPurchasedProductEvent = (
  product,
  orderNumber,
  freeSessionsLeft,
  tellUsMore,
  isHireATeamSession,
) => {
  let productType = "Consulting";
  if (isHireATeamSession) productType = "Hire a team";
  if (product?.isAskRose) productType = "Ask Rose";

  const config = {
    dataLayer: {
      event: "item_purchase",
      orderId: orderNumber,
      name: product.title,
      sku: product.id,
      price: product.price,
      language: product.dataLayerLanguage,
      category: "Course",
      format: product.tag || "",
      sessionType: `${product.numberOfSessions} ${
        product.numberOfSessions === 1 ? "meeting" : "meetings"
      } x ${getDisplayDuration(product.timeOfSession)}`,
      productType,
      hour: product.time,
      freeConsultingServicesStatus: `You have ${freeSessionsLeft} free meetings left`,
      day: moment(product.meetingTime).format("DD/MM/YYYY"),
      tellUsMore,
    },
  };
  sendEvent(config);
};

// can possibly be removed in the future
export const sendPurchasedEvent = (orderNumber, revenue, promoCode, products, freeSessionsLeft) => {
  const productsForGA = products.map((p) => {
    return {
      sku: p.id,
      name: p.title,
      price: p.price / p.quantity,
      category: "Course",
      language: p.language,
      quantity: p.quantity,
      format: p.tag || "",
      sessionType: `${p.numberOfSessions} ${
        p.numberOfSessions === 1 ? "meeting" : "meetings"
      } x ${getDisplayDuration(p.timeOfSession)}`,
      hour: p.time,
      freeConsultingServicesStatus: `You have ${freeSessionsLeft} free meetings left`,
      day: moment(p.meetingTime).format("DD/MM/YYYY"),
    };
  });

  const config = {
    dataLayer: {
      event: "purchase",
      transactionId: orderNumber,
      transactionAffiliation: "Reply Online Services",
      transactionTotal: revenue,
      transactionTax: 0,
      transactionShipping: 0,
      promoCode,
      transactionProducts: productsForGA,
    },
  };
  sendEvent(config);
};

// search analytics
export const internalSearchEvent = (eventAction, eventLabel, eventValue) => {
  const config = {
    dataLayer: {
      event: "internal_search",
      eventCategory: "click_suggested_keyword",
      eventAction,
      eventLabel,
      eventValue,
    },
  };
  sendEvent(config);
};

export const internalSearchSubmission = (eventAction, eventLabel) => {
  const config = {
    dataLayer: {
      event: "internal_search",
      eventCategory: "click_submit_search",
      eventAction,
      eventLabel,
      eventValue: "not_present",
    },
  };
  sendEvent(config);
};

export const internalSearchClick = (eventAction, eventLabel, eventValue) => {
  const config = {
    dataLayer: {
      event: "internal_search",
      eventCategory: "click_search_result",
      eventAction,
      eventLabel,
      eventValue,
    },
  };
  sendEvent(config);
};

export const sendProductPage = (product) => {
  let productType = "Consulting";
  if (product?.isResearch) productType = "Insights";
  if (product?.isVideo) productType = "Video Library";
  if (product?.isHireATeam) productType = "Hire a team";
  if (product?.isAskRose) productType = "Ask Rose";
  const config = {
    dataLayer: {
      event: "productPage",
      name: product?.title,
      sku: product?.id,
      price: product?.price,
      productType,
      language: product?.dataLayerLanguage,
      format: product?.tag,
    },
  };
  sendEvent(config);
};

export const sendCalendarPage = (product, isHireATeamSession) => {
  let productType = "Consulting";
  if (isHireATeamSession) productType = "Hire a team";
  if (product?.isAskRose) productType = "Ask Rose";
  const config = {
    dataLayer: {
      event: "calendar",
      name: product.title,
      sku: product.id,
      price: product.price,
      language: product.dataLayerLanguage,
      productType,
      format: product.tag,
    },
  };
  sendEvent(config);
};

export const sendAddToCartEvent = (product, isHireATeamSession) => {
  let productType = "Consulting";
  if (isHireATeamSession) productType = "Hire a team";
  if (product?.isAskRose) productType = "Ask Rose";

  const config = {
    dataLayer: {
      event: "addToCart",
      name: product.title,
      sku: product.id,
      price: product.price,
      language: product.dataLayerLanguage,
      format: product.tag,
      productType,
    },
  };
  sendEvent(config);
};

export const sendAccountRegistration = () => {
  const config = {
    dataLayer: {
      event: "UserAccountRegistration",
    },
  };

  if (!localStorage.getItem("signupconfirmation")) {
    localStorage.setItem("signupconfirmation", true);
    sendEvent(config);
  }
};

export const sendLogin = () => {
  const config = {
    dataLayer: {
      event: "logIn",
    },
  };

  if (!sessionStorage.getItem("loggedin")) {
    sessionStorage.setItem("loggedin", true);
    sendEvent(config);
  }
};

export const sendContactConcierge = () => {
  sendEvent({
    dataLayer: {
      event: "clickConcierge",
      page: getPage(),
    },
  });
};

export const sendOnLeaveMessage = () => {
  sendEvent({
    dataLayer: {
      event: "startFormConcierge",
      page: getPage(),
    },
  });
};

/**
 * @deprecated - this method has to be removed
 * @param page
 */
export const sendChatLanding = (page = "homepage") => {
  sendEvent({
    dataLayer: {
      page,
      event: "chatWithUs",
    },
  });
};

export const sendStartMeeting = (page = "homepage") => {
  sendEvent({
    dataLayer: {
      page,
      event: "startMeeting",
    },
  });
};

export const sendChatForm = (page = "homepage") => {
  sendEvent({
    dataLayer: {
      page,
      event: "formLeadConcierge",
    },
  });
};

export const sendVideoLibraryDetailPage = (videoName, videoCategory, language) => {
  sendEvent({
    dataLayer: {
      event: "videoOnDemandDetail",
      page: "VideoLibrary",
      videoName,
      videoCategory,
      videoLanguage: language,
    },
  });
};

export const sendErrorEvent = (message, stackTrace) => {
  sendEvent({
    dataLayer: {
      event: "error_tracking",
      errorMessage: message,
      errorStackTrace: stackTrace,
      page: getPage(),
    },
  });
};

export const tagManagerConfig = {
  gtmId: process.env.REACT_APP_GA_GTM_ID,
};

export const sendCtaClick = (buttonName) => {
  sendEvent({
    dataLayer: {
      event: "ctaClick",
      page: getPage(),
      buttonName,
    },
  });
};

export const sendResearchEvent = (buttonName, productName) => {
  sendEvent({
    dataLayer: {
      event: "research_interaction",
      page: getPage(),
      buttonName,
      name: productName,
    },
  });
};

export const sendPageViewEvent = (isPublic = false) => {
  const token = isPublic ? "not_present" : AuthHelper.getUserOid();
  const userRegFrom = isPublic ? "not_present" : AuthHelper.getUserRegisteredFrom();
  const replyer = isPublic ? "not_present" : AuthHelper.isReplyUser();
  const type = isPublic ? "not_present" : AuthHelper.getUserType();
  sendEvent({
    dataLayer: {
      event: "page_view",
      page: getPage(),
      userToken: token,
      userRegisteredFrom: userRegFrom,
      isReplyer: replyer,
      userType: type,
    },
  });
};

export const sendPublicPageEvent = () => {
  sendEvent({
    dataLayer: {
      event: "public_page_landing",
      page: getPage(),
    },
  });
};

// MENU ITEM CLICK
// when the user selects a menu item in the page header
// the following dataLayer has to be pushed
export const menuItemClickGTM = (ctaName) => {
  sendEvent({
    dataLayer: {
      event: "click_menu_header",
      ctaName,
      userToken: AuthHelper.getUserOid(),
      isReplyer: AuthHelper.isReplyUser(),
      userRegisteredFrom: AuthHelper.getUserRegisteredFrom(),
      userType: AuthHelper.getUserType(),
    },
  });
};

// TOPIC OVERLAY MOUSEOVER
// when the user selects a topic from the overlay mouseover
// in the header the foolowing dataLayer has to be pushed
export const topicOverlayMouseOverGTM = (filterName) => {
  sendEvent({
    dataLayer: {
      event: "click_topic",
      entrypoint: "Overlay",
      page: getPage(),
      filterName,
      filterType: "topic",
      userToken: AuthHelper.getUserOid(),
      isReplyer: AuthHelper.isReplyUser(),
      userRegisteredFrom: AuthHelper.getUserRegisteredFrom(),
      userType: AuthHelper.getUserType(),
    },
  });
};

// FILTERS - TOPIC DROPDOWN
// when the user clicks apply button after selecting or more topics
// from the dropdown the following dataLayer has to be pushed
// !IMPORTANT
// if a user makes a multiple selections, and clicks the Apply button
// all dataLayers selections must be pushed
export const filterSelectedGTM = (label, filterType, extras) => {
  const config = {
    dataLayer: {
      event: "click_topic",
      entrypoint: "Dropdown",
      page: getPage(),
      filterName: label,
      filterType,
      userToken: AuthHelper.getUserOid(),
      isReplyer: AuthHelper.isReplyUser(),
      userRegisteredFrom: AuthHelper.getUserRegisteredFrom(),
      userType: AuthHelper.getUserType(),
      ...extras,
    },
  };
  sendEvent(config);
};

/**
 * Public Landing Page
 */
export const menuItemClickLandingPageGTM = (ctaName) => {
  const config = {
    dataLayer: {
      event: "click_menu_header",
      ctaName,
      userToken: "not_present",
      isReplyer: "not_present",
      userRegisteredFrom: "not_present",
      userType: "not_present",
    },
  };
  sendEvent(config);
};

export const sendCtaClickLandingPageGTM = (buttonName, page) => {
  const config = {
    dataLayer: {
      event: "ctaClick",
      page,
      buttonName,
      userToken: "not_present",
      isReplyer: "not_present",
      userRegisteredFrom: "not_present",
      userType: "not_present",
    },
  };
  sendEvent(config);
};

export const sendLeadGenerationEventGTM = (leadType) => {
  const config = {
    dataLayer: {
      event: "lead_generation",
      lead_type: leadType,
      userToken: "not_present",
      isReplyer: "not_present",
      userRegistedFrom: "not_present",
      userType: "not_present",
    },
  };
  sendEvent(config);
};

export const sendAccordionClickLandingPageGTM = (accordionName) => {
  const config = {
    dataLayer: {
      event: "accordion_click",
      accordion_name: accordionName,
      userToken: "not_present",
      isReplyer: "not_present",
      userRegistedFrom: "not_present",
      userType: "not_present",
    },
  };
  sendEvent(config);
};

export const sendProductEventClickGTM = (
  product,
  isProductPage = false,
  isPublic = true,
  from = "consulting",
) => {
  const token = isPublic ? "not_present" : AuthHelper.getUserOid();
  const userRegFrom = isPublic ? "not_present" : AuthHelper.getUserRegisteredFrom();
  const replyer = isPublic ? "not_present" : AuthHelper.isReplyUser();
  const type = isPublic ? "not_present" : AuthHelper.getUserType();

  const paths = {
    "video-library": "Video Library",
    insights: "Insights",
    consulting: "Consulting",
    "hire-a-team": "Hire a Team",
    "area-42-labs": "Area42 Labs",
    events: "Events",
    topic: "Consulting",
  };

  const productType = paths[from];

  sendEvent({
    dataLayer: {
      event: isProductPage ? "productPage" : "productClick",
      name: product.title,
      sku: product.id,
      price: product.price,
      language: product.dataLayerLanguage,
      format: product.tag || "",
      userToken: token,
      userRegisteredFrom: userRegFrom,
      isReplyer: replyer,
      userType: type,
      productType,
    },
  });
};

export const sortingJobTablePageGTM = (ctaName) => {
  const config = {
    dataLayer: {
      event: "sorting",
      ctaName,
      page: getPage(),
    },
  };
  sendEvent(config);
};

export const popUpCommentJobTablePageGTM = ({
  jobTitle,
  companyName,
  userCurrentRating,
  replyCurrentStatus,
  lastUpdate,
}) => {
  const config = {
    dataLayer: {
      event: "view_comment",
      jobTitle,
      companyName,
      userCurrentRating,
      replyCurrentStatus,
      lastUpdate,
      page: getPage(),
    },
  };
  sendEvent(config);
};
export const ratingChangeJobTablePageGTM = ({
  step,
  jobTitle,
  companyName,
  userCurrentRating,
  replyCurrentStatus,
  lastUpdate,
  userPrevRating = "not_present",
}) => {
  const config = {
    dataLayer: {
      event: "rating_change",
      step,
      jobTitle,
      companyName,
      userCurrentRating,
      replyCurrentStatus,
      userPrevRating,
      lastUpdate,
      page: getPage(),
    },
  };
  sendEvent(config);
};

export const jobDetailPageCtaPageGTM = ({
  buttonName = "View_detail",
  jobTitle,
  companyName,
  replyCurrentStatus,
  userCurrentRating,
  lastUpdate,
}) => {
  const config = {
    dataLayer: {
      event: "ctaClick",
      buttonName,
      jobTitle,
      companyName,
      userCurrentRating,
      replyCurrentStatus,
      lastUpdate,
      page: getPage(),
    },
  };
  sendEvent(config);
};

export const shareCtaJobDetailPageGTM = ({
  event = "click_share_button",
  jobTitle,
  companyName,
  replyCurrentStatus,
  userCurrentRating,
  lastUpdate,
}) => {
  const config = {
    dataLayer: {
      event,
      jobTitle,
      companyName,
      userCurrentRating,
      replyCurrentStatus,
      lastUpdate,
      page: getPage(),
    },
  };
  sendEvent(config);
};

export const jobDetailCtaGTM = ({ event = "ctaClick", buttonName, jobTitle, companyName }) => {
  const config = {
    dataLayer: {
      event,
      jobTitle,
      companyName,
      buttonName,
      page: getPage(),
    },
  };
  sendEvent(config);
};

export const dashboardKpiCtaGTM = ({
  event = "ctaClick",
  buttonName,
  jobTitle,
  companyName,
  typology,
}) => {
  const config = {
    dataLayer: {
      event,
      jobTitle,
      companyName,
      buttonName,
      typology,
      page: getPage(),
    },
  };
  sendEvent(config);
};

export const sendAccordionDashboardHistoryGTM = ({
  event = "accordion_click",
  jobTitle,
  companyName,
  accordionName,
}) => {
  const config = {
    dataLayer: {
      event,
      jobTitle,
      companyName,
      accordion_name: accordionName,
      page: getPage(),
    },
  };
  sendEvent(config);
};

export const sendCarouselClickGTM = ({ page, buttonName, destination }) => {
  const token = AuthHelper.getUserOid() || "not_present";
  const replyer = AuthHelper.isReplyUser() || "not_present";
  const userRegFrom = AuthHelper.getUserRegisteredFrom() || "not_present";
  const type = AuthHelper.getUserType() || "not_present";

  const config = {
    dataLayer: {
      event: "click_carousel",
      page,
      buttonName,
      destinationPage: destination,
      userToken: token,
      isReplyer: replyer,
      userRegisteredFrom: userRegFrom,
      userType: type,
    },
  };
  sendEvent(config);
};
