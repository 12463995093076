import { UserAgentApplication } from "msal";
import AuthHelper from "../utils/AuthHelper";
import { menuItemClickGTM } from "../utils/TagManagerHelper";
import { b2cPolicies } from "./AuthProvider";

class CustomAgentApplication extends UserAgentApplication {
  constructor(config, refreshTokenParams, loginParams) {
    super(config);
    this.refreshTokenParams = refreshTokenParams;
    this.loginParams = loginParams;
    this.handleRedirectCallback(this.redirectCallBack);
  }

  loginRedirect(userRequest) {
    AuthHelper.saveCurrentLocationBeforeLogin();
    super.loginRedirect(userRequest);
  }

  redirectCallBack = (error) => {
    if (error) {
      this.setError(error);

      // forgot password error, redirect to forgot password
      if (error.errorMessage.indexOf("AADB2C90118") > -1) {
        localStorage.clear();
        window.history.pushState({}, document.title, "/forgot-password");
        this.loginRedirect(b2cPolicies.authorities.forgotPassword);
      }

      // user cancellation error, triggered when TnC page closed
      // do not redirect anywhere
      else if (error.errorMessage.indexOf("AADB2C90091") > -1 && !this.getAccount()) {
        // TODO: Determine if we should remove this block
      }
    }
  };

  getError = () => {
    // eslint-disable-next-line no-underscore-dangle
    return this._error ? { ...this._error } : null;
  };

  setError = (error) => {
    // eslint-disable-next-line no-underscore-dangle
    this._error = error ? { ...error } : null;
  };

  getAccessToken = async (blockSaveOfLocation) => {
    return new Promise((resolve) => {
      this.acquireTokenSilent(this.refreshTokenParams)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          if (!blockSaveOfLocation) {
            AuthHelper.saveCurrentLocationBeforeLogin();
          }
          this.acquireTokenRedirect(this.refreshTokenParams);
        });
    });
  };

  refreshIdToken = async () => {
    return new Promise((resolve) => {
      this.acquireTokenSilent(this.loginParams)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          // if the refresh was unsuccessful just sign out
          // to ensure public page is shown when user lands to it
          this.signOut();
        });
    });
  };

  signOut = () => {
    menuItemClickGTM("Logout");
    sessionStorage.clear();
    this.logout();
  };
}

export default CustomAgentApplication;
