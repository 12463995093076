import { SUPPORT_INIT_CATEGORIES, SUPPORT_INIT_LANDING_FAQS } from "./SupportTypes";

const initialState = {
  isCategoriesLoading: true,
  isFaqsLoading: true,

  categories: [],
  faqs: [],
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPPORT_INIT_CATEGORIES: {
      return {
        ...state,
        isCategoriesLoading: false,
        categories: action.value,
      };
    }
    case SUPPORT_INIT_LANDING_FAQS: {
      return {
        ...state,
        isFaqsLoading: false,
        faqs: action.value,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
