import {
  ACTIVATE_ALL_PRESELECTED_FILTERS,
  ACTIVATE_PRESELECTED_FILTER,
  ACTIVE_FILTER_NUM,
  CLEAR_ALL_FILTERS,
  CLEAR_ALL_PRESELECTED_FILTERS,
  DESELECT_FILTERS,
  POPULATE_FILTERS,
  POPULATE_WEBINARS,
  POPULATE_NEXT_PRODUCT_PAGE,
  PRESELECT_FILTER,
  RESET,
  SET_ACTIVE_FILTERS,
  TOGGLE_FILTER,
  HAS_RECOMMENDED,
} from "./VideoLibraryTypes";
import VideoLibraryHelper from "../../utils/helpers/VideoLibraryHelper";
import { filterSelectedGTM } from "../../utils/TagManagerHelper";
import { shouldResetState } from "../../utils/Helper";

const initialState = {
  previousPathname: "",
  isWebinarLoading: true,
  isFiltersLoading: true,
  isAdditionalWebinarsLoading: false,
  webinars: [],
  totalWebinarsCount: 0,
  languageFilters: [],
  countryFilters: [],
  topicFilters: [],
  typologyFilters: [],
  hasRecommended: false,
  page: 1,
  hasNextPage: true,

  activeFilters: {
    languages: "",
    topics: "",
    countries: "",
    typology: "",
  },

  activeFilterNum: {
    languages: "",
    topics: "",
    total: "",
    countries: "",
    typology: "",
  },
};

function removePreselectedFilters(filters) {
  return filters.map((filter) => {
    if (!filter.active && filter.preselected) {
      return {
        ...filter,
        preselected: false,
      };
    }
    if (filter.active && !filter.preselected) {
      return {
        ...filter,
        preselected: true,
      };
    }

    return filter;
  });
}

function deselectFilters(filters) {
  return filters.map((filter) => {
    if (filter.preselected) {
      return {
        ...filter,
        preselected: false,
      };
    }
    return filter;
  });
}

function activatePreselected(filters) {
  return filters.map((filter) => {
    return {
      ...filter,
      active: filter.preselected,
    };
  });
}

function preselectFilter(filters, id) {
  return filters.map((filter) => {
    if (filter.value === id) {
      return {
        ...filter,
        preselected: !filter.preselected,
      };
    }
    return filter;
  });
}

function toggleFilter(filters, id, force = false, checkLabel = false) {
  return filters.map((filter) => {
    if (filter.label === id || filter.value === id || (checkLabel && filter.label === id)) {
      return {
        ...filter,
        active: !filter.active,
        preselected: !filter.active,
      };
    }
    if (force) {
      return {
        ...filter,
        active: false,
        preselected: false,
      };
    }
    return filter;
  });
}

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POPULATE_WEBINARS: {
      if (!Array.isArray(action.webinars) || typeof action.totalCount !== "number") {
        return state;
      }

      const webinars = state.page > 1 ? state.webinars.concat(action.webinars) : action.webinars;

      return {
        ...state,
        webinars,
        isWebinarLoading: false,
        isAdditionalWebinarsLoading: false,
        totalWebinarsCount: action.totalCount,
        hasNextPage: webinars.length < action.totalCount,
      };
    }

    case POPULATE_NEXT_PRODUCT_PAGE: {
      return {
        ...state,
        isAdditionalWebinarsLoading: true,
        page: state.page + 1,
      };
    }

    case HAS_RECOMMENDED: {
      return {
        ...state,
        hasRecommended: action.value,
      };
    }

    case POPULATE_FILTERS: {
      const languageList = action.value.languageFilters;
      const { typologyFilters } = action.value;
      const filteredLanguageList = [];
      languageList.forEach((tag) => {
        if (tag.label === "EN" || tag.label === "DE" || tag.label === "IT")
          filteredLanguageList.push(tag);
      });
      return {
        ...state,
        isFiltersLoading: false,
        languageFilters: filteredLanguageList,
        typologyFilters,
        topicFilters: action.value.topicFilters,
        countryFilters: action.value.countryFilters || [],
      };
    }

    case TOGGLE_FILTER: {
      return {
        ...state,
        isWebinarLoading: true,
        page: 1,
        [action.value.selector]: toggleFilter(
          state[action.value.selector],
          action.value.id,
          action.value.force,
          action.value.checkLabel,
        ),
      };
    }

    case PRESELECT_FILTER: {
      return {
        ...state,
        [action.value.selector]: preselectFilter(state[action.value.selector], action.value.id),
      };
    }

    case ACTIVATE_PRESELECTED_FILTER: {
      const { selector } = action.value;
      const activeFilters = state[selector].filter((item) => item.preselected);

      activeFilters.forEach((item) => {
        filterSelectedGTM(item.label, selector === "topicFilters" ? "topic" : "language");
      });
      return {
        ...state,
        page: 1,
        isWebinarLoading: true,
        [action.value.selector]: activatePreselected(state[action.value.selector]),
      };
    }

    case ACTIVATE_ALL_PRESELECTED_FILTERS: {
      const filters = ["languageFilters", "topicFilters", "typologyFilters", "countryFilters"];
      filters.forEach((filterItem) => {
        const activeFilters = state[filterItem].filter((item) => item.preselected);
        activeFilters.forEach((item) => {
          filterSelectedGTM(item.label, filterItem === "topicFilters" ? "topic" : "language");
        });
      });
      return {
        ...state,
        isWebinarLoading: true,
        page: 1,
        topicFilters: activatePreselected(state.topicFilters),
        languageFilters: activatePreselected(state.languageFilters),
        typologyFilters: activatePreselected(state.typologyFilters),
        countryFilters: activatePreselected(state.countryFilters),
      };
    }

    case CLEAR_ALL_FILTERS: {
      return {
        ...state,
        isWebinarLoading: true,
        activeFilters: initialState.activeFilters,
        activeFilterNum: initialState.activeFilterNum,
        topicFilters: VideoLibraryHelper.addActivePropToFilter(state.topicFilters),
        languageFilters: VideoLibraryHelper.addActivePropToFilter(state.languageFilters),
        typologyFilters: VideoLibraryHelper.addActivePropToFilter(state.typologyFilters),
        countryFilters: VideoLibraryHelper.addActivePropToFilter(state.countryFilters),
        page: 1,
      };
    }

    case CLEAR_ALL_PRESELECTED_FILTERS: {
      return {
        ...state,
        topicFilters: removePreselectedFilters(state.topicFilters),
        languageFilters: removePreselectedFilters(state.languageFilters),
        typologyFilters: removePreselectedFilters(state.typologyFilters),
        countryFilters: removePreselectedFilters(state.countryFilters),
      };
    }

    case DESELECT_FILTERS: {
      return {
        ...state,
        topicFilters: deselectFilters(state.topicFilters),
        languageFilters: deselectFilters(state.languageFilters),
        typologyFilters: deselectFilters(state.typologyFilters),
        countryFilters: deselectFilters(state.countryFilters),
      };
    }

    case SET_ACTIVE_FILTERS: {
      return {
        ...state,
        activeFilters: action.value,
      };
    }
    case ACTIVE_FILTER_NUM: {
      return {
        ...state,
        activeFilterNum: action.value,
      };
    }

    case RESET: {
      const { resetStateFlag, newPreviousPathname } = shouldResetState(
        state,
        "/video-library",
        "/search-results",
        "/video-library/",
      );

      const updatedState = {};

      Object.keys(initialState).forEach((key) => {
        if (key !== "previousPathname") {
          updatedState[key] = resetStateFlag ? initialState[key] : state[key];
        }
      });

      return {
        ...state,
        ...updatedState,
        // Update the previousPathname
        previousPathname: newPreviousPathname,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default reducer;
