/* eslint-disable no-console */
import { authProvider, b2cPolicies } from "../config/AuthProvider";
import Constants from "./Constants";
import AWSAPI from "./AWSAPI";

const CALLBACK_REDIRECT_SESSION_KEY = "ONSUCCESS_REDIRECT_URL";
const CALLBACK_REDIRECT_PASSWORD_URL = "REDIRECT_PASSWORD_URL";
class AuthHelper {
  static login() {
    try {
      authProvider.loginRedirect(b2cPolicies.authorities.signIn);
    } catch (e) {
      console.error(e.getMessage());
    }
  }

  static register() {
    try {
      authProvider.loginRedirect(b2cPolicies.authorities.signUp);
    } catch (e) {
      console.error(e.getMessage());
    }
  }

  static isCountryAllowed(countryCode) {
    AWSAPI.getAllowedCountries().then((res) => {
      const allowedCountries = res.data;
      if (!allowedCountries.includes(countryCode)) {
        window.location.replace("/country-not-available");
      }
    });
  }

  static isRegisteredFromReply(email) {
    const replayDomains = [
      "reply.it",
      "reply.eu",
      "ringmaster.it",
      "bitmama.it",
      "xister.com",
      "reply.com",
      "reply.de",
      "valorem.com",
    ];
    return replayDomains.includes(email.split("@")[1]);
  }

  static getFirstName() {
    return authProvider.getAccount() && authProvider.getAccount().idToken.given_name;
  }

  static getLastName() {
    return authProvider.getAccount() && authProvider.getAccount().idToken.family_name;
  }

  static getFullName() {
    return `${this.getFirstName()} ${this.getLastName()}`;
  }

  static getEmail() {
    return (
      authProvider.getAccount() &&
      ((authProvider.getAccount().idToken.emails && authProvider.getAccount().idToken.emails[0]) ||
        authProvider.getAccount().idToken["signInNames.emailAddress"] ||
        authProvider.getAccount().idToken.email)
    );
  }

  static getInitials() {
    return this.getInitial(this.getFirstName()) + this.getInitial(this.getLastName());
  }

  static getRole() {
    return authProvider.getAccount() && authProvider.getAccount().idToken.jobTitle;
  }

  static getCompany() {
    return authProvider.getAccount() && authProvider.getAccount().idToken.extension_Company;
  }

  static getInitial(s) {
    return s && s[0].toUpperCase();
  }

  static isReplyUser() {
    return sessionStorage.getItem(Constants.IS_LOGGED_IN_USER_REPLYER);
  }

  static isQualifiedUser() {
    return sessionStorage.getItem(Constants.IS_LOGGED_IN_USER_QUALIFIED);
  }

  static saveCurrentLocationBeforeLogin() {
    if (window.location.pathname !== "/") {
      sessionStorage.setItem(
        CALLBACK_REDIRECT_SESSION_KEY,
        window.location.pathname + window.location.search,
      );
    }
  }

  static redirectToSavedLocation(setIsLoading) {
    if (sessionStorage.getItem(CALLBACK_REDIRECT_SESSION_KEY)) {
      if (
        sessionStorage.getItem(CALLBACK_REDIRECT_SESSION_KEY) !==
        window.location.pathname + window.location.search
      ) {
        window.location.href = sessionStorage.getItem(CALLBACK_REDIRECT_SESSION_KEY);
      }
      this.clearSavedRedirectLocation();
    } else {
      setIsLoading(false);
    }
  }

  static clearSavedRedirectLocation() {
    sessionStorage.removeItem(CALLBACK_REDIRECT_SESSION_KEY);
  }

  static savePasswordLocation() {
    sessionStorage.setItem(CALLBACK_REDIRECT_PASSWORD_URL, "/my-profile");
  }

  static clearSavedRedirectPasswordLocation() {
    if (sessionStorage.getItem(CALLBACK_REDIRECT_PASSWORD_URL)) {
      window.location.href = sessionStorage.getItem(CALLBACK_REDIRECT_PASSWORD_URL);
    }
    sessionStorage.removeItem(CALLBACK_REDIRECT_PASSWORD_URL);
  }

  static getUserOid() {
    return authProvider.getAccount() && authProvider.getAccount().idToken.oid;
  }

  static getUserRegisteredFrom() {
    return (
      authProvider.getAccount() && authProvider.getAccount().idToken.extension_userRegisteredFrom
    );
  }

  static getUserType() {
    return authProvider.getAccount() && authProvider.getAccount().idToken.extension_userType;
  }
}

export default AuthHelper;
