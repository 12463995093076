export const SET_CONSULTING_RESULTS_PAGE = "search/set-consulting-results-page";
export const SET_WEBINAR_RESULTS_PAGE = "search/set-webinar-results-page";
export const SET_CONSULTING_RESULTS_POPUP = "search/set-consulting-results-popup";
export const SET_WEBINAR_RESULTS_POPUP = "search/set-webinar-results-popup";
export const SET_HIRE_A_TEAM_RESULTS_POPUP = "search/set-hire-a-team-results-popup";
export const SET_SEARCH_QUERY = "search/set-query";
export const SET_POPUP_QUERY = "search/set-popup-query";
export const RESET = "search/reset";
export const CLEAR_POPUP = "search/clear-popup";
export const CLEAR_RESULTS_PAGE = "search/clear-results-page";
export const SET_VALUE = "search/set-value";
export const GET_NEXT_PAGE = "search/get-next-page";
export const SET_SEARCH_BACK_LINK = "search/set-back-link";
export const SET_INSIGHTS_RESULTS_POPUP = "search/set-insights-results-popup";
export const SET_INSIGHTS_RESULTS_PAGE = "search/set-insight-results-page";
export const SET_HIRE_A_TEAM_RESULTS_PAGE = "search/set-hire-a-team-results-page";
export const SET_EVENTS_RESULTS_PAGE = "search/set-events-results-page";
export const SET_LABS_RESULTS_PAGE = "search/set-labs-results-page";
export const SET_EVENTS_RESULTS_POPUP = "search/set-events-results-popup";
export const GET_NEXT_WEBINAR_PAGE = "search/get-next-webinars-page";
export const GET_NEXT_INSIGHTS_PAGE = "search/get-next-insights-page";
export const GET_NEXT_HIRE_A_TEAM_PAGE = "search/get-next-team-page";
export const SET_LABS_RESULTS_POPUP = "search/set-labs-results-popup";
export const GET_NEXT_LABS_PAGE = "search/get-next-labs-page";
