import axiosInstance from "./AxiosRoseInstance";
import Constants from "./Constants";
import { sendErrorEvent } from "./TagManagerHelper";
import AuthHelper from "./AuthHelper";

class CommerceAPI {
  /**
   * login a user and retrieve the context token
   * @param jwt the json token with the customer information
   * @returns {Promise<>}
   */
  static login = (jwt) => {
    axiosInstance.defaults.headers.common.Authorization = jwt;

    const reqBody = {
      jwt,
    };
    return axiosInstance.post("azure-login", reqBody);
  };

  static logout = () => {
    axiosInstance.defaults.headers.common.Authorization = null;
    axiosInstance.defaults.headers.common[Constants.SW_CONTEXT_TOKEN] = null;
  };

  /**
   * retrieve all filters for consulting page
   * @returns {Promise<>}
   */
  static getAllFilters() {
    return axiosInstance.get("product-filters");
  }

  /**
   * retrieve all products for consulting page
   * @returns {Promise<>}
   */
  static getAllProducts({ filters = {} } = {}) {
    return axiosInstance.get("product-explore", {
      params: {
        ...filters,
      },
    });
  }

  /**
   * retrieve all products for insights page
   * @returns {Promise<>}
   */
  static getAllInsights({ filters = {} } = {}) {
    return axiosInstance.get("product-explore?productType=Insights", {
      params: {
        ...filters,
      },
    });
  }

  /**
   * retrieve all video products for webinars page
   * @returns {Promise<>}
   */
  static getAllWebinarsBO({ filters = {} } = {}) {
    return axiosInstance.get("product-explore?productType=VideoLibrary", {
      params: {
        ...filters,
      },
    });
  }

  /**
   * retrieve all products for Hire a Team page
   * @returns {Promise<>}
   */
  static getAllHireATeam({ filters = {} } = {}) {
    return axiosInstance.get("product-explore?productType=HireATeam", {
      params: {
        ...filters,
      },
    });
  }

  /**
   * retrieve all the categories information with associated products
   * @returns {Promise<>}
   */
  static getCatalogue = () => {
    // return Promise.resolve(Constants.mockCatalogue);
    return axiosInstance.get("catalogue");
  };

  static getConsultingTopicCatalogue(catalogueName) {
    return axiosInstance.get(`catalogue-detail/${catalogueName}`);
  }

  /**
   * retrieve a specific product with all the details and associated variants
   * @param productId the main product id retrieved from product API
   * @param isBespoke bool
   * @returns {Promise<>}
   */
  static getProductDetails(productId, upperBound, lowerBound, isBespoke = false) {
    return axiosInstance.get(`/product-detail/${productId}`, {
      params: {
        BESPOKE_ONLY: isBespoke,
        LOWER_BOUND: lowerBound,
        UPPER_BOUND: upperBound,
      },
    });
  }

  /**
   * retrieve a specific product with all the details and associated variants
   * @param productId the main product id retrieved from product API
   * @returns {Promise<>}
   */
  static getBespokeSessions = () => {
    return axiosInstance.get("bespoke-sessions");
  };

  /**
   * Retrieves a manufacturer + associated products
   * @param manufacturerId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static getManufacturerById(manufacturerId) {
    return axiosInstance.get(`expert-detail/${manufacturerId}`);
  }

  /**
   * add a product to the cart
   * @param productId the id of the product to be added to the cart
   * @param questions - list of questions with answers
   * @returns {Promise<>}
   */
  static addProductToCart = (productId, questions) => {
    return axiosInstance.post(`checkout/cart/product/${productId}`, questions);
  };

  /**
   * add a promotion to the cart
   * @param promotionCode the code of the promotion to be added to the cart
   * @returns {Promise<>}
   */
  static addPromotionToCart = (promotionCode) => {
    return axiosInstance.post(`checkout/cart/code/${promotionCode}`);
  };

  /**
   * retrieve the session cart
   * @returns {Promise<>}
   */
  static getCart = () => {
    return axiosInstance.get("checkout/cart");
  };

  /**
   * retrieve the session cart
   * @returns {Promise<>}
   */
  static deleteCart = () => {
    return axiosInstance.delete("checkout/cart");
  };

  /**
   * delete a product from the cart
   * @param productId the id of the product to be added to the cart
   * @returns {Promise<>}
   */
  static deleteProductFromCart = (productId) => {
    return axiosInstance.delete(`checkout/cart/line-item/${productId}`);
  };

  /**
   * place an order from the session cart
   * Note: the cart will be emptied
   * @returns {Promise<>}
   */
  static placeOrder = () => {
    return axiosInstance.post("checkout/order");
  };

  /**
   * pay a placed order
   * @param orderId the id of the order to be paid
   * Note: the context payment method will be used
   * @returns {Promise<>}
   */
  static payOrder = (orderId) => {
    return axiosInstance.post(`checkout/order/${orderId}/pay`);
  };

  /**
   * retrieve the orders of the session customer
   * @returns {Promise<>}
   */
  static getOrderConfirmation = () => {
    return axiosInstance.get("/customer/order", {
      params: {
        limit: 1,
      },
    });
  };

  /**
   * retrieve the orders of the session customer
   * @returns {Promise<>}
   */
  static getOrders = (limit = 499) => {
    return axiosInstance.get("/purchased-sessions", {
      params: {
        limit,
      },
    });
  };

  static defaultErrorHandler(error, restrictRedirect) {
    // eslint-disable-next-line no-console
    console.error(error);
    if (error.response) {
      // Request made and server responded
      // eslint-disable-next-line no-console
      console.error(JSON.stringify(error.response.data));
      // eslint-disable-next-line no-console
      console.error(JSON.stringify(error.response.status));
      // eslint-disable-next-line no-console
      console.error(JSON.stringify(error.response.headers));

      const message = `Server responded with ${
        error.response.status
      } for user ${AuthHelper.getFullName()} with email ${AuthHelper.getEmail()}`;

      sendErrorEvent(message, JSON.stringify(error.response));
    } else if (error.request) {
      // The request was made but no response was received
      // eslint-disable-next-line no-console
      console.error(JSON.stringify(error.request));

      const message = `No server response for user ${AuthHelper.getFullName()} with email ${AuthHelper.getEmail()}`;
      sendErrorEvent(message, JSON.stringify(error.request));
    } else {
      // Something happened in setting up the request that triggered an Error
      const message = `Error happened to user ${AuthHelper.getFullName()} with email ${AuthHelper.getEmail()}`;

      // eslint-disable-next-line no-console
      console.error("Error", error.message);
      sendErrorEvent(message, JSON.stringify(error.message));
    }

    const status = error && error.response && error.response.status;
    if (!restrictRedirect) {
      if (status === 403 || status === 401) {
        window.location.replace("/logout");
      } else if (status === 404) {
        window.location.replace("/not-found");
      } else if (status === 422) {
        window.location.replace("/signup-confirmation");
      } else if (status === 429) {
        window.location.replace("/invitation-error");
      } else if (status === 504 || status === 503) {
        window.location.replace("/service-unavailable");
      } else if (status === 400 || status >= 500) {
        window.location.replace("/service-unavailable");
      }
    }
  }

  static createSnowCase(subject, description, category) {
    const body = {
      short_description: subject,
      description,
      u_category: category,
    };

    return axiosInstance.post("snow/support-case", body);
  }

  static createConciergeCase(subject, description) {
    const body = {
      short_description: subject,
      description,
    };

    return axiosInstance.post("snow/concierge-case", body);
  }

  static addAttachment(caseSysId, file) {
    const body = new FormData();
    body.append("table_sys_id", caseSysId);
    body.append("uploadFile", file);

    return axiosInstance({
      method: "post",
      url: "snow/support-case/attachment",
      data: body,
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  static deleteAttachment(sysId) {
    return axiosInstance.delete(`/snow/attachment/${sysId}`);
  }

  static getWebinarList(
    params = {
      language: "",
      topic: "",
      dateLowerbound: "",
      dateUpperbound: "",
      numberOfResults: "",
      eventOffset: "",
    },
  ) {
    return axiosInstance.get("/webinar", {
      params,
    });
  }

  static getWebinarById(id) {
    return axiosInstance.get(`/webinar/${id}`);
  }

  static getWebinarFilters() {
    return axiosInstance.get("/webinar/filters");
  }

  static getTeamsMeetingLink() {
    return axiosInstance.post("/ms/event");
  }

  static getChatAvailability() {
    return axiosInstance.get("/ms/availability");
  }

  static getSnowSysId() {
    return axiosInstance.get("snow/client-id");
  }

  static getConciergeConversations() {
    return axiosInstance.get("/snow/my-concierge");
  }

  static getMySupportRequests() {
    return axiosInstance.get("/snow/my-request");
  }

  static getSnowAuthenticatedState() {
    return axiosInstance.get("/snow/login-state");
  }

  static snowSearch(searchQuery) {
    return axiosInstance.get("/snow/knowledge-base/search", {
      params: {
        query: searchQuery,
      },
    });
  }

  static getSupportLandingFaqs(limit = 2) {
    return axiosInstance.get("/snow/articles/most-viewed", {
      params: {
        limit,
      },
    });
  }

  static getSupportRelatedFaqs(articleId, subcategorySysId) {
    return axiosInstance.get(`/snow/article/related-faq/${subcategorySysId}/${articleId}`);
  }

  static getSupportCategories() {
    return axiosInstance.get("/snow/category");
  }

  static getCategoryById(id) {
    return axiosInstance.get(`/snow/category/${id}`);
  }

  static getArticleById(articleId) {
    return axiosInstance.get(`/snow/article/${articleId}`);
  }

  static getArticleRatingById(articleId) {
    return axiosInstance.get(`/snow/article/${articleId}/rating`);
  }

  static rateArticle(articleId, rating) {
    const body = {
      rating,
    };
    return axiosInstance.post(`/snow/article/${articleId}/rating`, body);
  }

  static rateArticleUseful(articleId, isUseful) {
    const body = {
      useful: isUseful,
    };

    return axiosInstance.post(`/snow/article/${articleId}/useful`, body);
  }

  static getNotifications() {
    return axiosInstance.get("/notification");
  }

  static getConciergeMessages() {
    return axiosInstance.get("/snow/concierge-message");
  }

  static getSupportMessages() {
    return axiosInstance.get("/snow/support-case");
  }

  static getSupportCase(caseId) {
    return axiosInstance.get(`/snow/support-case/${caseId}`);
  }

  static updateSupportCase(caseId) {
    return axiosInstance.get(`/snow/support-case/${caseId}`);
  }

  static getFeedbackLink(surveyId) {
    return axiosInstance.get(`/snow/survey/${surveyId}`);
  }

  static sendSupportCase(caseId, comment) {
    const body = {
      comment,
    };
    return axiosInstance.put(`/snow/support-case/${caseId}`, body);
  }

  static getFile(id) {
    return axiosInstance.get(`/snow/file/${id}`, { responseType: "arraybuffer" });
  }

  static deleteNotification(id) {
    return axiosInstance.delete(`/notification/${id}`);
  }

  static getVouchers() {
    return axiosInstance.get("/customer/vouchers");
  }

  static getCorrelatedProducts(productNumber) {
    return axiosInstance.get(`/get-correlated-products/${productNumber}`);
  }

  static failSafeTest() {
    return axiosInstance.get("/fail-safe-test");
  }

  static getOrderByOrderNumber(orderNumber) {
    return axiosInstance.get(`/purchased-session-detail/${orderNumber}`);
  }

  // eslint-disable-next-line default-param-last
  static search(searchTerm, filters = {}, type) {
    return axiosInstance.get(`/text-search?query=${searchTerm}`, {
      params: {
        type,
        ...filters,
      },
    });
  }

  static getPaidOrders() {
    return axiosInstance.get("/get-paid-orders");
  }

  // ----------------------------------------------------------------------------
  // TOP UP API CALLS

  static getCreditPackages() {
    return axiosInstance.get("get-all-active-credits-packages");
  }

  static purchaseCreditsPackage(packageId) {
    return axiosInstance.post("/reply-api/purchase-credits", {
      creditsVariantId: packageId,
    });
  }

  static updateCreditsOrder(orderId, paymentId, addressId, amountTotal, amountNet, creditsNumber) {
    return axiosInstance.post("update-credits-order", {
      orderId,
      paymentId,
      addressId,
      amountTotal,
      amountNet,
      creditsNumber,
    });
  }

  static deleteCreditsOrder(orderId) {
    return axiosInstance.post("delete-credits-order", {
      orderId,
    });
  }

  static getEvents(showPastEvents = true, showFutureEvents = true) {
    return axiosInstance.get("get-events", {
      showPastEvents,
      showFutureEvents,
    });
  }
}

export default CommerceAPI;
