import moment from "moment";
import CommerceAPI from "../CommerceAPI";
import Constants from "../Constants";

class SupportHelper {
  static transformSearchResult(apiResult) {
    if (this.checkIfResultIsValid(apiResult)) {
      return apiResult.result.articles.map((article) => {
        return {
          id: article.id,
          title: article.title,
          description: article.snippet,
          number: article.number,
          link: article.link,
        };
      });
    }
    return [];
  }

  /**
   * Checks if article result is valid
   * @param apiResult
   * @returns {*|boolean}
   */
  static checkIfResultIsValid(apiResult) {
    const count = apiResult.result && apiResult.result.meta && apiResult.result.meta.count;
    return count && count > 0 && apiResult.result.articles && apiResult.result.articles.length > 0;
  }

  static transformSupportLandingFaq(apiResult) {
    if (this.checkIfResultIsValid(apiResult)) {
      return apiResult.result.articles.map((article) => {
        return {
          id: article.id,
          title: article.title,
          link: article.link,
        };
      });
    }
    return [];
  }

  static getArticleRatingById(articleId) {
    return new Promise((resolve, reject) => {
      CommerceAPI.getArticleRatingById(articleId)
        .then((res) => {
          let rating = 0;
          if (res.status === 200) {
            rating = (res.data && res.data.result && res.data.result.rating) || 0;
          }
          resolve(parseFloat(rating));
        })
        .catch((e) => {
          CommerceAPI.defaultErrorHandler(e, true);
          reject(e);
        });
    });
  }

  static transformCategories(data) {
    return data.result.map((category) => {
      return {
        id: category.categorySysId,
        title: category.categoryTitle,
        count: parseInt(category.numberOfArticleInCategory, 10),
        subCategories: this.transformSubcategories(category.subCategories),
      };
    });
  }

  static transformSubcategories(list) {
    if (list && list.length > 0) {
      return list.map((subCategory) => {
        return {
          id: subCategory.subCategorySysId,
          title: subCategory.subCategoryTitle,
          count: parseInt(subCategory.articlesNumber, 10),
        };
      });
    }
    return [];
  }

  static transformCategoryName(data) {
    return data.result.map((category) => {
      return {
        title: category.categoryTitle,
        count: parseInt(category.numberOfArticleInCategory, 10),
        subCategories: this.transformSubcategories(category.subCategories),
      };
    });
  }

  static transformArticle(data) {
    const article = data.result;
    return {
      title: article.short_description,
      views: parseInt(article.view_count, 10),
      usefulPercentage: Math.round(parseFloat(article.useful)),
      rating: parseFloat(article.rating),
      content: article.text,
      articleSysId: article.sys_id,
      subcategorySysId: article.subcategory,
    };
  }

  static transformRelatedArticles(data) {
    if (this.checkIfResultIsValid(data)) {
      return data.result.articles.map((article) => {
        return {
          id: article.id,
          title: article.title,
        };
      });
    }
    return [];
  }

  static transformConciergeMessages(data) {
    return data.result.map((message) => {
      return {
        id: message.sys_id,
        name: message.name,
        number: message.number,
        opened: message.opened,
        date: moment(`${message.opened}+00:00`).format(Constants.SELECTED_DATE_FORMAT),
        time: moment(`${message.opened}+00:00`).format("HH mm"),
        short_description: message.short_description,
        state: message.state,
      };
    });
  }

  static filterOpened(allMessages) {
    const results = allMessages.filter((item) => ["1", "10", "18", "6"].indexOf(item.state) >= 0);
    return results;
  }

  static filterClosed(allMessages) {
    const results = allMessages.filter((item) => ["3", "7"].indexOf(item.state) >= 0);
    return results;
  }

  static transformSupportMessages(data) {
    return data.result.map((message) => {
      return {
        id: message.sys_id,
        name: message.name,
        number: message.number,
        opened: message.opened,
        date: moment(message.opened).format("DD/MM/YYYY"),
        time: moment(message.opened).format("HH mm"),
        short_description: message.short_description,
        state: message.state,
      };
    });
  }

  static initialConvert(name) {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("");
  }

  static calculateTimeLeft(inputTime) {
    const format = "Y-M-D HH:mm:ss";
    const currentTimeInRome = moment().utc().format(format);
    const caseTime = moment(inputTime).format(format);

    const amountDays = moment(currentTimeInRome, format).diff(moment(caseTime, format), "days");
    const amountHours = moment(currentTimeInRome, format).diff(moment(caseTime, format), "hours");
    const amountMinutes = moment(currentTimeInRome, format).diff(
      moment(caseTime, format),
      "minutes",
    );

    if (amountDays === 1) {
      return `${amountDays}d ago`;
    }
    if (amountDays > 1) {
      return `${amountDays}d ago`;
    }
    if (amountDays === 0 && amountHours === 1) {
      return `${amountHours}h ago`;
    }
    if (amountDays === 0 && amountHours > 0) {
      return `${amountHours}h ago`;
    }
    if (amountDays === 0 && amountMinutes === 0) {
      return "Just now";
    }
    return `${amountMinutes}m ago`;
  }

  static bytesToSize(bytes, precision) {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;
    const gigabyte = megabyte * 1024;
    const terabyte = gigabyte * 1024;

    if (bytes >= 0 && bytes < kilobyte) {
      return `${bytes} B`;
    }
    if (bytes >= kilobyte && bytes < megabyte) {
      return `${(bytes / kilobyte).toFixed(precision)} KB`;
    }
    if (bytes >= megabyte && bytes < gigabyte) {
      return `${(bytes / megabyte).toFixed(precision)} MB`;
    }
    if (bytes >= gigabyte && bytes < terabyte) {
      return `${(bytes / gigabyte).toFixed(precision)} GB`;
    }
    if (bytes >= terabyte) {
      return `${(bytes / terabyte).toFixed(precision)} TB`;
    }
    return `${bytes} B`;
  }

  static stateLabel(value) {
    if (value === "1") {
      return "New";
    }
    if (value === "10") {
      return "Open";
    }
    if (value === "18") {
      return "Awaiting Info";
    }
    if (value === "6") {
      return "Resolved";
    }
    if (value === "3") {
      return "Closed";
    }
    if (value === "7") {
      return "Cancelled";
    }
    return "state";
  }

  static transformCase(data) {
    const caseItem = data.result.mainInformation;
    return {
      number: caseItem.number,
      state: this.stateLabel(caseItem.state),
      created: moment(`${caseItem.opened_at}+00:00`).format("DD/MM/YYYY HH:mm"),
      updated: moment(`${caseItem.sys_updated_on}+00:00`).format("DD/MM/YYYY HH:mm"),
      subjectTitleMessage: caseItem.short_description,
      description: caseItem.description,
      closed: ["3", "7"].indexOf(caseItem.state) >= 0,
    };
  }

  static transformTrackingCase(data) {
    const { result } = data;
    const tempFirstObject = {
      name: result.user_full_name,
      value: `${result.number} Created`,
      initials: result.user_full_name && this.initialConvert(result.user_full_name),
      sys_created_on: result.sys_created_on,
      user_sys_id: result.user_sys_id,
    };

    if (tempFirstObject) {
      result.entries.push(tempFirstObject);
    }

    if (result && result.entries.length > 0) {
      return result.entries.map((message) => {
        return {
          name: message.name,
          message: message.value,
          initials: message.initials,
          createdOn: message.sys_created_on,
          isUser: message.user_sys_id === result.user_sys_id,
          timeLeft: this.calculateTimeLeft(message.sys_created_on),
          attachmentSysId: message.sys_id,
          attachmentName: message.attachment && message.attachment.file_name,
          attachmentSize:
            message.attachment &&
            message.attachment.size_bytes &&
            SupportHelper.bytesToSize(message.attachment.size_bytes),
          attachmentType: message.attachment && message.attachment.content_type,
        };
      });
    }
    return [];
  }

  static transformAttachments(data) {
    const { result } = data;
    let filterResults;
    if (result && result.entries.length > 0) {
      filterResults = result.entries.map((message) => {
        if (message.attachment && message.user_sys_id === result.user_sys_id) {
          return {
            imageId: message.sys_id,
            message: message.value,
            createdOn: message.sys_created_on,
            timeLeft: this.calculateTimeLeft(message.sys_created_on),
            attachmentSysId: message.sys_id,
            attachmentName: message.attachment && message.attachment.file_name,
            attachmentSize:
              message.attachment &&
              message.attachment.size_bytes &&
              SupportHelper.bytesToSize(message.attachment.size_bytes),
            attachmentType: message.attachment && message.attachment.content_type,
          };
        }
        return null;
      });
    }
    filterResults = filterResults.filter((el) => {
      return el !== null;
    });
    return filterResults;
  }

  static getCategoryName(categoryId, categories) {
    // eslint-disable-next-line no-restricted-syntax
    for (const category of categories) {
      if (Object.prototype.hasOwnProperty.call(categories, category)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const subCategory of category.subCategories) {
          if (subCategory.id === categoryId) {
            const { title } = subCategory;
            return title.slice(4);
          }
        }
      }
    }

    return "";
  }
}

export default SupportHelper;
