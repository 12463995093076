export const SET_ACTIVE_FILTERS_FROM_SEARCH_QUERY = "labs/set-active-filters-from-search-query";
export const SET_ACTIVE_FILTERS = "labs/set-active-filters";
export const TOGGLE_FILTER = "labs/toggle-filter";
export const PRESELECT_FILTER = "labs/preselect-filter";
export const POPULATE_FILTERS = "labs/populate-filters";
export const POPULATE_PRODUCTS = "labs/populate-products";
export const POPULATE_NEXT_PRODUCT_PAGE = "labs/populate-next-product-page";
export const ACTIVE_FILTER_NUM = "labs/active-filters-num";
export const ACTIVATE_PRESELECTED_FILTER = "labs/activate-preselected-filter";
export const ACTIVATE_ALL_PRESELECTED_FILTERS = "labs/activate-all-preselected-filters";
export const CLEAR_ALL_FILTERS = "labs/clear-all-filters";
export const CLEAR_ALL_PRESELECTED_FILTERS = "labs/clear-all-preselected-filters";
export const DESELECT_FILTERS = "labs/deselect-filters";
export const RESET = "labs/reset";
export const CLEAR_NON_TOPIC_FILTERS = "labs/clear-non-topic-filters";
export const HAS_RECOMMENDED = "labs/has-recommended";
