import { last } from "lodash";
import moment from "moment-timezone";

export const HEADER_LINKS = [
  {
    name: "Consulting Services",
    href: "/consulting",
  },
  {
    name: "Video Library",
    href: "/video-library",
  },
  {
    name: "My Job Dashboard",
    href: "/jobs",
  },
  {
    name: "Insights",
    href: "/insights",
  },
  {
    name: "Events",
    href: "/events",
  },
  {
    name: "Area42 Labs",
    href: "/area-42-labs",
  },
  // {
  //   name: "Hire a Team",
  //   href: "/hire-a-team",
  // },
];

const Constants = {
  DEFAULT_HOUR_MINUTE_FORMAT: "HH:mm",
  DEFAULT_DATE_FORMAT: "DD-MM-YYYY",
  DEFAULT_SHOPWARE_DATE_FORMAT: "DD MMM YYYY",
  DEFAULT_SHOPWARE_TIMESTAMP_FORMAT: "HH:mm DD MMM YYYY",
  SELECTED_DATE_FORMAT: "dddd, Do MMMM YYYY",
  SELECTED_DATE_FORMAT_TWO_COMMAS: "dddd, Do MMMM, YYYY",
  SELECTED_DAY_FORMAT: "dddd",
  DEFAULT_VOUCHER_TIME_FORMAT: "Do MMMM YYYY",
  DEFAULT_VOUCHER_DATETIME_FORMAT: "Do MMMM YYYY HH:mm",
  MONTH_YEAR_COMPLETED_ON_FORMAT: "MMMM Do, YYYY",
  MONTH_YEAR_COMPLETED_FORMAT: "MMMM DD, YYYY",

  COOKIE_CONSENT_NAME: "CookieConsent",
  COOKIE_VOUCHER_NAME: "CookieVoucherShown",

  NOTIFICATIONS_KEY: "LIST_OF_NOTIFICATIONS_COL",
  REPLY_ACCOUNT_WARNING: "This functionality is blocked for Reply Accounts",

  SW_CONTEXT_TOKEN: "sw-context-token",
  IS_LOGGED_IN_USER_REPLYER: "is-logged-in-user-replyer",
  IS_LOGGED_IN_USER_QUALIFIED: "is-logged-in-user-qualified",
  IS_LOGGED_IN_USER_DASHBOARD_ENABLED: "is-logged-in-user-dashboard-enabled",
  CART_ITEMS_COUNT: "CART_ITEMS_COUNT",
  CART_EXPIRATION_TIMESTAMP: "CART_EXPIRATION_TIMESTAMP",
  CART_REMAINING_TIME: "CART_REMAINING_TIME",

  SERVER_TIMEZONE: "Europe/Rome",
  TIMEZONE_ABBREVIATION: moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("z"),
  TIMEZONE_FULL_NAME: Intl.DateTimeFormat().resolvedOptions().timeZone,
  STATIC_MEDIA_URL: process.env.REACT_APP_STATIC_MEDIA_URL,

  SAFARI_IFRAME_SANDBOX:
    "allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-forms allow-top-navigation-by-user-activation",

  TFP_LOGIN_SIGNUP_FLOW: last(
    (process.env.REACT_APP_AZURE_AUTHORITY_LOGIN_SIGNUP || "").split("/"),
  ),
  TFP_FORGOT_PASSWORD_FLOW: last(
    (process.env.REACT_APP_AZURE_AUTHORITY_FORGOT_PASSWORD || "").split("/"),
  ),
  TFP_RESET_PASSWORD_FLOW: last(
    (process.env.REACT_APP_AZURE_AUTHORITY_RESET_PASSWORD || "").split("/"),
  ),
  TFP_LOGIN_FLOW: last((process.env.REACT_APP_AZURE_AUTHORITY_LOGIN || "").split("/")),
  TFP_SIGNUP_FLOW: last((process.env.REACT_APP_AZURE_AUTHORITY_SIGNUP || "").split("/")),
  NO_JOBS_SELECTED: 0,
  SOME_JOBS_SELECTED: 1,
  ALL_JOBS_SELECTED: 2,
};

export default Constants;
