import { SESSION_SETUP_SET_PRODUCT_NUMBER, SESSION_SETUP_SET_QUESTION } from "./SetupTypes";
import { submitQuestionAnswers } from "./SetupActions";

const sessionSetupListener = (store) => (next) => (action) => {
  const result = next(action);

  const productNumberSessionKey = `${store.getState().session_setup.productNumber}-form-answers`;

  if (action.type === SESSION_SETUP_SET_QUESTION) {
    const qAndA = JSON.stringify(store.getState().session_setup.questions);
    sessionStorage.setItem(productNumberSessionKey, qAndA);
  }

  if (action.type === SESSION_SETUP_SET_PRODUCT_NUMBER && !store.getState().session_setup.editing) {
    let qAndAQuestions = sessionStorage.getItem(productNumberSessionKey);
    if (sessionStorage.getItem(productNumberSessionKey) !== null) {
      qAndAQuestions = JSON.parse(qAndAQuestions);
      store.dispatch(submitQuestionAnswers(qAndAQuestions));
    }
  }

  return result;
};

export default sessionSetupListener;
