import { filter } from "lodash";
import moment from "moment";
import Helper from "../Helper";
import Constants from "../Constants";
import { createSlug } from "../slug/session-detail";

class CartHelper {
  static transformCartToReviewOrderDetails(apiData) {
    const filteredOutLineItems = this.filterOutLineItemsForCart(apiData.lineItems);
    return {
      promoAmount: 0,
      promoPrice: 0,
      numberOfItemsInCart: filteredOutLineItems.length,
      ...this.transformLineItemsToProducts(filteredOutLineItems),
    };
  }

  static transformLineItemsToProducts(lineItems) {
    let subtotal = 0;
    const products = lineItems.map((lineItem) => {
      subtotal += lineItem.price.totalPrice;

      return {
        id: lineItem.id,
        quantity: lineItem.quantity,
        title: lineItem.label,
        payload: lineItem.payload,
        time:
          lineItem.payload &&
          `${this.getTimeFromCartPayload(
            lineItem.payload.customFields,
          )} - ${Helper.calculateEndHour(
            this.getTimeFromCartPayload(lineItem.payload.customFields),
            lineItem.payload.customFields.reply_col_session_duration,
          )} ${Constants.TIMEZONE_ABBREVIATION}`,
        productNumber:
          lineItem?.payload?.productNumber &&
          this.stripProductNumberExtension(lineItem?.payload?.productNumber),
        editSlug:
          lineItem?.payload?.productNumber &&
          `${createSlug(
            this.stripProductNumberExtension(lineItem?.payload?.productNumber),
            lineItem.label.trim(),
          )}?editing=true`,
        date: this.getDateFromCartPayload(lineItem.payload.customFields),
        numberOfSessions:
          (lineItem.payload.customFields &&
            lineItem.payload.customFields.reply_col_session_meetings) ||
          undefined,
        timeOfSession:
          (lineItem.payload.customFields &&
            lineItem.payload.customFields.reply_col_session_duration) ||
          undefined,
        meetingTime:
          (lineItem.payload.customFields &&
            lineItem.payload.customFields.reply_col_session_meeting_time) ||
          undefined,
        price: Helper.formatCreditsPrice(lineItem.price.totalPrice),
        language:
          (lineItem.payload.customFields &&
            Helper.transformShortLanguage(
              lineItem.payload.customFields.reply_col_session_language,
            )) ||
          undefined,
        fullLanguage: Helper.transformFullLanguage(
          lineItem.payload.customFields.reply_col_session_language,
        ),
        tag:
          (lineItem.payload.tags && lineItem.payload.tags[0] && lineItem.payload.tags[0].name) ||
          undefined,
        bgColor: Helper.constructBackgroundColor(lineItem.payload.customFields),
        isDark: lineItem.payload.customFields.reply_col_session_font,
        isAskRose:
          lineItem.payload.customFields?.reply_col_session_categories_product_type ===
          "reply_col_session_categories_is_ask_rose",
        isLiveEvent:
          lineItem.payload.customFields?.reply_col_session_categories_product_type ===
          "reply_col_session_categories_is_live_event",
        cartNeeds: lineItem?.extensions?.questions?.questions,
      };
    });

    return {
      products,
      subtotal,
    };
  }

  static stripProductNumberExtension(productNumber) {
    return productNumber.split(".")[0];
  }

  static getNumberOfItemsInCart(apiData) {
    return this.filterOutLineItemsForCart(apiData.lineItems).length;
  }

  static getDateFromCartPayload(customFields) {
    const isoString = customFields?.reply_col_session_meeting_time;
    if (!isoString) {
      return "";
    }
    return moment(isoString).format(Constants.SELECTED_DATE_FORMAT);
  }

  static getTimeFromCartPayload(customFields) {
    const isoString = customFields?.reply_col_session_meeting_time;
    if (!isoString) {
      return "";
    }
    return moment(isoString).format(Constants.DEFAULT_HOUR_MINUTE_FORMAT);
  }

  static filterOutLineItemsForCart(lineItems) {
    return filter(lineItems, (lineItem) => {
      return lineItem.type === "product";
    });
  }
}

export const transformQuestionsFromCartPayload = (questions) => {
  const result = {};
  questions.forEach((question, index) => {
    result[index] = {
      question_number: question.question_number,
      question: question.question,
      id: question.id,
      answer: question.answer,
      subtitle: question.subtitle,
    };
  });
  return result;
};

export default CartHelper;
