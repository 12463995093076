import { combineReducers } from "redux";
import ConsultingReducer from "./consulting/ConsultingReducer";
import SupportReducer from "./support/SupportReducer";
import GlobalReducer from "./global/GlobalReducer";
import VoucherReducer from "./vouchers/VoucherReducer";
import VideoLibraryReducer from "./videolibrary/VideoLibraryReducer";
import HomePageReducer from "./homepage/HomePageReducer";
import SetupReducer from "./session/setup/SetupReducer";
import SessionDetailReducer from "./session/detail/SessionDetailReducer";
import SearchReducer from "./search/SearchReducer";
import CheckoutReducer from "./checkout/CheckoutReducer";
import DashboardReducer from "./dashboard/DashboardReducer";
import LandingReducer from "./landing/LandingReducer";
import InsightsReducer from "./insights/InsightsReducer";
import HireATeamReducer from "./hireateam/HireATeamReducer";
import EventsSearchReducer from "./events/EventsReducer";
import LabsReducer from "./labs/LabsReducer";
import eventsReducer from "./eventsSlice";

const RootReducer = combineReducers({
  global: GlobalReducer,
  consulting: ConsultingReducer,
  videoLibrary: VideoLibraryReducer,
  support: SupportReducer,
  vouchers: VoucherReducer,
  insights: InsightsReducer,
  homepage: HomePageReducer,
  session_setup: SetupReducer,
  session_detail: SessionDetailReducer,
  search: SearchReducer,
  checkout: CheckoutReducer,
  dashboard: DashboardReducer,
  landing: LandingReducer,
  hireATeam: HireATeamReducer,
  events: eventsReducer,
  eventsSearch: EventsSearchReducer,
  labs: LabsReducer,
});

export default RootReducer;
