import {
  POPULATE_PRODUCTS,
  POPULATE_FILTERS,
  SET_ACTIVE_FILTERS,
  TOGGLE_FILTER,
  ACTIVE_FILTER_NUM,
  PRESELECT_FILTER,
  ACTIVATE_PRESELECTED_FILTER,
  ACTIVATE_ALL_PRESELECTED_FILTERS,
  RESET,
  CLEAR_ALL_FILTERS,
  CLEAR_ALL_PRESELECTED_FILTERS,
  CLEAR_NON_TOPIC_FILTERS,
  POPULATE_NEXT_PRODUCT_PAGE,
  DESELECT_FILTERS,
  HAS_RECOMMENDED,
} from "./HireATeamTypes";
import ConsultingHelper from "../../utils/helpers/ConsultingHelper";
import { filterSelectedGTM } from "../../utils/TagManagerHelper";
import Helper, { shouldResetState } from "../../utils/Helper";

const initialState = {
  previousPathname: "",
  isHireATeamLoading: true,
  isFiltersLoading: true,
  hireATeam: [],
  totalHireATeamCount: 0,
  hasRecommended: false,

  isAdditionalHireATeamLoading: false,
  page: 1,
  hasNextPage: true,

  languageFilters: [],

  activeFilters: {
    languages: "",
  },

  activeFilterNum: {
    languages: "",
    total: "",
  },
};

function removePreselectedFilters(filters) {
  return filters.map((filter) => {
    if (!filter.active && filter.preselected) {
      return {
        ...filter,
        preselected: false,
      };
    }
    if (filter.active && !filter.preselected) {
      return {
        ...filter,
        preselected: true,
      };
    }

    return filter;
  });
}

function deselectFilters(filters) {
  return filters.map((filter) => {
    if (filter.active || filter.preselected) {
      return {
        ...filter,
        preselected: false,
      };
    }
    return filter;
  });
}

function activatePreselected(filters) {
  return filters.map((filter) => {
    return {
      ...filter,
      active: filter.preselected,
    };
  });
}

function preselectFilter(filters, id) {
  return filters.map((filter) => {
    if (filter.value === id) {
      return {
        ...filter,
        preselected: !filter.preselected,
      };
    }
    return filter;
  });
}

function toggleFilter(filters, id, force = false, checkLabel = false) {
  return filters.map((filter) => {
    if (filter.value === id || (checkLabel && filter.label === id)) {
      return {
        ...filter,
        active: !filter.active,
        preselected: !filter.active,
      };
    }
    if (force) {
      return {
        ...filter,
        active: false,
        preselected: false,
      };
    }
    return filter;
  });
}

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POPULATE_PRODUCTS: {
      let hireATeam = [];
      if (state.page > 1) {
        hireATeam = [...state.hireATeam, ...action.value];
      } else {
        hireATeam = action.value;
      }

      return {
        ...state,
        hireATeam,
        hasNextPage: hireATeam.length < action.total,
        isHireATeamLoading: false,
        isAdditionalHireATeamLoading: false,
        totalHireATeamCount: action.total,
      };
    }

    case POPULATE_NEXT_PRODUCT_PAGE: {
      return {
        ...state,
        isAdditionalHireATeamLoading: true,
        page: state.page + 1,
      };
    }

    case HAS_RECOMMENDED: {
      return {
        ...state,
        hasRecommended: action.value,
      };
    }

    case POPULATE_FILTERS: {
      const languageList = action.value.languageFilters;
      const filteredLanguageList = [];
      languageList.forEach((tag) => {
        if (tag.label === "EN" || tag.label === "FR" || tag.label === "DE" || tag.label === "IT")
          filteredLanguageList.push(tag);
      });

      return {
        ...state,
        isFiltersLoading: false,
        languageFilters: filteredLanguageList,
      };
    }

    case TOGGLE_FILTER: {
      return {
        ...state,
        isHireATeamLoading: true,
        hireATeam: [],
        page: 1,
        [action.value.selector]: toggleFilter(
          state[action.value.selector],
          action.value.id,
          action.value.force,
          action.value.checkLabel,
        ),
      };
    }

    case PRESELECT_FILTER: {
      return {
        ...state,
        [action.value.selector]: preselectFilter(state[action.value.selector], action.value.id),
      };
    }

    case ACTIVATE_PRESELECTED_FILTER: {
      const { selector } = action.value;
      const activeFilters = state[selector].filter((item) => item.preselected);
      activeFilters.forEach((item) => {
        filterSelectedGTM(item.label, ConsultingHelper.getFilterGALabel(selector));
      });

      return {
        ...state,
        hireATeam: [],
        page: 1,
        isHireATeamLoading: true,
        [action.value.selector]: activatePreselected(state[action.value.selector]),
      };
    }

    case ACTIVATE_ALL_PRESELECTED_FILTERS: {
      const filters = ["languageFilters"];
      filters.forEach((filterItem) => {
        const activeFilters = state[filterItem].filter((item) => item.active || item.preselected);
        activeFilters.forEach((item) => {
          filterSelectedGTM(
            filterItem === "languageFilters"
              ? Helper.getLanguageFromShopwareCode(item.value)
              : item.label,
            ConsultingHelper.getFilterGALabel(filterItem),
          );
        });
      });

      return {
        ...state,
        hireATeam: [],
        page: 1,
        isHireATeamLoading: true,
        languageFilters: activatePreselected(state.languageFilters),
      };
    }

    case CLEAR_ALL_FILTERS: {
      return {
        ...state,
        isHireATeamLoading: true,
        hireATeam: [],
        page: 1,
        activeFilters: initialState.activeFilters,
        activeFilterNum: initialState.activeFilterNum,
        languageFilters: ConsultingHelper.addActivePropToFilter(state.languageFilters),
      };
    }

    case CLEAR_ALL_PRESELECTED_FILTERS: {
      return {
        ...state,
        languageFilters: removePreselectedFilters(state.languageFilters),
      };
    }

    case CLEAR_NON_TOPIC_FILTERS: {
      return {
        ...state,
        isHireATeamLoading: true,
        hireATeam: [],
        page: 1,
        activeFilters: ConsultingHelper.clearNonTopicActiveFilters(state.activeFilters),
        activeFilterNum: ConsultingHelper.clearNonTopicActiveFilterNum(state.activeFilterNum),
        languageFilters: ConsultingHelper.addActivePropToFilter(state.languageFilters),
      };
    }

    case DESELECT_FILTERS: {
      return {
        ...state,
        languageFilters: deselectFilters(state.languageFilters),
      };
    }

    case SET_ACTIVE_FILTERS: {
      return {
        ...state,
        activeFilters: action.value,
      };
    }
    case ACTIVE_FILTER_NUM: {
      return {
        ...state,
        activeFilterNum: action.value,
      };
    }

    case RESET: {
      const { resetStateFlag, newPreviousPathname } = shouldResetState(
        state,
        "/hire-a-team",
        "/search-results",
        "/hire-a-team/",
      );

      const updatedState = {};

      Object.keys(initialState).forEach((key) => {
        if (key !== "previousPathname") {
          updatedState[key] = resetStateFlag ? initialState[key] : state[key];
        }
      });

      return {
        ...state,
        ...updatedState,
        // Update the previousPathname
        previousPathname: newPreviousPathname,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
