import {
  SESSION_SETUP_CONFIRM,
  SESSION_SETUP_MOVE_STEP,
  SESSION_SETUP_SET_QUESTION,
  SESSION_SETUP_SET_VALUE,
  SESSION_SETUP_TOGGLE_SHOW,
  SESSION_SETUP_SET_PRODUCT_NUMBER,
  SESSION_SETUP_RESET,
  SESSION_SETUP_EDITING,
  SESSION_SETUP_ERROR,
} from "./SetupTypes";

export const setProductNumber = (productNumber) => ({
  type: SESSION_SETUP_SET_PRODUCT_NUMBER,
  target: "productNumber",
  value: productNumber,
});

export const togglePopup = () => ({
  type: SESSION_SETUP_TOGGLE_SHOW,
});

export const moveStep = (value = 1) => ({
  type: SESSION_SETUP_MOVE_STEP,
  value,
});

export const confirmSelection = () => ({
  type: SESSION_SETUP_CONFIRM,
});

export const updateSelection = (selection) => ({
  type: SESSION_SETUP_SET_VALUE,
  target: "selectedSession",
  value: selection,
});

export const clearEditingSession = () => ({
  type: SESSION_SETUP_SET_VALUE,
  target: "editingSession",
  value: null,
});

export const submitQuestionAnswers = (qAndAs) => ({
  type: SESSION_SETUP_SET_QUESTION,
  value: qAndAs,
});

export const triggerEditMode = (qAndAs, editingSession) => ({
  type: SESSION_SETUP_EDITING,
  questions: qAndAs,
  editingSession,
});

export const error = () => ({
  type: SESSION_SETUP_ERROR,
});

export const reset = () => ({
  type: SESSION_SETUP_RESET,
});
