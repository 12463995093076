export const SET_ACTIVE_FILTERS_FROM_SEARCH_QUERY =
  "dashboard/set-active-filters-from-search-query";
export const SET_ACTIVE_FILTERS = "dashboard/set-active-filters";
export const TOGGLE_FILTER = "dashboard/toggle-filter";
export const PRESELECT_FILTER = "dashboard/preselect-filter";
export const LIST_OF_SELECTED_FILTERS = "dashboard/list-of-selected-filters";
export const POPULATE_FILTERS = "dashboard/populate-filters";
export const POPULATE_ACTIVITY = "dashboard/populate-activity";
export const POPULATE_ACTIVITIES = "dashboard/populate-activities";
export const POPULATE_JOBS = "dashboard/populate-jobs";
export const POPULATE_NEXT_JOBS_PAGE = "dashboard/populate-next-jobs-page";
export const ACTIVE_FILTER_NUM = "dashboard/active-filters-num";
export const ACTIVATE_PRESELECTED_FILTER = "dashboard/activate-preselected-filter";
export const ACTIVATE_ALL_PRESELECTED_FILTERS = "dashboard/activate-all-preselected-filters";
export const CLEAR_ALL_FILTERS = "dashboard/clear-all-filters";
export const CLEAR_ALL_PRESELECTED_FILTERS = "dashboard/clear-all-preselected-filters";
export const RESET = "dashboard/reset";
export const GET_AUTH = "dashboard/get-auth";
export const SET_EMAIL = "dashboard/set-email";
export const SET_ACTIVE_SORT = "dashboard/set-active-sort";
export const RESET_SORT = "dashboard/reset-sort";
export const SET_TABLE_PAGE = "dashboard/set-table-page";
export const UPDATE_STATUS = "dashboard/update-status";
export const ADD_SELECTED_JOB = "dashboard/add-selected-job";
export const REMOVE_SELECTED_JOB = "dashboard/remove-selected-job";
export const CLEAR_SELECTED_JOBS = "dashboard/clear-selected-jobs";
export const ADD_JOBS_IN_TABLE = "dashboard/add-jobs-in-table";
export const ADD_ALL_SELECTED_JOBS = "dashboard/add-all-selected-jobs";
export const ADD_SELECTED_CONTACT = "dashboard/add-all-selected-contact";
export const ADD_DETAIL_JOB_CONTACTS = "dashboard/add-detail-job-contact";
export const ADD_SELECTED_JOB_CONTACTS = "dashboard/add-all-job-contacts";
export const ADD_JOB_DETAIL = "dashboard/add-job-detail";
export const UPDATE_SHARED_JOB_CONTACTS = "dashboard/updated-added-job-contacts";
export const REMOVE_SELECTED_JOB_CONTACTS = "dashboard/remove--selected-job-contacts";
export const ADD_FORM_ERROR = "dashboard/add-form-error";
export const ADD_FORM_VALUES = "dashboard/add-form-values";
export const ADD_FORM_ERROR_MESSAGE = "dashboard/add-form-error-message";
export const RESET_FORM_DATA = "dashboard/reset-form-data";
