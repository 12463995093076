import React from "react";
import { Link } from "react-router-dom";
import SorryIcon from "../../icons/SorryIcon";

function ChatError() {
  return (
    <div className="chat-error">
      <SorryIcon />
      <div className="sorry-wrap">
        <h1 className="title-top mb-0">Service</h1>
        <h1 className="title-bottom mb-0">unavailable</h1>

        <div className="sorry-cta">
          <p className="medium-contrast">We apologize for the inconvenience</p>

          <Link to="/" className="btn rose-btn big-cta primary-cta">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ChatError;
