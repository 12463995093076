import React, { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import styled from "styled-components";
import PropTypes from "prop-types";

const TrendWrapper = styled.div`
  background: ${(props) => props.backgroundStyle};
`;

const VideoWrapper = styled.div`
  .disco-video-overlay {
    background: ${(props) => props.overlayStyle};
  }
`;

function BackgroundConstructor({
  isSingleCanvas,
  children,
  bgColorStart,
  bgColorEnd,
  bgFullWidthVideo,
  bgFullWidthImage,
  bgFullWidthImageMobile,
  transLayerColor,
  transLayerPercentage,
}) {
  const [isBgImage, setIsBgImage] = useState("");
  const [stop, setStop] = useState(false);
  const videoRef = useRef();

  const handleVideo = () => {
    setStop(!stop);
    if (stop === true) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const hexToRgb = (hex) =>
    hex
      .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));

  const constructOverlay = (layerColor, alphaPercentage) => {
    if ((layerColor, alphaPercentage)) {
      const color = hexToRgb(layerColor);
      const alpha = alphaPercentage / 100;
      return `rgba(${color}, ${alpha})`;
    }
    return "rgba(#444444, 0)";
  };

  const constructBgColor = (bgStart, bgEnd) => {
    if (bgStart && bgEnd) {
      return `linear-gradient(93.67deg, ${bgStart} -0.33%, ${bgEnd} 100%)`;
    }
    if (bgStart) {
      return bgStart;
    }
    if (bgEnd) {
      return bgEnd;
    }
    return "#444444";
  };

  useEffect(() => {
    const onResize = () => {
      if (typeof bgFullWidthImage?.url !== "undefined" && window.innerWidth > 1199) {
        setIsBgImage(`url(${bgFullWidthImage?.url})`);
      } else if (typeof bgFullWidthImageMobile?.url !== "undefined" && window.innerWidth <= 1199) {
        setIsBgImage(`url(${bgFullWidthImageMobile?.url})`);
      } else {
        const bgGradient = constructBgColor(bgColorStart, bgColorEnd);
        setIsBgImage(bgGradient);
      }
    };
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [bgFullWidthImage, bgFullWidthImageMobile, bgColorStart, bgColorEnd]);

  if (typeof bgFullWidthVideo?.url !== "undefined") {
    return (
      <VideoWrapper
        overlayStyle={constructOverlay(transLayerColor, transLayerPercentage)}
        className={classnames("", { "single-video-wrap": isSingleCanvas })}
      >
        {transLayerColor && <div className="disco-video-overlay" />}
        <video
          className={classnames("disco-video", { "single-video": isSingleCanvas })}
          autoPlay
          loop
          muted
          playsInline
          ref={videoRef}
        >
          <source src={bgFullWidthVideo?.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="playback-wrap" onClick={handleVideo} role="presentation">
          {stop ? (
            <button type="button" className="play-btn">
              <i className="fa-solid fa-play" />
            </button>
          ) : (
            <button type="button" className="play-btn">
              <i className="fa-solid fa-pause" />
            </button>
          )}
        </div>
        <div className="hero-wrap video-canvas">{children}</div>
      </VideoWrapper>
    );
  }
  return (
    <TrendWrapper
      backgroundStyle={isBgImage}
      className={classnames("trend-wrapped", { "disco-bg": isBgImage })}
    >
      <div className="hero-wrap">{children}</div>
    </TrendWrapper>
  );
}

BackgroundConstructor.propTypes = {
  isSingleCanvas: PropTypes.bool,
  children: PropTypes.node.isRequired,
  bgColorStart: PropTypes.string,
  bgColorEnd: PropTypes.string,
  bgFullWidthVideo: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
  bgFullWidthImage: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
  bgFullWidthImageMobile: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
  transLayerColor: PropTypes.string,
  transLayerPercentage: PropTypes.node,
};

BackgroundConstructor.defaultProps = {
  isSingleCanvas: false,
  bgColorStart: "#000",
  bgColorEnd: "#fff",
  bgFullWidthVideo: null,
  bgFullWidthImage: null,
  bgFullWidthImageMobile: null,
  transLayerColor: "#fff",
  transLayerPercentage: 100,
};

export default BackgroundConstructor;
