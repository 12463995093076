import CustomAgentApplication from "./CustomAgentApplication";

export const b2cPolicies = {
  authorities: {
    signIn: {
      authority: process.env.REACT_APP_AZURE_AUTHORITY_LOGIN,
    },
    signUp: {
      authority: process.env.REACT_APP_AZURE_AUTHORITY_SIGNUP,
    },
    forgotPassword: {
      authority: process.env.REACT_APP_AZURE_AUTHORITY_FORGOT_PASSWORD,
    },
    resetPassword: {
      authority: process.env.REACT_APP_AZURE_AUTHORITY_RESET_PASSWORD,
    },
  },
};

export const config = {
  auth: {
    authority: b2cPolicies.authorities.signIn.authority,
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    // TODO s.shum
    validateAuthority: false,
    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const apiScope = { scopes: [process.env.REACT_APP_AZURE_API_SCOPE] };
export const loginScope = { scopes: [process.env.REACT_APP_AZURE_CLIENT_ID] };
export const authProvider = new CustomAgentApplication(config, apiScope, loginScope);
