export function slugifyTitle(title) {
  return title
    .replace(/[^\w\s]/g, "")
    .toLowerCase()
    .replace(/ /g, "-");
}

/**
 * Returns only the first part from a product number split by .
 * E.g. SW10234.45 -> SW10234
 * @param productNumber
 * @returns string
 */
export function removeProductNumberExtension(productNumber) {
  return productNumber.split(".")[0];
}

/**
 * Creates a product slug for /services, /bespoke-services endpoints
 * Could also be used for purchased and completed sessions
 * @param productNumber
 * @param productTitle
 * @returns {string}
 */
export function createSlug(productNumber, productTitle) {
  const productTitleSlug = slugifyTitle(productTitle);
  return `${removeProductNumberExtension(productNumber)}-${productTitleSlug}`;
}

/**
 * Extracts the product number from a give slug
 * @param slug
 * @returns {*}
 */
export function extractProductNumberFromSlug(slug) {
  return slug.split("-")[0];
}

/**
 * Compares two slugs
 * @param generatedSlug
 * @param parsedSlug
 * @returns {boolean}
 */
export function compareSlugs(generatedSlug, parsedSlug) {
  return generatedSlug.trim() === parsedSlug.trim();
}

/**
 * Replaces the url without pushing new state
 * @param newURL
 */
export function replaceURL(newURL, history) {
  window.history.replaceState(history, "", newURL);
}

/**
 * Checks if the current slug in the URL and the one generated by the methods above match.
 * If they don't the current slug is replaced with the correct one
 * @param productNumber
 * @param title
 * @param currentSlug
 */
export function postSessionLoad(productNumber, title, currentSlug) {
  const correctSlug = createSlug(productNumber, title.trim());

  if (!compareSlugs(correctSlug, currentSlug)) {
    const splitPathname = window.location.pathname.split("/");
    splitPathname.pop();
    const history = window.history.state;
    const currentPathname = `${splitPathname.join("/")}/`;
    const newURL = `${window.location.protocol}//${window.location.host}${currentPathname}${correctSlug}`;
    replaceURL(newURL, history);
  }
}
