import { toggleTag } from "../../reducers/videolibrary/VideoLibraryActions";
import Helper from "../Helper";
import ConsultingHelper from "./ConsultingHelper";

class VideoLibraryHelper {
  static transformFilters(apiResponse) {
    const typology = apiResponse?.typology?.filters;
    const countries = apiResponse?.countries?.filters;
    return {
      topicFilters: VideoLibraryHelper.addActivePropToFilter(apiResponse.topics?.filters, "topics"),
      countryFilters: VideoLibraryHelper.addActivePropToFilter(countries, "countries"),
      typologyFilters: VideoLibraryHelper.addActivePropToFilter(typology, "typology"),
      languageFilters: VideoLibraryHelper.addActivePropToFilter(
        apiResponse.languages.filters,
        "languages",
      ),
    };
  }

  static addActivePropToFilter(filters) {
    return filters?.map((filter) => {
      return {
        ...filter,
        active: false,
        preselected: false,
      };
    });
  }

  static filterOutEmptyFilters(activeFilters, forcePushState = true) {
    const res = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const property in activeFilters) {
      if (activeFilters[property].length > 0) {
        res[property] = activeFilters[property];
      }
    }

    if (forcePushState) {
      ConsultingHelper.addToQuerySearch(res);
    }
    return res;
  }

  static checkIfAnyActiveFiltersInSearch(dispatch) {
    const urlParams = new URLSearchParams(document.location.search);
    VideoLibraryHelper.populateFilterFromSearchQuery(
      "countries",
      "countryFilters",
      dispatch,
      urlParams,
    );
    VideoLibraryHelper.populateFilterFromSearchQuery(
      "languages",
      "languageFilters",
      dispatch,
      urlParams,
    );
    VideoLibraryHelper.populateFilterFromSearchQuery(
      "topics",
      "topicFilters",
      dispatch,
      urlParams,
      true,
    );
  }

  static populateFilterFromSearchQuery(
    querySelector,
    stateSelector,
    dispatch,
    urlParams,
    checkLabel = false,
  ) {
    if (urlParams.has(querySelector)) {
      const params = urlParams.get(querySelector).split(",");
      params.forEach((tag) => {
        dispatch(
          toggleTag({
            selector: stateSelector,
            id: tag,
            firstLoad: true,
            checkLabel,
          }),
        );
      });
    }
  }

  static showVideoLibraryDetailPage(item) {
    return {
      brightcoveScript: item.brightcoveScript,
      eventDate: item.eventDate,
      id: item.id,
      bgColor: "#ffffff",
      bgImage: item.cover || undefined,
      bgImageMobile: item.cover || undefined,
      title: item.title || undefined,
      description: item.shortDescription || undefined,
      language: Helper.getFullLanguageString(item.language.toUpperCase(), 1) || undefined,
      tag: "ON-DEMAND VIDEO",
      topicName: item.topicName,
      overview: item.overview,
    };
  }
}

export default VideoLibraryHelper;
