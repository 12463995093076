import {
  GLOBAL_HIDE_CHAT,
  GLOBAL_SET_AVAILABILITY,
  GLOBAL_SET_CHAT_DISABLED,
  GLOBAL_SHOW_CHAT,
  GLOBAL_SHOW_SUPPORT_CHAT,
  GLOBAL_STOP_TIMER,
  GLOBAL_UPDATE_BLOCK_NOTIFICATIONS,
  GLOBAL_UPDATE_CART_ITEMS_COUNT,
  GLOBAL_UPDATE_EXPIRATION,
  GLOBAL_UPDATE_NOTIFICATION_LIST,
  GLOBAL_UPDATE_NOTIFICATION_STATE,
  GLOBAL_UPDATE_REMAINING_TIME,
} from "./GlobalTypes";

const initialState = {
  showConciergePopup: false,
  isSupportChat: false,
  lastLocation: "/",
  availabilityRange: "__:__ - __:__",
  isChatDisabled: true,
  isAvailabilityTriggered: false,
  askRoseProductSlug: "",
  cartExpirationTimeStamp: null,
  cartRemainingTime: null,
  cartItemsCount: null,
  blockNotifications: true,
  newNotification: false,
  notifications: [],
  isPublicHoliday: false,
};

// eslint-disable-next-line default-param-last
function reducer(state = initialState, action) {
  switch (action.type) {
    case GLOBAL_SHOW_CHAT: {
      return {
        ...state,
        showConciergePopup: true,
        isSupportChat: false,
        lastLocation: window.location.pathname,
      };
    }
    case GLOBAL_SHOW_SUPPORT_CHAT: {
      return {
        ...state,
        showConciergePopup: true,
        isSupportChat: true,
        lastLocation: window.location.pathname,
      };
    }
    case GLOBAL_HIDE_CHAT: {
      return {
        ...state,
        showConciergePopup: false,
      };
    }
    case GLOBAL_SET_CHAT_DISABLED: {
      return {
        ...state,
        isChatDisabled: true,
      };
    }
    case GLOBAL_SET_AVAILABILITY: {
      return {
        ...state,
        availabilityRange: action.availabilityRange,
        isChatDisabled: action.isChatDisabled,
        isAvailabilityTriggered: true,
        askRoseProductSlug: action.askRoseProductSlug,
        isPublicHoliday: action.isPublicHoliday,
      };
    }
    case GLOBAL_UPDATE_BLOCK_NOTIFICATIONS: {
      return {
        ...state,
        blockNotifications: action.value,
      };
    }
    case GLOBAL_UPDATE_NOTIFICATION_LIST: {
      return {
        ...state,
        notifications: action.value,
      };
    }
    case GLOBAL_UPDATE_NOTIFICATION_STATE: {
      return {
        ...state,
        newNotification: action.value,
      };
    }
    case GLOBAL_UPDATE_CART_ITEMS_COUNT: {
      return {
        ...state,
        cartItemsCount: action.value,
      };
    }
    case GLOBAL_UPDATE_EXPIRATION: {
      return {
        ...state,
        cartExpirationTimeStamp: action.value,
      };
    }
    case GLOBAL_UPDATE_REMAINING_TIME: {
      return {
        ...state,
        cartRemainingTime: action.value,
      };
    }
    case GLOBAL_STOP_TIMER: {
      return {
        ...state,
        cartExpirationTimeStamp: null,
        cartRemainingTime: null,
        cartItemsCount: null,
        blockNotifications: true,
        newNotification: false,
        notifications: [],
      };
    }
    default: {
      return state;
    }
  }
}

export default reducer;
