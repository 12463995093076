import React from "react";
import { DiscoveryContext, useComponentData } from "@discoverycms/connector";
import classNames from "classnames";
import PropTypes from "prop-types";
import AskConciergeChatTrigger from "../snow/AskConciergeChatTrigger";
import { sendEventAnalytics } from "../../utils/TagManagerHelper";

function DiscoveryButton({ id }) {
  const componentData = useComponentData(id, DiscoveryContext);

  if (componentData === undefined) {
    return <span>Loading..</span>;
  }
  const {
    Button_active: buttonActive,
    Button_color: buttonColor,
    Button_text: buttonText,
    Button_text_color: buttonTextColor,
    Button_url: buttonUrl,
    Button_action: actionType,
    Button_outline_color: buttonOutlineColor,
    Url_in_new_browser_tab: inNewTab,
  } = componentData;

  const buttonBorder = `1px solid ${buttonOutlineColor}`;

  function escapeHtml(htmlStr) {
    return htmlStr
      .replace(/&/g, "")
      .replace(/</g, "")
      .replace(/>/g, "")
      .replace(/"/g, "")
      .replace(/'/g, "")
      .replace(/\\/g, "")
      .replace(/\//g, "");
  }

  return (
    buttonActive &&
    (actionType === "Ask Rose" ? (
      <AskConciergeChatTrigger
        className={classNames("discover rose-btn big-cta primary-cta", {
          "no-hover-s": buttonColor === "white",
          "no-hover-p":
            buttonColor === "black" || buttonColor === "#151F27" || buttonColor === "#0b1014",
        })}
        style={{ background: buttonColor, color: buttonTextColor, border: buttonBorder }}
        onClick={() =>
          sendEventAnalytics({
            event: "canvas_hp_interaction",
            name: "Scaling Artificial Intelligence",
            buttonName: { buttonText },
            page: "homepage",
          })
        }
      >
        {escapeHtml(buttonText)}
      </AskConciergeChatTrigger>
    ) : (
      <a
        href={buttonUrl[0].url}
        className={classNames("discover rose-btn big-cta primary-cta", {
          "no-hover-s": buttonColor === "white",
          "no-hover-p":
            buttonColor === "black" || buttonColor === "#151F27" || buttonColor === "#0b1014",
        })}
        style={{ background: buttonColor, color: buttonTextColor, border: buttonBorder }}
        target={inNewTab ? "_blank" : "_self"}
        onClick={() =>
          sendEventAnalytics({
            event: "canvas_hp_interaction",
            name: "Scaling Artificial Intelligence",
            buttonName: { buttonText },
            page: "homepage",
          })
        }
        rel="noopener noreferrer"
      >
        {escapeHtml(buttonText)}
      </a>
    ))
  );
}

DiscoveryButton.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DiscoveryButton;
