import React from "react";
import PropTypes from "prop-types";
import whiteLogoSrc from "../../assets/images/logo-white.svg";
import blackLogoSrc from "../../assets/images/logo-black.svg";

function Logo({ isWhite }) {
  return (
    <>
      <img
        src={isWhite ? whiteLogoSrc : blackLogoSrc}
        className="navbar-logo img-fluid d-none d-lg-block"
        alt="Reply logo"
      />
      <img src={blackLogoSrc} className="navbar-logo img-fluid d-lg-none" alt="Reply logo" />
    </>
  );
}

Logo.propTypes = {
  isWhite: PropTypes.bool,
};

Logo.defaultProps = {
  isWhite: false,
};

export default Logo;
