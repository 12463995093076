import moment from "moment";
import { find } from "lodash";
import {
  GLOBAL_HIDE_CHAT,
  GLOBAL_SHOW_CHAT,
  GLOBAL_SHOW_SUPPORT_CHAT,
  GLOBAL_SET_AVAILABILITY,
  GLOBAL_SET_CHAT_DISABLED,
  GLOBAL_UPDATE_NOTIFICATION_LIST,
  GLOBAL_UPDATE_NOTIFICATION_STATE,
  GLOBAL_UPDATE_BLOCK_NOTIFICATIONS,
  GLOBAL_UPDATE_EXPIRATION,
  GLOBAL_STOP_TIMER,
  GLOBAL_UPDATE_CART_ITEMS_COUNT,
  GLOBAL_UPDATE_REMAINING_TIME,
} from "./GlobalTypes";
import CommerceAPI from "../../utils/CommerceAPI";
import Helper from "../../utils/Helper";
import CartHelper from "../../utils/helpers/CartHelper";
import Constants from "../../utils/Constants";

export const showConciergeChat = () => {
  return {
    type: GLOBAL_SHOW_CHAT,
  };
};

export const showSupportChat = () => {
  return {
    type: GLOBAL_SHOW_SUPPORT_CHAT,
  };
};

export const hideConciergeChat = () => {
  return {
    type: GLOBAL_HIDE_CHAT,
  };
};

export const setChatDisabled = () => {
  return {
    type: GLOBAL_SET_CHAT_DISABLED,
  };
};

export const setAvailability = (
  availabilityRange,
  isChatDisabled,
  askRoseProductSlug,
  isPublicHoliday,
) => {
  return {
    type: GLOBAL_SET_AVAILABILITY,
    availabilityRange,
    isChatDisabled,
    askRoseProductSlug,
    isPublicHoliday,
  };
};

export const getChatAvailability = (dispatch, setPageContent = () => {}) => {
  CommerceAPI.getChatAvailability()
    .then((res) => {
      const { data } = res;
      const isAvailable = data.available;
      const { askRoseProductSlug } = data;
      const { isPublicHoliday } = data;

      dispatch(
        setAvailability(
          Helper.formatTimeRange(res?.data?.availabilityStart, res?.data?.availabilityEnd),
          !isAvailable,
          askRoseProductSlug,
          isPublicHoliday,
        ),
      );
    })
    .catch((e) => {
      CommerceAPI.defaultErrorHandler(e, true);
      setPageContent("error");
    });
};

export const setNewNotificationList = (list) => {
  return {
    type: GLOBAL_UPDATE_NOTIFICATION_LIST,
    value: list,
  };
};

export const setNewNotification = (state) => {
  return {
    type: GLOBAL_UPDATE_NOTIFICATION_STATE,
    value: state,
  };
};
export const setBlockNotifications = (state) => {
  return {
    type: GLOBAL_UPDATE_BLOCK_NOTIFICATIONS,
    value: state,
  };
};

export const setExpirationTimestamp = (exp) => {
  return {
    type: GLOBAL_UPDATE_EXPIRATION,
    value: exp,
  };
};

export const setCartItemsCount = (count) => {
  return {
    type: GLOBAL_UPDATE_CART_ITEMS_COUNT,
    value: count,
  };
};

export const setReaminingTime = (remaining) => {
  return {
    type: GLOBAL_UPDATE_REMAINING_TIME,
    value: remaining,
  };
};

export const updateNotifications = (dispatch) => {
  CommerceAPI.getNotifications()
    // eslint-disable-next-line no-use-before-define
    .then((res) => handleApiResponse(res, dispatch))
    .catch((e) => {
      if (!process.env.REACT_APP_NOTIFICATION_SINGLE_CALL) {
        setTimeout(() => {
          updateNotifications(dispatch);
        }, process.env.REACT_APP_NOTIFICATION_UPDATE_OFFSET);
      }
      dispatch(setNewNotification(false));
      CommerceAPI.defaultErrorHandler(e, true);
    });
};

export const handleApiResponse = (result, dispatch) => {
  if (!process.env.REACT_APP_NOTIFICATION_SINGLE_CALL) {
    setTimeout(() => {
      updateNotifications(dispatch);
    }, process.env.REACT_APP_NOTIFICATION_UPDATE_OFFSET);
  }

  if (result.data.length === 0) {
    dispatch(setNewNotification(false));
    dispatch(setNewNotificationList([]));
    return;
  }
  dispatch(setNewNotification(true));

  const pData = result.data.map((node) => {
    return {
      productId: node.data.productId,
      id: node.id,
    };
  });
  dispatch(setNewNotificationList(pData));
};

export const deleteNotification = (productId, notifications, dispatch) => {
  const notificationList = find(notifications, (n) => {
    return productId === n.productId;
  });

  if (notificationList) {
    // optimistic response, clear notifications immediately and then call API
    dispatch(setNewNotificationList([]));
    dispatch(setNewNotification(false));
    CommerceAPI.deleteNotification(notificationList.id)
      .then(() => {
        updateNotifications(dispatch);
      })
      .catch((e) => {
        CommerceAPI.defaultErrorHandler(e, true);
      });
  }
};

export const updateExpirationTimestamp = (dispatch, newValue) => {
  sessionStorage.setItem(Constants.CART_EXPIRATION_TIMESTAMP, newValue);
  dispatch(setExpirationTimestamp(newValue));
};

export const clearTimer = (dispatch) => {
  CommerceAPI.deleteCart()
    .then(() => {})
    .catch((e) => CommerceAPI.defaultErrorHandler(e, true));

  sessionStorage.removeItem(Constants.CART_EXPIRATION_TIMESTAMP);
  sessionStorage.removeItem(Constants.CART_ITEMS_COUNT);
  sessionStorage.removeItem(Constants.CART_REMAINING_TIME);
  dispatch({ type: GLOBAL_STOP_TIMER });
};

export const updateCartItemsCount = (dispatch, newValue) => {
  sessionStorage.setItem(Constants.CART_ITEMS_COUNT, newValue);
  dispatch(setCartItemsCount(newValue));
};

export const updateCartTimestampAndCount = (dispatch, response) => {
  const remainingTime = sessionStorage.getItem(Constants.CART_REMAINING_TIME);
  let cartExpiration = null;

  if (remainingTime !== null) {
    cartExpiration = moment(new Date()).add(remainingTime, "seconds");
    setReaminingTime(dispatch, null);
    sessionStorage.removeItem(Constants.CART_REMAINING_TIME);
  } else {
    cartExpiration = moment(response.data.data.cartDateTime).add(10, "minutes").local();
  }
  // Handle the case when there are now products in the cart
  const { lineItems } = response.data.data;
  if (lineItems && lineItems.length > 0) {
    if (cartExpiration > 0) {
      updateExpirationTimestamp(dispatch, cartExpiration.unix());
    }
  } else {
    updateExpirationTimestamp(dispatch, null);
  }

  if (!sessionStorage.getItem(Constants.CART_EXPIRATION_TIMESTAMP)) {
    clearTimer(dispatch);
    return;
  }

  updateCartItemsCount(dispatch, CartHelper.getNumberOfItemsInCart(response.data.data));
};

export const fetchCart = (dispatch) => {
  CommerceAPI.getCart()
    .then((result) => {
      updateCartTimestampAndCount(dispatch, result);
    })
    .catch((e) => {
      CommerceAPI.defaultErrorHandler(e, true);
    });
};

export const updateRemainingTime = (dispatch) => {
  if (!sessionStorage.getItem(Constants.CART_REMAINING_TIME)) {
    const now = moment(new Date());
    const end = moment.unix(sessionStorage.getItem(Constants.CART_EXPIRATION_TIMESTAMP));

    const remainingTime = Math.abs(now.diff(end, "seconds"));
    sessionStorage.setItem(Constants.CART_REMAINING_TIME, remainingTime);
    setReaminingTime(dispatch, remainingTime);
  }
};
