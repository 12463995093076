import axios from "axios";
import { authProvider } from "../config/AuthProvider";
import Constants from "./Constants";

const getRoseAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SHOPWARE_URL,
  headers: {
    "sw-access-key": process.env.REACT_APP_SHOPWARE_API_SECRET,
    Accept: "application/json",
    "Content-Type": "application/json",
    "sw-timezone": Constants.TIMEZONE_FULL_NAME,
  },
});

getRoseAxiosInstance.interceptors.request.use(
  async (config) => {
    if (authProvider.getAccount()) {
      const res = await authProvider.getAccessToken();
      // eslint-disable-next-line no-param-reassign
      config.headers[Constants.SW_CONTEXT_TOKEN] = sessionStorage.getItem(
        Constants.SW_CONTEXT_TOKEN,
      );
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${res.accessToken}`;
      return Promise.resolve(config);
    }
    return Promise.resolve(config);
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default getRoseAxiosInstance;
