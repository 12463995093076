import { LOADING, SUCCESS_LOADING, FAILED_LOADING, VALUE } from "./HomePageTypes";

const initialState = {
  isLoading: true,
  list: [],
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case FAILED_LOADING:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
