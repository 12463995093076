import React from "react";
import BarLoader from "react-spinners/BarLoader";
import Logo from "./Logo";

function LoadingScreen() {
  return (
    <div className="loading-screen">
      <Logo className="logo" />
      <div className="my-2">
        <BarLoader width="140px" color="#151F27" />
        {/* DO NOT REMOVE THIS. IT'S A FIX FOR FONT FLICKERING */}
        <div style={{ height: 0, overflow: "hidden" }}>
          <h1>.</h1>
          <h2>.</h2>
          <h3>.</h3>
          <h4>.</h4>
          <h5>.</h5>
          <p>.</p>
        </div>
      </div>
    </div>
  );
}

export default LoadingScreen;
