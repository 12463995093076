import { isEmpty } from "lodash";
import {
  POPULATE_FILTERS,
  POPULATE_PRODUCTS,
  TOGGLE_FILTER,
  PRESELECT_FILTER,
  ACTIVATE_PRESELECTED_FILTER,
  ACTIVATE_ALL_PRESELECTED_FILTERS,
  CLEAR_ALL_FILTERS,
  RESET,
  CLEAR_ALL_PRESELECTED_FILTERS,
  POPULATE_NEXT_PRODUCT_PAGE,
  DESELECT_FILTERS,
  CLEAR_NON_TOPIC_FILTERS,
} from "./ConsultingTypes";
import ConsultingHelper from "../../utils/helpers/ConsultingHelper";
import CommerceAPI from "../../utils/CommerceAPI";
import Helper from "../../utils/Helper";
import store from "../Store";

export const populateProducts = (value, total = 0) => {
  return {
    type: POPULATE_PRODUCTS,
    value,
    total,
  };
};

export const loadNextPage = () => {
  return {
    type: POPULATE_NEXT_PRODUCT_PAGE,
  };
};

export const populateFilters = (value) => {
  return {
    type: POPULATE_FILTERS,
    value,
  };
};

export const toggleTag = (value) => {
  return {
    type: TOGGLE_FILTER,
    value,
  };
};

export const preselectTag = (value) => {
  return {
    type: PRESELECT_FILTER,
    value,
  };
};

export const activatePreselectedFilter = (value) => {
  return {
    type: ACTIVATE_PRESELECTED_FILTER,
    value,
  };
};

export const activateAllPreselectedFilters = (value) => {
  return {
    type: ACTIVATE_ALL_PRESELECTED_FILTERS,
    value,
  };
};

export const reset = () => {
  return {
    type: RESET,
  };
};

export const clearAllFilters = (value) => {
  return {
    type: CLEAR_ALL_FILTERS,
    value,
  };
};

export const clearAllPreselectedFilters = (value) => {
  return {
    type: CLEAR_ALL_PRESELECTED_FILTERS,
    value,
  };
};

export const clearAllNonTopicFilters = (value) => {
  return {
    type: CLEAR_NON_TOPIC_FILTERS,
    value,
  };
};

export const deselectFilters = (value) => {
  return {
    type: DESELECT_FILTERS,
    value,
  };
};

export const fetchAllPosts = (dispatch, forcePushState = true, page = 1) => {
  const filters = ConsultingHelper.filterOutEmptyFilters(
    store.getState().consulting.activeFilters,
    forcePushState,
  );
  filters.page = page;
  filters.limit = 12;

  CommerceAPI.getAllProducts({ filters })
    .then((res) => {
      if (res.status === 200) {
        dispatch(
          populateProducts(
            Helper.transformApiProductListToCardProps(
              res.data.data,
              "reply_col_portrait_card",
              undefined,
              undefined,
              true,
            ),
            res.data.aggregations.count.count,
          ),
        );

        return;
      }
      dispatch(populateProducts([]));
    })
    .catch(CommerceAPI.defaultErrorHandler);
};

export const fetchAllFilters = (dispatch, isProductFetch = false) => {
  const { industryFilters, topicFilters, tagFilters, languageFilters, countryFilters } =
    store.getState().consulting;
  const hasFilters =
    !isEmpty(industryFilters) &&
    !isEmpty(topicFilters) &&
    !isEmpty(tagFilters) &&
    !isEmpty(languageFilters) &&
    !isEmpty(countryFilters);
  if (!hasFilters) {
    CommerceAPI.getAllFilters()
      .then((res) => {
        if (res.status === 200) {
          dispatch(populateFilters(ConsultingHelper.transformConsultFilters(res.data)));
          if (isProductFetch) {
            ConsultingHelper.checkIfAnyActiveFiltersInSearch(dispatch);
            // fetch products only after fetching filters
            fetchAllPosts(dispatch, false);
          }

          return;
        }
        dispatch({ type: POPULATE_PRODUCTS, value: [] });
      })
      .catch(CommerceAPI.defaultErrorHandler);
  } else if (isProductFetch) {
    // otherwise fetch
    ConsultingHelper.checkIfAnyActiveFiltersInSearch(dispatch);
    fetchAllPosts(dispatch, false);
  }
};
