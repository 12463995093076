import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { DiscoveryContext, useComponentData as setComponentData } from "@discoverycms/connector";
import { Autoplay, Navigation, Pagination } from "swiper";
import PropTypes from "prop-types";
import { ReactComponent as ArrowUnion } from "../../assets/images/landing/icons/arrow-union.svg";
// eslint-disable-next-line import/no-unresolved
import "swiper/css";
// eslint-disable-next-line import/no-unresolved
import "swiper/css/navigation";
// eslint-disable-next-line import/no-unresolved
import "swiper/css/pagination";
import DiscoveryTrendCard from "./DiscoveryTrendCard";

function DiscoveryTrendsCatalogue({ isLoading, componentId }) {
  const componentData = setComponentData(componentId).cards;
  const discoveryCards = componentData
    .map((card) => {
      // eslint-disable-next-line no-underscore-dangle
      return setComponentData(card._id, DiscoveryContext);
    })
    .filter((card) => card?.Card_enabled === true);

  const swiperRef = useRef();

  if (componentData === undefined) {
    return <span>Loading..</span>;
  }

  // eslint-disable-next-line no-nested-ternary
  return isLoading ? (
    <div id="hero-trends">
      <div className="skellyload" />
    </div>
  ) : discoveryCards.length === 1 ? (
    <div id="hero-trends" data-discovery-id={componentId}>
      <DiscoveryTrendCard cardData={discoveryCards[0]} isSingleCanvas />
    </div>
  ) : (
    <div id="hero-trends" data-discovery-id={componentId}>
      <Swiper
        slidesPerView={1}
        centeredSlides
        pagination={{ clickable: true }}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        loop
        className="trend-swiper"
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        breakpoints={{
          1299: {
            slidesPerView: 1,
            touchRatio: 1,
          },
        }}
        modules={[Autoplay, Navigation, Pagination]}
      >
        <div className="controls">
          <div
            className="circle-left"
            role="presentation"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <ArrowUnion width="16px" height="9px" className="control-left" />
          </div>

          <div
            className="circle-right"
            role="presentation"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <ArrowUnion width="16px" height="9px" className="control-right" />
          </div>
        </div>
        {discoveryCards.map((card) => (
          <SwiperSlide key={card?.Title_text}>
            <DiscoveryTrendCard cardData={card} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

DiscoveryTrendsCatalogue.defaultProps = {
  isLoading: false,
};

DiscoveryTrendsCatalogue.propTypes = {
  isLoading: PropTypes.bool,
  componentId: PropTypes.string.isRequired,
};

export default DiscoveryTrendsCatalogue;
